import type { AxiosInstance, AxiosRequestConfig } from 'axios';

export type AbstractRankingMetaRes = EfiMetaRes & {
  /** @format int64 */
  key: number;
  /** @format int64 */
  personKey: number;
  position?: PositionResponse;
  state?: StateResponseRecord;
  /** @format int64 */
  timestamp: number;
  type?: string;
};

export enum AnnouncementImageGraphicType {
  Commitment = 'Commitment',
  Decommitment = 'Decommitment',
  TopSchools = 'TopSchools',
  DecisionDate = 'DecisionDate',
  OfficialVisit = 'OfficialVisit',
  Offers = 'Offers',
}

export enum AnnouncementImageStatus {
  Requested = 'Requested',
  InProgress = 'InProgress',
  Delivered = 'Delivered',
  Rejected = 'Rejected',
}

export type ArticleMetaRes = EfiMetaRes & {
  authorName?: string | null;
  authorSlug?: string | null;
  featuredImage?: ImageResponse;
  fullPath?: string | null;
  isPlus: boolean;
  /** @format int64 */
  key: number;
  /** @format date-time */
  postDateGmt: string;
  primaryCategory?: SlimCategoryResponse;
  slug?: string | null;
  status?: string | null;
  title?: string | null;
};

/** Specifies the kind of asset and Asset object is. */
export enum AssetClasses {
  Unknown = 'Unknown',
  Image = 'Image',
  Video = 'Video',
}

export interface AssetResponse {
  altText?: string | null;
  /** Specifies the kind of asset and Asset object is. */
  assetType: AssetClasses;
  caption?: string | null;
  category?: Category;
  description?: string | null;
  domain?: string | null;
  domainOverride?: string | null;
  /** @format int32 */
  duration: number;
  fileSystem?: string | null;
  /** @format int32 */
  height?: number | null;
  /** @format int64 */
  key: number;
  mimeType?: string | null;
  path?: string | null;
  source?: string | null;
  sourceOverride?: string | null;
  thumbnail?: string | null;
  title?: string | null;
  type?: string | null;
  /** @format int32 */
  width?: number | null;
}

export enum BoltContentMarkup {
  Unknown = 'Unknown',
  BbCode = 'BbCode',
  Html = 'Html',
  Markdown = 'Markdown',
}

export interface BoltConvertMarkupRequest {
  /**
   * A string in the source markup to be converted.
   * @minLength 1
   */
  content: string;
  from: BoltContentMarkup;
  to: BoltContentMarkup;
}

export interface BoltConvertMarkupResponse {
  markup: BoltContentMarkup;
  /** The converted string in the target markup. */
  result?: string;
}

export type BoltMetaRes = EfiMetaRes & {
  asset?: AssetResponse;
  /** @format int64 */
  assetKey?: number | null;
  author?: SimpleAuthorResponse;
  /** @format int64 */
  authorKey: number;
  body?: string | null;
  /** @format date-time */
  created: string;
  headline?: SimpleHeadlineResponse;
  headlineAsset?: AssetResponse;
  /** @format int64 */
  headlineKey?: number | null;
  /** @format int64 */
  key: number;
  title?: string;
  /** @format date-time */
  updated?: string | null;
};

export interface BoltRes {
  /** @format int64 */
  assetKey?: number | null;
  /** @format int64 */
  authorKey: number;
  /** @format date-time */
  created: string;
  /** @format int64 */
  headlineKey?: number | null;
  /** @format int64 */
  key: number;
  title?: string;
  /** @format date-time */
  updated?: string | null;
}

export interface BoltResPagedData {
  list?: BoltRes[];
  pagination?: Pagination;
}

export enum Category {
  Undetermined = 'Undetermined',
  Highlights = 'Highlights',
  Training = 'Training',
  Experts = 'Experts',
  Conversations = 'Conversations',
  Camp = 'Camp',
}

export interface DetailedBoltRes {
  asset?: AssetResponse;
  /** @format int64 */
  assetKey?: number | null;
  author?: SimpleAuthorResponse;
  /** @format int64 */
  authorKey: number;
  body?: string | null;
  /** @format date-time */
  created: string;
  headline?: SimpleHeadlineResponse;
  headlineAsset?: AssetResponse;
  /** @format int64 */
  headlineKey?: number | null;
  /** @format int64 */
  key: number;
  taggedClassYears?: number[] | null;
  taggedPeople?: SimplePersonResponse[] | null;
  taggedPersonKeys?: number[] | null;
  taggedSportKeys?: number[] | null;
  taggedSports?: SportResponseRecord[] | null;
  taggedTierStatuses?: TierStatuses[] | null;
  title?: string;
  /** @format date-time */
  updated?: string | null;
}

export type EfiMetaRes = object;

export interface EliteFeedItemRes {
  feedType: FeedItemTypes;
  /** @format int64 */
  key: number;
  meta?:
    | EfiMetaRes
    | ArticleMetaRes
    | NilValuationMetaRes
    | GraphicEditMetaRes
    | AbstractRankingMetaRes
    | ServiceRankingMetaRes
    | IndustryRankingMetaRes
    | BoltMetaRes
    | null;
  /** @format date-time */
  storedDate: string;
  /** @format int64 */
  typeKey: number;
}

export interface EliteFeedItemResPagedData {
  list?: EliteFeedItemRes[];
  pagination?: Pagination;
}

export enum FeedItemGenerationTypes {
  Unknown = 'Unknown',
  Meta = 'Meta',
  PersonFeed = 'PersonFeed',
  OrganizationFeed = 'OrganizationFeed',
  UserFeed = 'UserFeed',
}

export enum FeedItemStatuses {
  Unknown = 'Unknown',
  Stale = 'Stale',
  GeneratingMeta = 'GeneratingMeta',
  GeneratingPersonFeeds = 'GeneratingPersonFeeds',
  GeneratingOrganizationFeeds = 'GeneratingOrganizationFeeds',
  GeneratingUserFeeds = 'GeneratingUserFeeds',
  FlushingCache = 'FlushingCache',
  QueueNotifications = 'QueueNotifications',
  Updated = 'Updated',
  Error = 'Error',
}

export enum FeedItemTypes {
  Unknown = 'Unknown',
  Article = 'Article',
  NilValuation = 'NilValuation',
  NilDeal = 'NilDeal',
  IndustryRanking = 'IndustryRanking',
  GraphicEdit = 'GraphicEdit',
  ServiceRanking = 'ServiceRanking',
  Bolt = 'Bolt',
}

export interface FileResponse {
  key?: string;
  /** @format date-time */
  lastModifiedDate?: string | null;
  name?: string | null;
  /** @format int64 */
  personKey?: number | null;
  /** @format int64 */
  tag?: number | null;
}

export type GraphicEditMetaRes = EfiMetaRes & {
  completedImage?: FileResponse;
  graphicType: AnnouncementImageGraphicType;
  /** @format int64 */
  key: number;
  /** @format int64 */
  personKey: number;
  /** @format int64 */
  requestedTimestamp: number;
  status: AnnouncementImageStatus;
};

export interface ImageResponse {
  altText?: string | null;
  /** Specifies the kind of asset and Asset object is. */
  assetType: AssetClasses;
  caption?: string | null;
  category?: Category;
  description?: string | null;
  domain?: string | null;
  domainOverride?: string | null;
  /** @format int32 */
  duration: number;
  fileSystem?: string | null;
  /** @format int32 */
  height?: number | null;
  /** @format int64 */
  key: number;
  mimeType?: string | null;
  path?: string | null;
  source?: string | null;
  sourceOverride?: string | null;
  thumbnail?: string | null;
  title?: string | null;
  type?: string | null;
  /** @format int32 */
  width?: number | null;
}

export type IndustryRankingMetaRes = AbstractRankingMetaRes & {
  industryRanking?: IndustryRankingRes;
};

export interface IndustryRankingRes {
  on3?: ServiceRankingRes;
  espn?: ServiceRankingRes;
  fiveStarPlus: boolean;
  industryRanking?: ServiceRankingRes;
  /** @format int64 */
  key: number;
  nearlyFiveStarPlus: boolean;
  rankingType: RankingType;
  rivals?: ServiceRankingRes;
  tfs?: ServiceRankingRes;
  /** @format int64 */
  timestamp: number;
}

export interface NilDisputeRequest {
  otherInformation?: string | null;
  /** @format int64 */
  personKey: number;
  type: NilDisputeType;
}

export interface NilDisputeResponse {
  /** @format date-time */
  date: string;
  /** @format int64 */
  key: number;
  otherInformation?: string | null;
  person?: SimplePersonResponse;
  /** @format int64 */
  personKey: number;
  reviewedNotes?: string | null;
  status: NilDisputeStatus;
  type: NilDisputeType;
}

export interface NilDisputeResponsePagedData {
  list?: NilDisputeResponse[];
  pagination?: Pagination;
}

export enum NilDisputeStatus {
  Unknown = 'Unknown',
  Delivered = 'Delivered',
  Rejected = 'Rejected',
  Accepted = 'Accepted',
}

export enum NilDisputeType {
  Unknown = 'Unknown',
  MakingMore = 'MakingMore',
  AdditionalOffers = 'AdditionalOffers',
  Other = 'Other',
}

export type NilValuationMetaRes = EfiMetaRes & {
  /** @format date-time */
  date: string;
  increase?: boolean | null;
  /** @format int64 */
  key: number;
  /** @format int64 */
  previousValuation?: number | null;
  /** @format int64 */
  valuation: number;
  /** @format int64 */
  valuationChange: number;
  /** @format double */
  valuationChangePercent: number;
};

export interface Pagination {
  /** @format int32 */
  count: number;
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  itemsPerPage: number;
  /** @format int32 */
  limit: number;
  /** @format int32 */
  offset: number;
  /** @format int32 */
  pageCount: number;
}

export enum PlayerRatingChange {
  Decrease = 'Decrease',
  None = 'None',
  Increase = 'Increase',
}

export interface PositionResponse {
  abbreviation?: string | null;
  /** @format int32 */
  key: number;
  name?: string;
  positionType: PositionType;
  sport?: SportResponseRecord;
  /** @format int32 */
  sportKey?: number | null;
}

export enum PositionType {
  Player = 'Player',
  Coach = 'Coach',
  Operations = 'Operations',
}

export interface RankChangeRes {
  type: PlayerRatingChange;
  /** @format int32 */
  value?: number | null;
}

export enum RankingType {
  Player = 'Player',
  AllTimePlayer = 'AllTimePlayer',
  TransferPortal = 'TransferPortal',
  AllTimeTransferPortal = 'AllTimeTransferPortal',
  Draft = 'Draft',
  AllTimeDraft = 'AllTimeDraft',
  Organization = 'Organization',
  AllTimeOrganization = 'AllTimeOrganization',
  Roster = 'Roster',
  AllTimeRoster = 'AllTimeRoster',
  TeamTransferPortal = 'TeamTransferPortal',
  AllTimeTeamTransferPortal = 'AllTimeTeamTransferPortal',
  TeamComposite = 'TeamComposite',
  AllTimeTeamComposite = 'AllTimeTeamComposite',
}

export interface RatingChangeRes {
  type: PlayerRatingChange;
  /** @format double */
  value?: number | null;
}

export interface RebuildPersonFeedRequest {
  /** @minItems 1 */
  entityTypes?: FeedItemTypes[];
}

export interface RenovateBoltReq {
  /** @format int64 */
  assetKey?: number | null;
  /**
   * @format int64
   * @min 1
   */
  authorKey: number;
  body?: string | null;
  /**
   * Taken in preference to On3.Shared.Models.Requests.Main.RenovateBoltReq.HeadlineUrl
   * @format int64
   */
  headlineKey?: number | null;
  /** Use this if the key is not known or a token input doesn't exist */
  headlineUrl?: string | null;
  /** @format int64 */
  key?: number | null;
  taggedClassYears?: number[] | null;
  taggedPersonKeys?: number[] | null;
  taggedSportKeys?: number[] | null;
  taggedTierStatuses?: TierStatuses[] | null;
  /**
   * @minLength 0
   * @maxLength 200
   */
  title?: string | null;
}

export interface SendEliteTestNotificationRequest {
  condition?: string | null;
  /** @format int64 */
  eliteFeedItemKey?: number | null;
  /** @format int64 */
  personKey?: number | null;
  syncUserEliteSettings: boolean;
  topic?: string | null;
  /** @format int64 */
  userKey: number;
}

export type ServiceRankingMetaRes = AbstractRankingMetaRes & {
  serviceRanking?: ServiceRankingRes;
};

export interface ServiceRankingRes {
  nationalChange?: RankChangeRes;
  /** @format int32 */
  nationalRank?: number | null;
  positionChange?: RankChangeRes;
  /** @format int32 */
  positionRank?: number | null;
  previousRanking?: ServiceRankingRes;
  /** @format double */
  rating?: number | null;
  ratingChange?: RatingChangeRes;
  /** @format int32 */
  stars?: number | null;
  stateChange?: RankChangeRes;
  /** @format int32 */
  stateRank?: number | null;
  type?: string;
}

export interface SimpleAuthorResponse {
  displayName?: string | null;
  /** @format int64 */
  key: number;
  slug?: string | null;
}

export interface SimpleHeadlineResponse {
  /**
   * Date the article was published
   * @format date-time
   */
  datePublishedGmt: string;
  /** url of headline */
  fullUrl?: string | null;
  /** Denotes if the content is marked as premium or not */
  isPremium: boolean;
  /**
   * Key of headline
   * @format int64
   */
  key: number;
  /** Slug of headline */
  slug?: string | null;
  /** Title of headline */
  title?: string | null;
}

export interface SimplePersonResponse {
  /** @format int32 */
  classYear: number;
  firstName?: string;
  fullName?: string;
  /** @format int64 */
  key: number;
  knownAsName?: string | null;
  lastName?: string;
  slug?: string;
}

export interface SlimCategoryResponse {
  /** @format int64 */
  key: number;
  name?: string;
}

export interface SportResponseRecord {
  abbreviation?: string;
  isIndustryRankable: boolean;
  isRankable: boolean;
  /** @format int32 */
  key: number;
  name?: string;
}

export interface StateResponseRecord {
  abbreviation?: string;
  /** @format int32 */
  key: number;
  name?: string;
}

export interface StringListResourceResponseMessage {
  message?: string;
  resource?: string[];
  /** @format int32 */
  statusCode: number;
}

export enum TierStatuses {
  Unknown = 'Unknown',
  Elite = 'Elite',
  All = 'All',
}

export interface UpdateAdminNilDisputeRequest {
  /** @format int64 */
  key: number;
  reviewedNotes?: string | null;
  status: NilDisputeStatus;
}

export interface V1ArticlesRebuildFeedLocCreateParams {
  /**
   * @format int64
   * @default 18446744000000000000
   */
  startBefore?: number;
}

export interface V1BoltsListParams {
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   * @default 1
   */
  page?: number;
}

export interface V1EliteFeedItemsListParams {
  /**
   * @format int32
   * @min 1
   * @max 1000
   */
  limit?: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  page?: number;
  sendNotification?: boolean;
  statuses?: FeedItemStatuses[];
  /** @format int64 */
  typeKey?: number;
  types?: FeedItemTypes[];
}

export interface V1EliteFeedItemsSendNotificationsCreateParams {
  /** @default true */
  activityFeed?: boolean;
  /** @format int64 */
  eliteFeedItemKey: number;
}

export interface V1GraphicEditsRebuildFeedLocCreateParams {
  /**
   * @format int64
   * @default 18446744000000000000
   */
  startBefore?: number;
}

export interface V1NilDisputesDeleteParams {
  /** @format int64 */
  playerKey?: number;
}

export interface V1NilDisputesListParams {
  /**
   * @format int32
   * @default 25
   */
  limit?: number;
  /**
   * @format int32
   * @default 1
   */
  page?: number;
}

export interface V1NilValuationsRebuildFeedLocCreateParams {
  /**
   * @format int64
   * @default 18446744000000000000
   */
  startBefore?: number;
}

export interface V1NotificationsSyncTopicsUpdate2Params {
  /** @format int64 */
  userKeyStart?: number;
}

export interface V1OrganizationsValidateFeedHeadlinesDetailParams {
  /** @format int32 */
  organizationKey: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   * @default 1
   */
  page?: number;
}

export interface V1PeopleNilDisputesDetailParams {
  /**
   * @format int32
   * @default 25
   */
  limit?: number;
  /**
   * @format int32
   * @default 1
   */
  page?: number;
  /** @format int64 */
  personKey: number;
}

export interface V1RankingsSyncPersonFeedsCreateParams {
  /** @default true */
  industry?: boolean;
  /** @format int32 */
  rankingKey: number;
  /** @default true */
  sendNotification?: boolean;
}

export type QueryParamsType = Record<string | number, any>;

export type RequestParams = Omit<
  AxiosRequestConfig,
  'data' | 'method' | 'params' | 'url' | 'responseType'
>;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

/**
 * No description
 *
 * @tags Bolts
 * @name V1BoltsConvertMarkupCreate
 * @summary Converts a given string from one markup language to another.
 * @request POST:/elite/v1/bolts/convert-markup
 * @secure
 */
export const v1BoltsConvertMarkupCreate = (
  client: AxiosInstance,
  data: BoltConvertMarkupRequest,
  params: RequestParams = {},
) =>
  client
    .request<BoltConvertMarkupResponse>({
      url: `/elite/v1/bolts/convert-markup`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Bolts
 * @name V1BoltsDetail
 * @summary  (Roles: developer, rdb5)
 * @request GET:/elite/v1/bolts/{key}
 * @secure
 */
export const v1BoltsDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<DetailedBoltRes>({
      url: `/elite/v1/bolts/${key}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Bolts
 * @name V1BoltsList
 * @summary  (Roles: developer, rdb5)
 * @request GET:/elite/v1/bolts
 * @secure
 */
export const v1BoltsList = (
  client: AxiosInstance,
  query: V1BoltsListParams,
  params: RequestParams = {},
) =>
  client
    .request<BoltResPagedData>({
      url: `/elite/v1/bolts`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Bolts
 * @name V1BoltsUpdate
 * @summary  (Roles: developer, rdb5)
 * @request PUT:/elite/v1/bolts
 * @secure
 */
export const v1BoltsUpdate = (
  client: AxiosInstance,
  data: RenovateBoltReq,
  params: RequestParams = {},
) =>
  client
    .request<DetailedBoltRes>({
      url: `/elite/v1/bolts`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags EliteFeedItem
 * @name V1EliteFeedItemsDetail
 * @summary  (Roles: developer, rdb5)
 * @request GET:/elite/v1/elite-feed-items/{eliteFeedItemKey}
 * @secure
 */
export const v1EliteFeedItemsDetail = (
  client: AxiosInstance,
  eliteFeedItemKey: number,
  params: RequestParams = {},
) =>
  client
    .request<EliteFeedItemRes>({
      url: `/elite/v1/elite-feed-items/${eliteFeedItemKey}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags EliteFeedItem
 * @name V1EliteFeedItemsGenerateCreate
 * @summary  (Roles: developer, rdb5)
 * @request POST:/elite/v1/elite-feed-items/{eliteFeedItemKey}/generate/{feedItemGenerationType}
 * @secure
 */
export const v1EliteFeedItemsGenerateCreate = (
  client: AxiosInstance,
  eliteFeedItemKey: number,
  feedItemGenerationType: FeedItemGenerationTypes,
  params: RequestParams = {},
) =>
  client
    .request<boolean>({
      url: `/elite/v1/elite-feed-items/${eliteFeedItemKey}/generate/${feedItemGenerationType}`,
      method: 'POST',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags EliteFeedItem
 * @name V1EliteFeedItemsList
 * @summary  (Roles: developer, rdb5)
 * @request GET:/elite/v1/elite-feed-items
 * @secure
 */
export const v1EliteFeedItemsList = (
  client: AxiosInstance,
  query: V1EliteFeedItemsListParams,
  params: RequestParams = {},
) =>
  client
    .request<EliteFeedItemResPagedData>({
      url: `/elite/v1/elite-feed-items`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags EliteFeedItem
 * @name V1EliteFeedItemsSendNotificationsCreate
 * @summary  (Roles: developer, rdb5)
 * @request POST:/elite/v1/elite-feed-items/{eliteFeedItemKey}/send-notifications
 * @secure
 */
export const v1EliteFeedItemsSendNotificationsCreate = (
  client: AxiosInstance,
  { eliteFeedItemKey, ...query }: V1EliteFeedItemsSendNotificationsCreateParams,
  params: RequestParams = {},
) =>
  client
    .request<string>({
      url: `/elite/v1/elite-feed-items/${eliteFeedItemKey}/send-notifications`,
      method: 'POST',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Notifications
 * @name V1NotificationsSyncTopicsUpdate
 * @summary  (Roles: developer, rdb5)
 * @request PUT:/elite/v1/notifications/{userKey}/sync-topics
 * @secure
 */
export const v1NotificationsSyncTopicsUpdate = (
  client: AxiosInstance,
  userKey: number,
  params: RequestParams = {},
) =>
  client
    .request<boolean>({
      url: `/elite/v1/notifications/${userKey}/sync-topics`,
      method: 'PUT',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Notifications
 * @name V1NotificationsSyncTopicsUpdate2
 * @summary  (Roles: developer, rdb5)
 * @request PUT:/elite/v1/notifications/sync-topics
 * @originalName v1NotificationsSyncTopicsUpdate
 * @duplicate
 * @secure
 */
export const v1NotificationsSyncTopicsUpdate2 = (
  client: AxiosInstance,
  query: V1NotificationsSyncTopicsUpdate2Params,
  params: RequestParams = {},
) =>
  client
    .request<number[]>({
      url: `/elite/v1/notifications/sync-topics`,
      method: 'PUT',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Notifications
 * @name V1NotificationsTestCreate
 * @summary  (Roles: developer, rdb5)
 * @request POST:/elite/v1/notifications/test
 * @secure
 */
export const v1NotificationsTestCreate = (
  client: AxiosInstance,
  data: SendEliteTestNotificationRequest,
  params: RequestParams = {},
) =>
  client
    .request<string>({
      url: `/elite/v1/notifications/test`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDispute
 * @name V1NilDisputeRequestCreate
 * @summary Creates NIL dispute for a player
 * @request POST:/nil/v1/nil-dispute-request
 * @secure
 */
export const v1NilDisputeRequestCreate = (
  client: AxiosInstance,
  data: NilDisputeRequest,
  params: RequestParams = {},
) =>
  client
    .request<NilDisputeResponse>({
      url: `/nil/v1/nil-dispute-request`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDispute
 * @name V1NilDisputesDelete
 * @summary Deletes NIL dispute for given NIL dispute key
 * @request DELETE:/nil/v1/nil-disputes
 * @secure
 */
export const v1NilDisputesDelete = (
  client: AxiosInstance,
  query: V1NilDisputesDeleteParams,
  params: RequestParams = {},
) =>
  client
    .request<NilDisputeResponsePagedData>({
      url: `/nil/v1/nil-disputes`,
      method: 'DELETE',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDispute
 * @name V1NilDisputesDetail
 * @summary Gets given dispute for key for admin
 * @request GET:/nil/v1/nil-disputes/{key}
 * @secure
 */
export const v1NilDisputesDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<NilDisputeResponse>({
      url: `/nil/v1/nil-disputes/${key}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDispute
 * @name V1NilDisputesList
 * @summary Gets list of disputes for db admin
 * @request GET:/nil/v1/nil-disputes
 * @secure
 */
export const v1NilDisputesList = (
  client: AxiosInstance,
  query: V1NilDisputesListParams,
  params: RequestParams = {},
) =>
  client
    .request<NilDisputeResponsePagedData>({
      url: `/nil/v1/nil-disputes`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDispute
 * @name V1NilDisputesUpdate
 * @summary Modifies NIL dispute for when admin is reviewing
 * @request PUT:/nil/v1/nil-disputes
 * @secure
 */
export const v1NilDisputesUpdate = (
  client: AxiosInstance,
  data: UpdateAdminNilDisputeRequest,
  params: RequestParams = {},
) =>
  client
    .request<NilDisputeResponse>({
      url: `/nil/v1/nil-disputes`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDispute
 * @name V1PeopleNilDisputesDetail
 * @summary Gets Player's NIL Disputes
 * @request GET:/nil/v1/people/{personKey}/nil-disputes
 * @secure
 */
export const v1PeopleNilDisputesDetail = (
  client: AxiosInstance,
  { personKey, ...query }: V1PeopleNilDisputesDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<NilDisputeResponsePagedData>({
      url: `/nil/v1/people/${personKey}/nil-disputes`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonFeed
 * @name V1ArticlesRebuildFeedLocCreate
 * @request POST:/rdb/v1/articles/rebuild-feed-loc
 */
export const v1ArticlesRebuildFeedLocCreate = (
  client: AxiosInstance,
  query: V1ArticlesRebuildFeedLocCreateParams,
  params: RequestParams = {},
) =>
  client
    .request<number>({
      url: `/rdb/v1/articles/rebuild-feed-loc`,
      method: 'POST',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonFeed
 * @name V1GraphicEditsRebuildFeedLocCreate
 * @request POST:/rdb/v1/graphic-edits/rebuild-feed-loc
 */
export const v1GraphicEditsRebuildFeedLocCreate = (
  client: AxiosInstance,
  query: V1GraphicEditsRebuildFeedLocCreateParams,
  params: RequestParams = {},
) =>
  client
    .request<number>({
      url: `/rdb/v1/graphic-edits/rebuild-feed-loc`,
      method: 'POST',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonFeed
 * @name V1NilValuationsRebuildFeedLocCreate
 * @request POST:/rdb/v1/nil-valuations/rebuild-feed-loc
 */
export const v1NilValuationsRebuildFeedLocCreate = (
  client: AxiosInstance,
  query: V1NilValuationsRebuildFeedLocCreateParams,
  params: RequestParams = {},
) =>
  client
    .request<number>({
      url: `/rdb/v1/nil-valuations/rebuild-feed-loc`,
      method: 'POST',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags OrganizationFeed
 * @name V1OrganizationsRebuildFeedCreate
 * @summary  (Roles: developer, rdb5)
 * @request POST:/rdb/v1/organizations/{organizationKey}/rebuild-feed
 * @secure
 */
export const v1OrganizationsRebuildFeedCreate = (
  client: AxiosInstance,
  organizationKey: number,
  params: RequestParams = {},
) =>
  client
    .request<number>({
      url: `/rdb/v1/organizations/${organizationKey}/rebuild-feed`,
      method: 'POST',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags OrganizationFeed
 * @name V1OrganizationsValidateFeedHeadlinesDetail
 * @summary  (Roles: developer, rdb5)
 * @request GET:/rdb/v1/organizations/{organizationKey}/validate-feed-headlines
 * @secure
 */
export const v1OrganizationsValidateFeedHeadlinesDetail = (
  client: AxiosInstance,
  {
    organizationKey,
    ...query
  }: V1OrganizationsValidateFeedHeadlinesDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<number[]>({
      url: `/rdb/v1/organizations/${organizationKey}/validate-feed-headlines`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonFeed
 * @name V1PeopleRebuildFeedCreate
 * @summary  (Roles: developer, rdb5, rdb4, elite_admin)
 * @request POST:/rdb/v1/people/{personKey}/rebuild-feed
 * @secure
 */
export const v1PeopleRebuildFeedCreate = (
  client: AxiosInstance,
  personKey: number,
  data: RebuildPersonFeedRequest,
  params: RequestParams = {},
) =>
  client
    .request<number>({
      url: `/rdb/v1/people/${personKey}/rebuild-feed`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonFeed
 * @name V1PeopleRebuildFeedLocCreate
 * @request POST:/rdb/v1/people/{personKey}/rebuild-feed-loc
 */
export const v1PeopleRebuildFeedLocCreate = (
  client: AxiosInstance,
  personKey: number,
  data: RebuildPersonFeedRequest,
  params: RequestParams = {},
) =>
  client
    .request<number>({
      url: `/rdb/v1/people/${personKey}/rebuild-feed-loc`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonFeed
 * @name V1RankingsSyncPersonFeedsCreate
 * @summary  (Roles: developer, rdb5)
 * @request POST:/rdb/v1/rankings/{rankingKey}/sync-person-feeds
 * @secure
 */
export const v1RankingsSyncPersonFeedsCreate = (
  client: AxiosInstance,
  { rankingKey, ...query }: V1RankingsSyncPersonFeedsCreateParams,
  params: RequestParams = {},
) =>
  client
    .request<number>({
      url: `/rdb/v1/rankings/${rankingKey}/sync-person-feeds`,
      method: 'POST',
      params: query,
      ...params,
    })
    .then((r) => r.data);
