import { useAuth } from '@on3/ui-lib';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useCallback, useEffect } from 'react';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _qevents: any;
  }
}

const QUANTCAST_ACCOUNT_ID = 'p-_zRUJy1MUNrxD';

const displayNone = { display: 'none' };

const quantcastPageview = (userID: string) => {
  window._qevents = window._qevents || [];

  window?._qevents.push({
    qacct: QUANTCAST_ACCOUNT_ID,
    uid: `${userID}`,
  });
};

export const Quantcast = () => {
  const { user } = useAuth();
  const { asPath: path } = useRouter();

  useEffect(() => {
    quantcastPageview(user?.uid || '');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  const quantcastScript = useCallback(() => {
    return {
      __html: `(function() {
          var elem = document.createElement('script');
          elem.src = (document.location.protocol == "https:" ? "https://secure" : "http://edge") + ".quantserve.com/quant.js";
          elem.async = true;
          elem.type = "text/javascript";
          var scpt = document.getElementsByTagName('script')[0];
          scpt.parentNode.insertBefore(elem, scpt);
        })();`,
    };
  }, []);

  return (
    <>
      <Script
        dangerouslySetInnerHTML={quantcastScript()}
        strategy="lazyOnload"
      />
      <noscript>
        <div style={displayNone}>
          <img
            alt="Quantcast"
            height="1"
            src="http://pixel.quantserve.com/pixel/p-_zRUJy1MUNrxD.gif"
            width="1"
          />
        </div>
      </noscript>
    </>
  );
};
