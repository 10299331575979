import type { AxiosInstance, AxiosRequestConfig } from 'axios';

export interface AdminSiteSubConfigRes {
  site: SimpleSiteResponseRecord;
  subConfig?: SiteSubConfigRes;
}

export interface AdminSiteSubConfigResPagedData {
  list?: AdminSiteSubConfigRes[];
  pagination?: Pagination;
}

/** Specifies the kind of asset and Asset object is. */
export enum AssetClasses {
  Unknown = 'Unknown',
  Image = 'Image',
  Video = 'Video',
}

export interface AssetResponse {
  altText?: string | null;
  /** Specifies the kind of asset and Asset object is. */
  assetType: AssetClasses;
  caption?: string | null;
  category?: Category;
  description?: string | null;
  domain?: string | null;
  domainOverride?: string | null;
  /** @format int32 */
  duration: number;
  fileSystem?: string | null;
  /** @format int32 */
  height?: number | null;
  /** @format int64 */
  key: number;
  mimeType?: string | null;
  path?: string | null;
  source?: string | null;
  sourceOverride?: string | null;
  thumbnail?: string | null;
  title?: string | null;
  type?: string | null;
  /** @format int32 */
  width?: number | null;
}

export interface AttributeResponse {
  abbr?: string | null;
  /** @format int64 */
  key: number;
  name?: string | null;
}

export interface BillingAddress {
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;
}

export enum Category {
  Undetermined = 'Undetermined',
  Highlights = 'Highlights',
  Training = 'Training',
  Experts = 'Experts',
  Conversations = 'Conversations',
  Camp = 'Camp',
}

export interface CheckAvailabilityResponse {
  available: boolean;
}

export interface CityResponseRecord {
  country?: CountryResponseRecord;
  /** @format int32 */
  key: number;
  name?: string;
  state?: StateResponseRecord;
}

export interface ContactInformationRequest {
  email?: string | null;
  name?: string | null;
  phoneNumber?: string | null;
}

export interface ContactInformationResponse {
  email?: string | null;
  name?: string | null;
  phoneNumber?: string | null;
}

export interface CountryResponseRecord {
  abbreviation?: string;
  /** @format int32 */
  key: number;
  name?: string;
}

/** Request representing coupon check */
export interface CouponValidateRequest {
  /** Optional Coupon Code */
  couponCode?: string | null;
  /**
   * Product to purchase
   * @format int32
   */
  productKey: number;
}

export interface CreateUserRequest {
  /** @minLength 1 */
  email: string;
  firstName?: string | null;
  lastName?: string | null;
  /** @minLength 1 */
  password: string;
  /**
   * If true, logs the user in after creation (On3.Shared.Models.User.UserJwtResponse). Otherwise, the user is created
   * and the response is empty.
   */
  process: boolean;
  /** Optional username for the person registering. Auto-generated if not specified. */
  username?: string | null;
}

export enum DeviceClient {
  FanApp = 'FanApp',
  EliteApp = 'EliteApp',
}

export interface DeviceTokenResponse {
  /** @format int32 */
  buildNumber?: number | null;
  client?: DeviceClient;
  deviceType: DeviceType;
  /** @format int64 */
  key: number;
  /** @format date-time */
  lastSeenAt?: string | null;
  token?: string;
  /** @format int64 */
  userKey: number;
}

export enum DeviceType {
  Android = 'Android',
  Browser = 'Browser',
  IOS = 'iOS',
}

export interface DownloadExternalAssetsReq {
  urls: string[];
}

export interface ImageAdminResponse {
  altText?: string | null;
  caption?: string | null;
  category?: Int32StringKeyValuePair;
  description?: string | null;
  /** @format int32 */
  height?: number | null;
  /** @format int64 */
  key: number;
  /** @format int64 */
  organizationKey?: number | null;
  /** @format int64 */
  personKey?: number | null;
  personSport?: PersonSportBase;
  sourceUrl?: string | null;
  title?: string | null;
  /** @format int32 */
  width?: number | null;
}

export interface ImageAdminResponseListResourceResponseMessage {
  message?: string;
  resource?: ImageAdminResponse[];
  /** @format int32 */
  statusCode: number;
}

export interface ImageResponse {
  altText?: string | null;
  /** Specifies the kind of asset and Asset object is. */
  assetType: AssetClasses;
  caption?: string | null;
  category?: Category;
  description?: string | null;
  domain?: string | null;
  domainOverride?: string | null;
  /** @format int32 */
  duration: number;
  fileSystem?: string | null;
  /** @format int32 */
  height?: number | null;
  /** @format int64 */
  key: number;
  mimeType?: string | null;
  path?: string | null;
  source?: string | null;
  sourceOverride?: string | null;
  thumbnail?: string | null;
  title?: string | null;
  type?: string | null;
  /** @format int32 */
  width?: number | null;
}

export interface Int32StringKeyValuePair {
  /** @format int32 */
  key: number;
  value?: string;
}

export interface Int64NullableValueVisibility {
  /** @format int64 */
  value?: number | null;
  visibility: Visibility;
}

export interface Int64ValueVisibility {
  /** @format int64 */
  value: number;
  visibility: Visibility;
}

export interface KeyUrlBase {
  fullName?: string;
  /** @format int64 */
  key: number;
  slug?: string | null;
  url?: string | null;
}

export interface LoginAsRequest {
  /**
   * Overrides the user's person key to simulate a claimed profile for an arbitrary athlete/coach.
   * @format int64
   */
  personKeyOverride?: number | null;
  /** @format int64 */
  userKey: number;
}

/** Request representing a user login attempt using Xenforo/WordPress credentials */
export interface LoginUserRequest {
  /**
   * The WP/Xenforo password of the user
   * @minLength 1
   */
  password: string;
  /**
   * The username or email address of the user
   * @minLength 1
   */
  username: string;
}

export interface MemberSettingResponse {
  avatarUrl?: string | null;
  billingAddress?: BillingAddress;
  email?: string | null;
  /** @format int32 */
  lastLoginTimestamp?: number | null;
  name?: string | null;
  /** @format int64 */
  userId: number;
  userName: string;
}

export interface NewsletterListResponse {
  /** @format int64 */
  id: number;
  name?: string;
}

export interface NewsletterListResponsePagedData {
  list?: NewsletterListResponse[];
  pagination?: Pagination;
}

export interface NewsletterSubscribeRequest {
  /**
   * @minLength 0
   * @maxLength 40
   */
  campaign?: string | null;
  /**
   * @minLength 0
   * @maxLength 40
   */
  campaignSource?: string | null;
  /** @minLength 1 */
  email: string;
  requireConfirmation: boolean;
}

export interface NotFoundResult {
  /** @format int32 */
  statusCode: number;
}

export interface OrganizationBase {
  abbreviation?: string | null;
  asset?: AssetResponse;
  assetUrl?: string | null;
  fullName?: string;
  /** @format int32 */
  key: number;
  mascot?: string | null;
  name?: string;
  primaryColor?: string | null;
  slug?: string | null;
}

export interface OrganizationResponse {
  abbreviation?: string | null;
  assetUrl?: string | null;
  defaultAsset?: AssetResponse;
  division?: string | null;
  fullName?: string;
  /** @format int32 */
  key: number;
  knownAs?: string | null;
  mascot?: string | null;
  name?: string;
  orgType?: string;
  orgTypeEnum: OrganizationType;
  primaryColor?: string | null;
  siteKeys?: number[] | null;
  slug?: string | null;
  urlSlug?: string | null;
}

export enum OrganizationType {
  Unknown = 'Unknown',
  HighSchool = 'HighSchool',
  JuniorCollege = 'JuniorCollege',
  College = 'College',
  Professional = 'Professional',
  PrepSchool = 'PrepSchool',
  Developmental = 'Developmental',
}

export interface Pagination {
  /** @format int32 */
  count: number;
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  itemsPerPage: number;
  /** @format int32 */
  limit: number;
  /** @format int32 */
  offset: number;
  /** @format int32 */
  pageCount: number;
}

export interface PersonEliteSettingsResponse {
  player?: SmallPlayerResponse;
  settings?: PersonProfileSettingsResponse;
}

export interface PersonProfileSettingsResponse {
  agentInfo?: ContactInformationResponse;
  birthDate?: Int64NullableValueVisibility;
  cellNumber?: StringValueVisibility;
  email?: StringValueVisibility;
  instagram?: SocialResponse;
  loginEmail?: string | null;
  parentInfo?: ContactInformationResponse;
  secondaryParentInfo?: ContactInformationResponse;
  tikTok?: SocialResponse;
  twitter?: SocialResponse;
}

export interface PersonSettingsRequest {
  agentInfo?: ContactInformationRequest;
  birthDate?: Int64ValueVisibility;
  cellNumber?: StringValueVisibility;
  email?: StringValueVisibility;
  instagram?: SocialRequest;
  parentInfo?: ContactInformationRequest;
  secondaryParentInfo?: ContactInformationRequest;
  tikTok?: SocialRequest;
  twitter?: SocialRequest;
}

export interface PersonSportBase {
  /** @format int64 */
  key: number;
  name?: string | null;
}

export interface PositionResponse {
  abbreviation?: string | null;
  /** @format int32 */
  key: number;
  name?: string;
  positionType: PositionType;
  sport?: SportResponseRecord;
  /** @format int32 */
  sportKey?: number | null;
}

export enum PositionType {
  Player = 'Player',
  Coach = 'Coach',
  Operations = 'Operations',
}

export interface ProblemDetails {
  detail?: string | null;
  instance?: string | null;
  /** @format int32 */
  status?: number | null;
  title?: string | null;
  type?: string | null;
  [key: string]: any;
}

export interface PublicOpenSearchResponse {
  /** @format int32 */
  age?: number | null;
  articleTaggingEnabled?: boolean | null;
  athleteVerified: boolean;
  classRank?: string;
  /**
   * The person's high school class year
   * @format int32
   */
  classYear?: number | null;
  currentOrganization?: OrganizationBase;
  /** @format date-time */
  dateOfBirth?: string | null;
  defaultAsset?: ImageResponse;
  /** The url of the person's default asset */
  defaultAssetUrl?: string | null;
  defaultSport?: SportResponseRecord;
  division?: string | null;
  /** Did the person enroll early */
  earlyEnrollee: boolean;
  /** Did the person sign early */
  earlySignee: boolean;
  firstName?: string;
  /** The person's height in Feet and Inches */
  formattedHeight?: string | null;
  goesBy?: string | null;
  /**
   * The person's height in inches
   * @format double
   */
  height?: number | null;
  highSchool?: OrganizationResponse;
  /** The name of the High School the person attended */
  highSchoolName?: string | null;
  /** The name of the person's hometown */
  homeTownName?: string | null;
  hometown?: CityResponseRecord;
  /** @format int64 */
  key: number;
  lastName?: string;
  /** The full name of the person */
  name?: string;
  organizationLevel?: OrganizationType;
  position?: PositionResponse;
  /** The abbreviation of the person's primary position */
  positionAbbreviation?: string | null;
  positionTypes?: PositionType[] | null;
  predictions?: RpmResponse[] | null;
  prospectVerified: boolean;
  rating?: RatingExtendedResponse;
  /** @format int64 */
  recruitmentKey?: number | null;
  /** The slug of the person */
  slug?: string;
  sport?: SportResponseRecord;
  status?: Status;
  /**
   * The person's weight in pounds
   * @format double
   */
  weight?: number | null;
}

export interface RatingExtendedResponse {
  /** @format int32 */
  consensusNationalRank?: number | null;
  /** @format int32 */
  consensusPositionRank?: number | null;
  /** @format double */
  consensusRating?: number | null;
  /** @format int32 */
  consensusStars?: number | null;
  /** @format int32 */
  consensusStateRank?: number | null;
  fiveStarPlus: boolean;
  /** @format int64 */
  key: number;
  /** @format int32 */
  nationalRank?: number | null;
  positionAbbr?: string | null;
  /** @format int32 */
  positionRank?: number | null;
  /** @format double */
  rating?: number | null;
  /** @format int32 */
  stars?: number | null;
  stateAbbr?: string | null;
  /** @format int32 */
  stateRank?: number | null;
}

export interface RecruitmentStatusResponse {
  /** @format date-time */
  commitmentDate?: string | null;
  committedOrganizationAsset?: ImageResponse;
  committedOrganizationAssetUrl?: string | null;
  /** @format int64 */
  committedOrganizationKey?: number | null;
  committedOrganizationPrimaryColor?: string | null;
  committedOrganizationSlug?: string | null;
  /** @format int32 */
  highestInterestLevel?: number | null;
  /** @format int32 */
  interestCount: number;
  isCommitted: boolean;
  isEnrolled: boolean;
  isSigned: boolean;
  isTransfer: boolean;
  /** @format int32 */
  recruitmentYear: number;
  shortTermSignee: boolean;
  sportName?: string | null;
  transferredFromOrganizationAssetUrl?: string | null;
  transferredFromOrganizationSlug?: string | null;
}

export interface RefreshRequest {
  /** @minLength 1 */
  refreshToken: string;
}

export interface RenSiteSubConfigReq {
  /**
   * @minLength 0
   * @maxLength 100
   */
  annualArticleTemplateVariantId?: string | null;
  /**
   * @minLength 0
   * @maxLength 100
   */
  annualJoinTemplateVariantId?: string | null;
  /**
   * @minLength 0
   * @maxLength 100
   */
  annualOffer?: string | null;
  /**
   * @minLength 0
   * @maxLength 100
   */
  annualPromotionCode?: string | null;
  /**
   * @minLength 0
   * @maxLength 100
   */
  articleTemplateId?: string | null;
  /**
   * @minLength 0
   * @maxLength 100
   */
  joinTemplateId?: string | null;
  /** @format int64 */
  key?: number | null;
  /**
   * @minLength 0
   * @maxLength 100
   */
  monthlyArticleTemplateVariantId?: string | null;
  /**
   * @minLength 0
   * @maxLength 100
   */
  monthlyJoinTemplateVariantId?: string | null;
  /**
   * @minLength 0
   * @maxLength 100
   */
  monthlyOffer?: string | null;
  /**
   * @minLength 0
   * @maxLength 100
   */
  monthlyPromotionCode?: string | null;
  /** @format int32 */
  siteKey: number;
}

export interface RenovateImageRequest {
  altText?: string | null;
  caption?: string | null;
  category?: Category;
  description?: string | null;
  /** @format binary */
  file?: File | null;
  /** @format int64 */
  key?: number | null;
  /** @format int32 */
  organizationKey?: number | null;
  /** @format int64 */
  personKey?: number | null;
  /** @format int64 */
  personSportKey?: number | null;
  personSportOrganizationKeys?: number[];
  /**
   * @minLength 0
   * @maxLength 255
   */
  title: string;
}

export interface RenovateUserFollowedOrgReq {
  /** @format int32 */
  organizationKey: number;
  settings?: UofSettings;
  /** @format int64 */
  userKey: number;
}

export interface RenovateUserFollowedPerReq {
  /** @format int64 */
  personKey: number;
  settings?: UpfSettings;
  /** @format int64 */
  userKey: number;
}

export interface RenovateVideoRequest {
  category?: Category;
  description?: string | null;
  isFeatured: boolean;
  /** @format int64 */
  key?: number | null;
  /** @format int64 */
  personKey?: number | null;
  /** @format int64 */
  personSportKey?: number | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  sourceUrl: string;
  /**
   * @minLength 0
   * @maxLength 255
   */
  thumbnail?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  title?: string | null;
}

export interface ResponseMessage {
  message?: string;
  /** @format int32 */
  statusCode: number;
}

export interface RpmResponse {
  committed: boolean;
  /** @format date-time */
  committedDate?: string | null;
  /** @format double */
  distance?: number | null;
  flippedFromOrganization?: Team;
  /** @format int64 */
  key: number;
  organization?: Team;
  /** @format double */
  percent?: number | null;
  status?: string | null;
  /** @format date-time */
  statusDate?: string | null;
}

export interface SimpleSiteResponseRecord {
  allowSubs: boolean;
  alternateAvatar?: AssetResponse;
  /** @format int64 */
  alternateAvatarKey?: number | null;
  alternateLogo?: AssetResponse;
  /** @format int64 */
  alternateLogoKey?: number | null;
  /** @format int64 */
  categoryKey?: number | null;
  defaultAsset?: AssetResponse;
  /** @format int64 */
  defaultAssetKey?: number | null;
  defaultAvatar?: AssetResponse;
  /** @format int64 */
  defaultAvatarKey?: number | null;
  /** @format int64 */
  defaultTrendingListKey?: number | null;
  facebookProfile?: string | null;
  /** @format int32 */
  forumKey?: number | null;
  gTagId?: string | null;
  googleAnalyticsProjectId?: string | null;
  inIndex: boolean;
  instagramProfile?: string | null;
  isChannel: boolean;
  isNational: boolean;
  isTeam: boolean;
  jwPlaylistId?: string | null;
  /** @format int32 */
  key: number;
  keyPersons?: SimpleUserResponse[] | null;
  linkColor?: string | null;
  live: boolean;
  /** @format int32 */
  menuKey?: number | null;
  name?: string | null;
  /** @format int32 */
  organizationKey?: number | null;
  primaryColor?: string | null;
  /** @format int32 */
  primaryForumId?: number | null;
  resourceId?: string | null;
  secondaryColor?: string | null;
  /** @format int32 */
  siblingSiteKey?: number | null;
  slug?: string | null;
  subscribeText?: string | null;
  tagLine?: string | null;
  template: SiteTemplate;
  templateId?: string | null;
  tertiaryColor?: string | null;
  twitterHandle?: string | null;
  type: SiteType;
  url?: string | null;
  youTubeChannelId?: string | null;
  youtubeProfile?: string | null;
}

export interface SimpleUserResponse {
  displayName?: string | null;
  /** @format int64 */
  key: number;
  login?: string | null;
  niceName?: string | null;
}

export interface SiteSubConfigRes {
  annualArticleTemplateVariantId?: string | null;
  annualJoinTemplateVariantId?: string | null;
  annualOffer?: string | null;
  annualPromotionCode?: string | null;
  articleTemplateId?: string | null;
  joinTemplateId?: string | null;
  /** @format int64 */
  key: number;
  monthlyArticleTemplateVariantId?: string | null;
  monthlyJoinTemplateVariantId?: string | null;
  monthlyOffer?: string | null;
  monthlyPromotionCode?: string | null;
  /** @format int32 */
  siteKey: number;
}

export enum SiteTemplate {
  Feed = 'Feed',
  Traditional = 'Traditional',
}

export enum SiteType {
  National = 'National',
  Channel = 'Channel',
  Team = 'Team',
}

export interface SmallPlayerResponse {
  /** @format int32 */
  classYear?: number | null;
  defaultAsset?: AssetResponse;
  firstName?: string;
  fullName?: string;
  height?: string | null;
  /** @format int64 */
  key: number;
  lastName?: string;
  primaryPosition?: AttributeResponse;
  /** @format int64 */
  recruitmentKey?: number | null;
  slug?: string;
  /** @format double */
  weight?: number | null;
}

export interface SocialRequest {
  handle?: string | null;
}

export interface SocialResponse {
  handle?: string | null;
  verified?: boolean | null;
}

export interface SportResponseRecord {
  abbreviation?: string;
  isIndustryRankable: boolean;
  isRankable: boolean;
  /** @format int32 */
  key: number;
  name?: string;
}

export interface StateResponseRecord {
  abbreviation?: string;
  /** @format int32 */
  key: number;
  name?: string;
}

export interface Status {
  classRank?: string | null;
  committedAsset?: KeyUrlBase;
  committedAssetRes?: AssetResponse;
  committedOrganization?: OrganizationBase;
  /** @format date-time */
  date?: string | null;
  decommittedAsset?: OrganizationResponse;
  /** @format int32 */
  recruitmentYear?: number | null;
  shortTermSignee: boolean;
  /** @format date-time */
  transferEntered?: string | null;
  transferredAsset?: KeyUrlBase;
  transferredAssetRes?: AssetResponse;
  type?: string;
}

export interface StringListResourceResponseMessage {
  message?: string;
  resource?: string[];
  /** @format int32 */
  statusCode: number;
}

export interface StringValueVisibility {
  value?: string | null;
  visibility: Visibility;
}

export interface SubscribedTopicsResponse {
  device: DeviceTokenResponse;
  topics: string[];
}

export interface SyncImagesToOffloadReq {
  /** @minItems 1 */
  assetKeys: number[];
}

export interface SyncOffloadsToImagesReq {
  /** @minItems 1 */
  attachmentKeys: number[];
}

export interface SyncOrphanedAttachmentsRequest {
  /** @format int64 */
  startBefore?: number | null;
}

export interface SyncUnknownAssetsRequest {
  /** @format int64 */
  startBefore?: number | null;
}

export interface SyncUserPermissionsFriendlyResponse {
  /** Groups the user was added to on this request */
  added: XfUserGroupResponse[];
  /** All groups the user is in after this request */
  groups: XfUserGroupResponse[];
  /** Groups the user was removed from in this request */
  removed: XfUserGroupResponse[];
}

export interface SyncUsersPermissionsApiRequest {
  dryRun?: boolean | null;
}

export enum SyncUsersToSailThruStatus {
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  Complete = 'Complete',
  Failed = 'Failed',
}

export interface SyncUsersToSailThruStatusResponse {
  status: SyncUsersToSailThruStatus;
  /** @format int32 */
  totalUsersSynced: number;
}

export interface Team {
  abbreviation?: string | null;
  asset?: AssetResponse;
  assetUrl?: KeyUrlBase;
  fullName?: string | null;
  /** @format int32 */
  key: number;
  mascot?: string | null;
  name?: string;
  primaryColor?: string | null;
  slug?: string | null;
}

export interface UofSettings {
  all: boolean;
  articleTag: boolean;
}

export interface UpdateSailThruForUserRequest {
  resourceId?: string | null;
}

export interface UpdateUserNameResponse {
  /** @format date-time */
  refreshBefore: string;
  refreshToken?: string | null;
  token?: string | null;
  userName?: string | null;
}

export interface UpfSettings {
  all: boolean;
  articleTag: boolean;
  changeInNilValuation: boolean;
  changeInRecruitingRanking: boolean;
}

export interface UserAppSettingValue {
  /** @format int32 */
  key: number;
  newsFeed: UserAppSettingsStatus;
  notifications: UserAppSettingsStatus;
  /** @format int32 */
  order?: number | null;
}

/** User settings for the Fan App that is synced across devices. */
export interface UserAppSettings {
  /**
   * The version of the settings "schema" to accommodate breaking changes in a manageable way.
   * @format int32
   * @example 1
   */
  $version?: number;
  categories: UserAppSettingValue[];
  /** @format int32 */
  defaultSiteKey: number | null;
  /**
   * The user's favorite sites/teams. If notifications are enabled for a site then the user is subscribed to the
   * associated organization's topic. If there is no associated organization they are subscribed to the site's topic.
   */
  sites: UserAppSettingValue[];
  /**
   * The last time the settings were updated (unix timestamp - seconds). This value is set in the API, so request
   * values are effectively ignored.
   * @format int32
   */
  updatedAt: number;
  writers: UserAppSettingValue[];
}

export interface UserAppSettingsResponse {
  /**
   * Indicates whether the settings are read-only or not. Settings can be read-only if the saved settings are a higher
   * schema version than requested.
   */
  readOnly: boolean;
  /** User settings for the Fan App that is synced across devices. */
  settings: UserAppSettings;
  /** Indicates the saved settings are a higher version than requested, so an app update is required to use them. */
  updateRequired: boolean;
}

export enum UserAppSettingsStatus {
  Unknown = 'Unknown',
  Enabled = 'Enabled',
  Disabled = 'Disabled',
}

export interface UserAvatarUpdateResponse {
  avatarUrl?: string | null;
  clientError: boolean;
  message?: string | null;
  success: boolean;
}

export interface UserEliteProfileSettingsRequest {
  articleTag: boolean;
  bolts: boolean;
  changeInNilValuation: boolean;
  changeInRecruitingRanking: boolean;
  newFeatures: boolean;
  newReportedDeal: boolean;
  tipsAndAdvice: boolean;
}

export interface UserEliteProfileSettingsResponse {
  articleTag: boolean;
  bolts: boolean;
  changeInNilValuation: boolean;
  changeInRecruitingRanking: boolean;
}

export interface UserEliteSettingsRequest {
  /** @format int64 */
  personKey: number;
  profile: UserEliteProfileSettingsRequest;
}

export interface UserEliteSettingsResponse {
  /** @format int64 */
  personKey: number;
  profile?: UserEliteProfileSettingsResponse;
}

export interface UserEliteSettingsResponsePagedData {
  list?: UserEliteSettingsResponse[];
  pagination?: Pagination;
}

export interface UserFollowedOrgRes {
  hidden: boolean;
  /** @format int64 */
  key: number;
  organization?: OrganizationResponse;
  /** @format int32 */
  organizationKey: number;
  settings?: UofSettings;
  /** @format int64 */
  userKey: number;
}

export interface UserFollowedOrgResPagedData {
  list?: UserFollowedOrgRes[];
  pagination?: Pagination;
}

export interface UserFollowedPerRes {
  hidden: boolean;
  /** @format int64 */
  key: number;
  person?: PublicOpenSearchResponse;
  /** @format int64 */
  personKey: number;
  settings?: UpfSettings;
  /** @format int64 */
  userKey: number;
}

export interface UserFollowedPerResPagedData {
  list?: UserFollowedPerRes[];
  pagination?: Pagination;
}

/**
 * Response representing a user and the JWT
 * associated
 */
export interface UserJwtResponse {
  /** The display name of the user */
  displayName?: string | null;
  email: string | null;
  /** Does the user have at least one active subscription */
  hasActiveSubscription: boolean;
  latestTransactionId?: string | null;
  /** @format int64 */
  personKey?: number | null;
  /**
   * The pianoId of the user
   * @format uuid
   */
  pianoId?: string | null;
  promotionEligible: boolean;
  /**
   * The time when the Token will be expired
   * @format date-time
   */
  refreshBefore: string;
  /** The refresh token used to get a new Token */
  refreshToken?: string | null;
  /**
   * The date/time the refresh token expires
   * @format date-time
   */
  refreshTokenExpiry: string;
  /** The list of string roles */
  roles?: string[] | null;
  sites?: number[] | null;
  /** What is the max type of subscription a user has */
  subscriptionMaxType?: string | null;
  /** List of active subscription keys */
  subscriptions?: number[] | null;
  /** The JWT to use */
  token?: string | null;
  /**
   * The id of the user
   * @format int64
   */
  userId: number;
  /** The username of the user */
  username?: string | null;
}

export interface UserNameUpdateRequest {
  /** @minLength 1 */
  userName: string;
}

export interface UserNewsletterListRequest {
  enrolled: boolean;
  /** @format int64 */
  id: number;
  name?: string;
}

export interface UserNewsletterListResponse {
  enrolled: boolean;
  /** @format int64 */
  id: number;
  name?: string;
}

export interface UserNewsletterListResponsePagedData {
  list?: UserNewsletterListResponse[];
  pagination?: Pagination;
}

export interface UserSearchResponse {
  email?: string;
  /** @format int64 */
  key: number;
  username?: string;
}

export interface UserSearchResponsePagedData {
  list?: UserSearchResponse[];
  pagination?: Pagination;
}

export interface UserSingleNewsletterListRequest {
  enrolled: boolean;
}

export interface V1ImagesAdminListParams {
  /**
   * @format int32
   * @default 1
   */
  page?: number;
}

export interface V1ImagesRenovateSingleUpdatePayload {
  altText?: string;
  caption?: string;
  category?: Category;
  description?: string;
  /** @format binary */
  file?: File;
  /** @format int64 */
  key?: number;
  /** @format int32 */
  organizationKey?: number;
  /** @format int64 */
  personKey?: number;
  /** @format int64 */
  personSportKey?: number;
  personSportOrganizationKeys?: number[];
  /**
   * @minLength 0
   * @maxLength 255
   */
  title: string;
}

export interface V1ImagesRenovateUpdatePayload {
  request?: RenovateImageRequest[];
}

export interface V1OrganizationsImagesAdminDetailParams {
  /** @format int64 */
  organizationKey: number;
  /**
   * @format int32
   * @default 1
   */
  page?: number;
}

export interface V1PeopleImagesAdminDetailParams {
  /**
   * @format int32
   * @default 1
   */
  page?: number;
  /** @format int64 */
  personKey: number;
}

export interface V1PeopleVideosAdminDetailParams {
  /**
   * @format int32
   * @default 1
   */
  page?: number;
  /** @format int64 */
  personKey: number;
}

export interface V1SettingsAppDetailParams {
  /**
   * The version of the settings schema you want returned. If it is saved as a different version then some settings
   * might get lost in translation. This is a forward-looking feature. For now, just use "1".
   * @format int32
   * @example 1
   */
  schemaVersion?: number;
  /**
   * The user's key
   * @format int64
   * @example 20
   */
  userKey: number;
}

export interface V1SettingsAppSubscribedTopicsDetailParams {
  /** @default true */
  onlyCurrent?: boolean;
  /** @format int64 */
  userKey: number;
}

export interface V1SettingsDetailParams {
  /**
   * @format int32
   * @default 0
   */
  accessed?: number;
  /** @format int64 */
  userKey: number;
}

export type V1SettingsEliteCreatePayload = UserEliteSettingsRequest[];

export interface V1SettingsEliteDefaultUpdate2Params {
  /**
   * @format int64
   * @default 25
   */
  limit?: number;
  /**
   * @format int64
   * @default 0
   */
  userKeyStart?: number;
}

export interface V1SettingsEliteFollowedOrgsDetailParams {
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   * @default 1
   */
  page?: number;
  /** @format int64 */
  userKey: number;
}

export interface V1SettingsEliteFollowedPeopleDetailParams {
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   * @default 1
   */
  page?: number;
  /** @format int64 */
  userKey: number;
}

export type V1SettingsNewslettersUpdatePayload = UserNewsletterListRequest[];

export interface V1SitesImportNewsletterSubscribersCreateParams {
  /** @default true */
  confirm?: boolean;
  /** @format int32 */
  siteKey: number;
}

export interface V1SitesImportNewsletterSubscribersCreatePayload {
  /** @format binary */
  csvFile?: File;
}

export interface V1StripeCustomersUpdateAddressListParams {
  /** @format int32 */
  countNum?: number;
  objId?: string;
}

export type V1StripeCustomersUpdateAddressesCreatePayload = string[];

export type V1SubscribersNewslettersUpdatePayload = UserNewsletterListRequest[];

export interface V1UsersPianoAccessListParams {
  /** @default false */
  resync?: boolean;
}

export interface V1UsersSearchDetailParams {
  /**
   * If true, then it queries the database. If false, it returns the OpenSearch data.
   * @default false
   */
  forceRebuild?: boolean;
  /**
   * The user's key
   * @format int64
   */
  userKey: number;
}

export interface V1UsersSearchListParams {
  /**
   * @format int32
   * @min 1
   * @max 50
   * @default 25
   */
  limit?: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   * @default 1
   */
  page?: number;
  q?: string;
}

export interface V1UsersSearchRebuildCreateParams {
  /**
   * @format int32
   * @default 500
   */
  batchSize?: number;
  /** @default false */
  resetIndex?: boolean;
}

export interface V1UsersSyncToSailthruCreateParams {
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  batchLimit?: number;
  /**
   * @format int32
   * @min 1
   * @max 500
   */
  batchSize?: number;
  /**
   * @format int64
   * @min 1
   */
  startBeforeUserKey?: number;
}

export interface V1UsersUpdateAvatarUpdatePayload {
  /** @format binary */
  file?: File;
}

export interface V1UsersUsernameAvailabilityListParams {
  username: string;
}

export interface V1VideosAdminListParams {
  /**
   * @format int32
   * @default 1
   */
  page?: number;
}

export type V1VideosRenovateUpdatePayload = RenovateVideoRequest[];

export type V1VideosUpdatePayload = RenovateVideoRequest[];

export interface VideoResponse {
  category?: Int32StringKeyValuePair;
  /** @format int32 */
  date: number;
  description?: string | null;
  isFeatured: boolean;
  /** @format int64 */
  key: number;
  /** @format int64 */
  personKey?: number | null;
  personSport?: PersonSportBase;
  sourceUrl?: string;
  thumbnail?: string | null;
  title?: string | null;
}

export interface VideoResponseListResourceResponseMessage {
  message?: string;
  resource?: VideoResponse[];
  /** @format int32 */
  statusCode: number;
}

export enum Visibility {
  Private = 'Private',
  Friends = 'Friends',
  Athletes = 'Athletes',
  Public = 'Public',
  Unknown = 'Unknown',
}

export interface XfUserGroupResponse {
  /** @format int32 */
  key: number;
  name: string;
}

export type QueryParamsType = Record<string | number, any>;

export type RequestParams = Omit<
  AxiosRequestConfig,
  'data' | 'method' | 'params' | 'url' | 'responseType'
>;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

/**
 * No description
 *
 * @tags Coupon
 * @name V1CouponsValidateCreate
 * @request POST:/users/v1/coupons/validate
 */
export const v1CouponsValidateCreate = (
  client: AxiosInstance,
  data: CouponValidateRequest,
  params: RequestParams = {},
) =>
  client
    .request<ResponseMessage>({
      url: `/users/v1/coupons/validate`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Image
 * @name V1DownloadExternalCreate
 * @summary  (Roles: developer)
 * @request POST:/users/v1/download-external
 * @secure
 */
export const v1DownloadExternalCreate = (
  client: AxiosInstance,
  data: DownloadExternalAssetsReq,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/users/v1/download-external`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Image
 * @name V1ImagesAdminDetail
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/users/v1/images/{key}/admin
 * @secure
 */
export const v1ImagesAdminDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<ImageAdminResponse>({
      url: `/users/v1/images/${key}/admin`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Image
 * @name V1ImagesAdminList
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/users/v1/images/admin
 * @secure
 */
export const v1ImagesAdminList = (
  client: AxiosInstance,
  query: V1ImagesAdminListParams,
  params: RequestParams = {},
) =>
  client
    .request<ImageAdminResponse[]>({
      url: `/users/v1/images/admin`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Image
 * @name V1ImagesDelete
 * @summary Delete an image
 * @request DELETE:/users/v1/images/{key}
 * @secure
 */
export const v1ImagesDelete = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/users/v1/images/${key}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Image
 * @name V1ImagesRenovateSingleUpdate
 * @summary Single renovation request to make swagger work
 * @request PUT:/users/v1/images/renovate-single
 * @secure
 */
export const v1ImagesRenovateSingleUpdate = (
  client: AxiosInstance,
  data: V1ImagesRenovateSingleUpdatePayload,
  params: RequestParams = {},
) =>
  client
    .request<ImageAdminResponseListResourceResponseMessage>({
      url: `/users/v1/images/renovate-single`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Image
 * @name V1ImagesRenovateUpdate
 * @summary Creates or Updates a list of images
 * @request PUT:/users/v1/images/renovate
 * @secure
 */
export const v1ImagesRenovateUpdate = (
  client: AxiosInstance,
  data: V1ImagesRenovateUpdatePayload,
  params: RequestParams = {},
) =>
  client
    .request<ImageAdminResponseListResourceResponseMessage>({
      url: `/users/v1/images/renovate`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Newsletter
 * @name V1NewslettersList
 * @summary Get a list of newsletter
 * @request GET:/users/v1/newsletters
 */
export const v1NewslettersList = (
  client: AxiosInstance,
  params: RequestParams = {},
) =>
  client
    .request<NewsletterListResponsePagedData>({
      url: `/users/v1/newsletters`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Image
 * @name V1OrganizationsImagesAdminDetail
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/users/v1/organizations/{organizationKey}/images/admin
 * @secure
 */
export const v1OrganizationsImagesAdminDetail = (
  client: AxiosInstance,
  { organizationKey, ...query }: V1OrganizationsImagesAdminDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<ImageAdminResponse[]>({
      url: `/users/v1/organizations/${organizationKey}/images/admin`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Image
 * @name V1PeopleImagesAdminDetail
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/users/v1/people/{personKey}/images/admin
 * @secure
 */
export const v1PeopleImagesAdminDetail = (
  client: AxiosInstance,
  { personKey, ...query }: V1PeopleImagesAdminDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<ImageAdminResponse[]>({
      url: `/users/v1/people/${personKey}/images/admin`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Video
 * @name V1PeopleVideosAdminDetail
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/users/v1/people/{personKey}/videos/admin
 * @secure
 */
export const v1PeopleVideosAdminDetail = (
  client: AxiosInstance,
  { personKey, ...query }: V1PeopleVideosAdminDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<VideoResponse[]>({
      url: `/users/v1/people/${personKey}/videos/admin`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Settings
 * @name V1SettingsAppDelete
 * @summary  (Roles: registered user)
 * @request DELETE:/users/v1/settings/{userKey}/app
 * @secure
 */
export const v1SettingsAppDelete = (
  client: AxiosInstance,
  userKey: number,
  params: RequestParams = {},
) =>
  client
    .request<ResponseMessage>({
      url: `/users/v1/settings/${userKey}/app`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Settings
 * @name V1SettingsAppDetail
 * @summary Retrieves the user's saved Media/Fan App settings if they exist otherwise 404.
 * @request GET:/users/v1/settings/{userKey}/app
 * @secure
 */
export const v1SettingsAppDetail = (
  client: AxiosInstance,
  { userKey, ...query }: V1SettingsAppDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<UserAppSettingsResponse>({
      url: `/users/v1/settings/${userKey}/app`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Settings
 * @name V1SettingsAppSubscribedTopicsDetail
 * @summary  (Roles: developer)
 * @request GET:/users/v1/settings/{userKey}/app/subscribed-topics
 * @secure
 */
export const v1SettingsAppSubscribedTopicsDetail = (
  client: AxiosInstance,
  { userKey, ...query }: V1SettingsAppSubscribedTopicsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<SubscribedTopicsResponse[]>({
      url: `/users/v1/settings/${userKey}/app/subscribed-topics`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Settings
 * @name V1SettingsAppUpdate
 * @summary  (Roles: registered user)
 * @request PUT:/users/v1/settings/{userKey}/app
 * @secure
 */
export const v1SettingsAppUpdate = (
  client: AxiosInstance,
  userKey: number,
  data: UserAppSettings,
  params: RequestParams = {},
) =>
  client
    .request<UserAppSettingsResponse>({
      url: `/users/v1/settings/${userKey}/app`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Settings
 * @name V1SettingsDetail
 * @summary Get a summary for userSettings
 * @request GET:/users/v1/settings/{userKey}
 * @secure
 */
export const v1SettingsDetail = (
  client: AxiosInstance,
  { userKey, ...query }: V1SettingsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<MemberSettingResponse>({
      url: `/users/v1/settings/${userKey}`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Settings
 * @name V1SettingsEliteCreate
 * @summary Sets the Elite Settings value for the target User. All current information is overwritten.
 * @request POST:/users/v1/settings/{userKey}/elite
 * @secure
 */
export const v1SettingsEliteCreate = (
  client: AxiosInstance,
  userKey: number,
  data: V1SettingsEliteCreatePayload,
  params: RequestParams = {},
) =>
  client
    .request<UserEliteSettingsResponsePagedData>({
      url: `/users/v1/settings/${userKey}/elite`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Settings
 * @name V1SettingsEliteDefaultUpdate
 * @summary  (Roles: developer, rdb5)
 * @request PUT:/users/v1/settings/{userKey}/elite/default
 * @secure
 */
export const v1SettingsEliteDefaultUpdate = (
  client: AxiosInstance,
  userKey: number,
  params: RequestParams = {},
) =>
  client
    .request<string>({
      url: `/users/v1/settings/${userKey}/elite/default`,
      method: 'PUT',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Settings
 * @name V1SettingsEliteDefaultUpdate2
 * @summary  (Roles: developer, rdb5)
 * @request PUT:/users/v1/settings/elite/default
 * @originalName v1SettingsEliteDefaultUpdate
 * @duplicate
 * @secure
 */
export const v1SettingsEliteDefaultUpdate2 = (
  client: AxiosInstance,
  query: V1SettingsEliteDefaultUpdate2Params,
  params: RequestParams = {},
) =>
  client
    .request<number[]>({
      url: `/users/v1/settings/elite/default`,
      method: 'PUT',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Settings
 * @name V1SettingsEliteDetail
 * @summary Retrieves all Elite Settings values for the target User.
 * @request GET:/users/v1/settings/{userKey}/elite
 * @secure
 */
export const v1SettingsEliteDetail = (
  client: AxiosInstance,
  userKey: number,
  params: RequestParams = {},
) =>
  client
    .request<UserEliteSettingsResponsePagedData>({
      url: `/users/v1/settings/${userKey}/elite`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Settings
 * @name V1SettingsEliteDetail2
 * @summary Retrieves Elite Settings values for the target User and Person.
 * @request GET:/users/v1/settings/{userKey}/elite/{personKey}
 * @originalName v1SettingsEliteDetail
 * @duplicate
 * @secure
 */
export const v1SettingsEliteDetail2 = (
  client: AxiosInstance,
  userKey: number,
  personKey: number,
  params: RequestParams = {},
) =>
  client
    .request<UserEliteSettingsResponse>({
      url: `/users/v1/settings/${userKey}/elite/${personKey}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Settings
 * @name V1SettingsEliteFollowedOrgsDelete
 * @summary  (Roles: registered user)
 * @request DELETE:/users/v1/settings/{userKey}/elite-followed-orgs/{uofKey}
 * @secure
 */
export const v1SettingsEliteFollowedOrgsDelete = (
  client: AxiosInstance,
  userKey: number,
  uofKey: number,
  params: RequestParams = {},
) =>
  client
    .request<boolean>({
      url: `/users/v1/settings/${userKey}/elite-followed-orgs/${uofKey}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Settings
 * @name V1SettingsEliteFollowedOrgsDetail
 * @summary  (Roles: registered user)
 * @request GET:/users/v1/settings/{userKey}/elite-followed-orgs
 * @secure
 */
export const v1SettingsEliteFollowedOrgsDetail = (
  client: AxiosInstance,
  { userKey, ...query }: V1SettingsEliteFollowedOrgsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<UserFollowedOrgResPagedData>({
      url: `/users/v1/settings/${userKey}/elite-followed-orgs`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Settings
 * @name V1SettingsEliteFollowedOrgsDetail2
 * @summary  (Roles: registered user)
 * @request GET:/users/v1/settings/{userKey}/elite-followed-orgs/{uofKey}
 * @originalName v1SettingsEliteFollowedOrgsDetail
 * @duplicate
 * @secure
 */
export const v1SettingsEliteFollowedOrgsDetail2 = (
  client: AxiosInstance,
  userKey: number,
  uofKey: number,
  params: RequestParams = {},
) =>
  client
    .request<UserFollowedOrgRes>({
      url: `/users/v1/settings/${userKey}/elite-followed-orgs/${uofKey}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Settings
 * @name V1SettingsEliteFollowedOrgsUpdate
 * @summary  (Roles: registered user)
 * @request PUT:/users/v1/settings/{userKey}/elite-followed-orgs
 * @secure
 */
export const v1SettingsEliteFollowedOrgsUpdate = (
  client: AxiosInstance,
  userKey: number,
  data: RenovateUserFollowedOrgReq,
  params: RequestParams = {},
) =>
  client
    .request<UserFollowedOrgRes>({
      url: `/users/v1/settings/${userKey}/elite-followed-orgs`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Settings
 * @name V1SettingsEliteFollowedPeopleDelete
 * @summary  (Roles: registered user)
 * @request DELETE:/users/v1/settings/{userKey}/elite-followed-people/{upfKey}
 * @secure
 */
export const v1SettingsEliteFollowedPeopleDelete = (
  client: AxiosInstance,
  userKey: number,
  upfKey: number,
  params: RequestParams = {},
) =>
  client
    .request<boolean>({
      url: `/users/v1/settings/${userKey}/elite-followed-people/${upfKey}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Settings
 * @name V1SettingsEliteFollowedPeopleDetail
 * @summary  (Roles: registered user)
 * @request GET:/users/v1/settings/{userKey}/elite-followed-people
 * @secure
 */
export const v1SettingsEliteFollowedPeopleDetail = (
  client: AxiosInstance,
  { userKey, ...query }: V1SettingsEliteFollowedPeopleDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<UserFollowedPerResPagedData>({
      url: `/users/v1/settings/${userKey}/elite-followed-people`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Settings
 * @name V1SettingsEliteFollowedPeopleDetail2
 * @summary  (Roles: registered user)
 * @request GET:/users/v1/settings/{userKey}/elite-followed-people/{upfKey}
 * @originalName v1SettingsEliteFollowedPeopleDetail
 * @duplicate
 * @secure
 */
export const v1SettingsEliteFollowedPeopleDetail2 = (
  client: AxiosInstance,
  userKey: number,
  upfKey: number,
  params: RequestParams = {},
) =>
  client
    .request<UserFollowedPerRes>({
      url: `/users/v1/settings/${userKey}/elite-followed-people/${upfKey}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Settings
 * @name V1SettingsEliteFollowedPeopleUpdate
 * @summary  (Roles: registered user)
 * @request PUT:/users/v1/settings/{userKey}/elite-followed-people
 * @secure
 */
export const v1SettingsEliteFollowedPeopleUpdate = (
  client: AxiosInstance,
  userKey: number,
  data: RenovateUserFollowedPerReq,
  params: RequestParams = {},
) =>
  client
    .request<UserFollowedPerRes>({
      url: `/users/v1/settings/${userKey}/elite-followed-people`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Settings
 * @name V1SettingsEliteProfileSettingsDetail
 * @summary Gets Elite Player Profile Settings value for the User and Person combination.
 * @request GET:/users/v1/settings/{userKey}/elite/{personKey}/profile-settings
 * @secure
 */
export const v1SettingsEliteProfileSettingsDetail = (
  client: AxiosInstance,
  userKey: number,
  personKey: number,
  params: RequestParams = {},
) =>
  client
    .request<PersonEliteSettingsResponse>({
      url: `/users/v1/settings/${userKey}/elite/${personKey}/profile-settings`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Settings
 * @name V1SettingsEliteProfileSettingsUpdate
 * @summary Adds or Updates an Elite Player Profile Settings value for the User and Person combination.
 * @request PUT:/users/v1/settings/{userKey}/elite/{personKey}/profile-settings
 * @secure
 */
export const v1SettingsEliteProfileSettingsUpdate = (
  client: AxiosInstance,
  userKey: number,
  personKey: number,
  data: PersonSettingsRequest,
  params: RequestParams = {},
) =>
  client
    .request<PersonEliteSettingsResponse>({
      url: `/users/v1/settings/${userKey}/elite/${personKey}/profile-settings`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Settings
 * @name V1SettingsEliteUpdate
 * @summary Adds or Updates an Elite Settings value for the User and Person combination.
 * @request PUT:/users/v1/settings/{userKey}/elite
 * @secure
 */
export const v1SettingsEliteUpdate = (
  client: AxiosInstance,
  userKey: number,
  data: UserEliteSettingsRequest,
  params: RequestParams = {},
) =>
  client
    .request<UserEliteSettingsResponse>({
      url: `/users/v1/settings/${userKey}/elite`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Settings
 * @name V1SettingsNewslettersDetail
 * @summary Get a summary for all news letters for a user
 * @request GET:/users/v1/settings/{userKey}/newsletters
 * @secure
 */
export const v1SettingsNewslettersDetail = (
  client: AxiosInstance,
  userKey: number,
  params: RequestParams = {},
) =>
  client
    .request<UserNewsletterListResponsePagedData>({
      url: `/users/v1/settings/${userKey}/newsletters`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Settings
 * @name V1SettingsNewslettersDetail2
 * @summary Get a summary for a newsletter for a user
 * @request GET:/users/v1/settings/{userKey}/newsletters/{newsletterKey}
 * @originalName v1SettingsNewslettersDetail
 * @duplicate
 * @secure
 */
export const v1SettingsNewslettersDetail2 = (
  client: AxiosInstance,
  userKey: number,
  newsletterKey: number,
  params: RequestParams = {},
) =>
  client
    .request<UserNewsletterListResponse>({
      url: `/users/v1/settings/${userKey}/newsletters/${newsletterKey}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Settings
 * @name V1SettingsNewslettersUpdate
 * @summary Update newsletters for a user
 * @request PUT:/users/v1/settings/{userKey}/newsletters
 * @secure
 */
export const v1SettingsNewslettersUpdate = (
  client: AxiosInstance,
  userKey: number,
  data: V1SettingsNewslettersUpdatePayload,
  params: RequestParams = {},
) =>
  client
    .request<UserNewsletterListResponsePagedData>({
      url: `/users/v1/settings/${userKey}/newsletters`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Settings
 * @name V1SettingsNewslettersUpdate2
 * @summary Update newsletters for a user
 * @request PUT:/users/v1/settings/{userKey}/newsletters/{newsletterKey}
 * @originalName v1SettingsNewslettersUpdate
 * @duplicate
 * @secure
 */
export const v1SettingsNewslettersUpdate2 = (
  client: AxiosInstance,
  userKey: number,
  newsletterKey: number,
  data: UserSingleNewsletterListRequest,
  params: RequestParams = {},
) =>
  client
    .request<UserNewsletterListResponsePagedData>({
      url: `/users/v1/settings/${userKey}/newsletters/${newsletterKey}`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Newsletter
 * @name V1SitesImportNewsletterSubscribersCreate
 * @summary  (Roles: developer)
 * @request POST:/users/v1/sites/{siteKey}/import-newsletter-subscribers
 * @secure
 */
export const v1SitesImportNewsletterSubscribersCreate = (
  client: AxiosInstance,
  { siteKey, ...query }: V1SitesImportNewsletterSubscribersCreateParams,
  data: V1SitesImportNewsletterSubscribersCreatePayload,
  params: RequestParams = {},
) =>
  client
    .request<ResponseMessage>({
      url: `/users/v1/sites/${siteKey}/import-newsletter-subscribers`,
      method: 'POST',
      params: query,
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Subscription
 * @name V1SitesSubConfigsDetail
 * @request GET:/users/v1/sites/{siteKey}/sub-configs
 */
export const v1SitesSubConfigsDetail = (
  client: AxiosInstance,
  siteKey: number,
  params: RequestParams = {},
) =>
  client
    .request<SiteSubConfigRes>({
      url: `/users/v1/sites/${siteKey}/sub-configs`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Newsletter
 * @name V1SitesSubscribeToNewsletterCreate
 * @request POST:/users/v1/sites/{siteKey}/subscribe-to-newsletter
 */
export const v1SitesSubscribeToNewsletterCreate = (
  client: AxiosInstance,
  siteKey: number,
  data: NewsletterSubscribeRequest,
  params: RequestParams = {},
) =>
  client
    .request<ResponseMessage>({
      url: `/users/v1/sites/${siteKey}/subscribe-to-newsletter`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Stripe
 * @name V1StripeCustomersUpdateAddressDetail
 * @summary Updates payment methods billing address
 * @request GET:/users/v1/stripe/customers/{customerId}/update-address
 * @secure
 */
export const v1StripeCustomersUpdateAddressDetail = (
  client: AxiosInstance,
  customerId: string,
  params: RequestParams = {},
) =>
  client
    .request<string>({
      url: `/users/v1/stripe/customers/${customerId}/update-address`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Stripe
 * @name V1StripeCustomersUpdateAddressesCreate
 * @summary  (Roles: developer)
 * @request POST:/users/v1/stripe/customers/update-addresses
 * @secure
 */
export const v1StripeCustomersUpdateAddressesCreate = (
  client: AxiosInstance,
  data: V1StripeCustomersUpdateAddressesCreatePayload,
  params: RequestParams = {},
) =>
  client
    .request<string>({
      url: `/users/v1/stripe/customers/update-addresses`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Stripe
 * @name V1StripeCustomersUpdateAddressList
 * @summary Updates payment methods billing address
 * @request GET:/users/v1/stripe/customers/update-address
 * @secure
 */
export const v1StripeCustomersUpdateAddressList = (
  client: AxiosInstance,
  query: V1StripeCustomersUpdateAddressListParams,
  params: RequestParams = {},
) =>
  client
    .request<string>({
      url: `/users/v1/stripe/customers/update-address`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Subscription
 * @name V1SubConfigsList
 * @summary  (Roles: developer, rdb5)
 * @request GET:/users/v1/sub-configs
 * @secure
 */
export const v1SubConfigsList = (
  client: AxiosInstance,
  params: RequestParams = {},
) =>
  client
    .request<AdminSiteSubConfigResPagedData>({
      url: `/users/v1/sub-configs`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Subscription
 * @name V1SubConfigsRenovateUpdate
 * @summary  (Roles: developer, rdb5)
 * @request PUT:/users/v1/sub-configs/renovate
 * @secure
 */
export const v1SubConfigsRenovateUpdate = (
  client: AxiosInstance,
  data: RenSiteSubConfigReq,
  params: RequestParams = {},
) =>
  client
    .request<AdminSiteSubConfigRes>({
      url: `/users/v1/sub-configs/renovate`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Newsletter
 * @name V1SubscribersConfirmUpdate
 * @summary confirm email
 * @request PUT:/users/v1/subscribers/{idAndHash}/confirm
 */
export const v1SubscribersConfirmUpdate = (
  client: AxiosInstance,
  idAndHash: string,
  params: RequestParams = {},
) =>
  client
    .request<ResponseMessage>({
      url: `/users/v1/subscribers/${idAndHash}/confirm`,
      method: 'PUT',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Newsletter
 * @name V1SubscribersNewslettersDetail
 * @summary Get a list of newsletter for a subscriber
 * @request GET:/users/v1/subscribers/{idAndHash}/newsletters
 */
export const v1SubscribersNewslettersDetail = (
  client: AxiosInstance,
  idAndHash: string,
  params: RequestParams = {},
) =>
  client
    .request<UserNewsletterListResponsePagedData>({
      url: `/users/v1/subscribers/${idAndHash}/newsletters`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Newsletter
 * @name V1SubscribersNewslettersUpdate
 * @summary update user list
 * @request PUT:/users/v1/subscribers/{idAndHash}/newsletters
 */
export const v1SubscribersNewslettersUpdate = (
  client: AxiosInstance,
  idAndHash: string,
  data: V1SubscribersNewslettersUpdatePayload,
  params: RequestParams = {},
) =>
  client
    .request<ResponseMessage>({
      url: `/users/v1/subscribers/${idAndHash}/newsletters`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Newsletter
 * @name V1SubscribersUnsubscribeUpdate
 * @summary Unsubscribe all list
 * @request PUT:/users/v1/subscribers/{idAndHash}/unsubscribe
 */
export const v1SubscribersUnsubscribeUpdate = (
  client: AxiosInstance,
  idAndHash: string,
  params: RequestParams = {},
) =>
  client
    .request<ResponseMessage>({
      url: `/users/v1/subscribers/${idAndHash}/unsubscribe`,
      method: 'PUT',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Image
 * @name V1SyncImagesToOffloadCreate
 * @summary  (Roles: developer)
 * @request POST:/users/v1/sync-images-to-offload
 * @secure
 */
export const v1SyncImagesToOffloadCreate = (
  client: AxiosInstance,
  data: SyncImagesToOffloadReq,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/users/v1/sync-images-to-offload`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Image
 * @name V1SyncOffloadsToImagesCreate
 * @summary  (Roles: developer)
 * @request POST:/users/v1/sync-offloads-to-images
 * @secure
 */
export const v1SyncOffloadsToImagesCreate = (
  client: AxiosInstance,
  data: SyncOffloadsToImagesReq,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/users/v1/sync-offloads-to-images`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Image
 * @name V1SyncOrphanedAttachmentsCreate
 * @summary  (Roles: developer)
 * @request POST:/users/v1/sync-orphaned-attachments
 * @secure
 */
export const v1SyncOrphanedAttachmentsCreate = (
  client: AxiosInstance,
  data: SyncOrphanedAttachmentsRequest,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/users/v1/sync-orphaned-attachments`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Image
 * @name V1SyncUnknownAssetsCreate
 * @summary  (Roles: developer)
 * @request POST:/users/v1/sync-unknown-assets
 * @secure
 */
export const v1SyncUnknownAssetsCreate = (
  client: AxiosInstance,
  data: SyncUnknownAssetsRequest,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/users/v1/sync-unknown-assets`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags User
 * @name V1UsersCreate
 * @summary Create a new user by sending over needed data
receiving the JWT payload
 * @request POST:/users/v1/users
 */
export const v1UsersCreate = (
  client: AxiosInstance,
  data: CreateUserRequest,
  params: RequestParams = {},
) =>
  client
    .request<UserJwtResponse>({
      url: `/users/v1/users`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags User
 * @name V1UsersLoginAsCreate
 * @summary  (Roles: developer, administrator, cs_admin, elite_admin)
 * @request POST:/users/v1/users/login-as
 * @secure
 */
export const v1UsersLoginAsCreate = (
  client: AxiosInstance,
  data: LoginAsRequest,
  params: RequestParams = {},
) =>
  client
    .request<UserJwtResponse>({
      url: `/users/v1/users/login-as`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags User
 * @name V1UsersLoginCreate
 * @summary Log in a user account or proceed as a guest by posting a request and
receiving the JWT payload
 * @request POST:/users/v1/users/login
 */
export const v1UsersLoginCreate = (
  client: AxiosInstance,
  data: LoginUserRequest,
  params: RequestParams = {},
) =>
  client
    .request<UserJwtResponse>({
      url: `/users/v1/users/login`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags User
 * @name V1UsersPianoAccessList
 * @summary Refresh a session as a user by sending a refresh token and
receiving the JWT payload
 * @request GET:/users/v1/users/piano-access
 */
export const v1UsersPianoAccessList = (
  client: AxiosInstance,
  query: V1UsersPianoAccessListParams,
  params: RequestParams = {},
) =>
  client
    .request<UserJwtResponse>({
      url: `/users/v1/users/piano-access`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags User
 * @name V1UsersRefreshCreate
 * @summary Refresh a session as a user by sending a refresh token and receiving a new JWT
 * @request POST:/users/v1/users/refresh
 */
export const v1UsersRefreshCreate = (
  client: AxiosInstance,
  data: RefreshRequest,
  params: RequestParams = {},
) =>
  client
    .request<UserJwtResponse>({
      url: `/users/v1/users/refresh`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags User
 * @name V1UsersSailthruUpdate
 * @summary  (Roles: registered user)
 * @request PUT:/users/v1/users/{userKey}/sailthru
 * @secure
 */
export const v1UsersSailthruUpdate = (
  client: AxiosInstance,
  userKey: number,
  data: UpdateSailThruForUserRequest,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/users/v1/users/${userKey}/sailthru`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags User
 * @name V1UsersSearchCreate
 * @summary Rebuilds the user's data in the search index
 * @request POST:/users/v1/users/{userKey}/search
 * @secure
 */
export const v1UsersSearchCreate = (
  client: AxiosInstance,
  userKey: number,
  params: RequestParams = {},
) =>
  client
    .request<UserSearchResponse>({
      url: `/users/v1/users/${userKey}/search`,
      method: 'POST',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags User
 * @name V1UsersSearchDetail
 * @summary Returns the user's search data. If `forceRebuild` is true, then it queries the database for the info. If false,
then it returns the data in OpenSearch.
 * @request GET:/users/v1/users/{userKey}/search
 * @secure
 */
export const v1UsersSearchDetail = (
  client: AxiosInstance,
  { userKey, ...query }: V1UsersSearchDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<UserSearchResponse>({
      url: `/users/v1/users/${userKey}/search`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags User
 * @name V1UsersSearchList
 * @summary  (Roles: developer, cs_admin)
 * @request GET:/users/v1/users/search
 * @secure
 */
export const v1UsersSearchList = (
  client: AxiosInstance,
  query: V1UsersSearchListParams,
  params: RequestParams = {},
) =>
  client
    .request<UserSearchResponsePagedData>({
      url: `/users/v1/users/search`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags User
 * @name V1UsersSearchRebuildCreate
 * @summary  (Roles: developer)
 * @request POST:/users/v1/users/search/rebuild
 * @secure
 */
export const v1UsersSearchRebuildCreate = (
  client: AxiosInstance,
  query: V1UsersSearchRebuildCreateParams,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/users/v1/users/search/rebuild`,
      method: 'POST',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags User
 * @name V1UsersSyncForumPermissionsCreate
 * @summary  (Roles: developer)
 * @request POST:/users/v1/users/{userKey}/sync-forum-permissions
 * @secure
 */
export const v1UsersSyncForumPermissionsCreate = (
  client: AxiosInstance,
  userKey: number,
  data: SyncUsersPermissionsApiRequest,
  params: RequestParams = {},
) =>
  client
    .request<SyncUserPermissionsFriendlyResponse>({
      url: `/users/v1/users/${userKey}/sync-forum-permissions`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags User
 * @name V1UsersSyncToSailthruCreate
 * @summary  (Roles: developer)
 * @request POST:/users/v1/users/sync-to-sailthru
 * @secure
 */
export const v1UsersSyncToSailthruCreate = (
  client: AxiosInstance,
  query: V1UsersSyncToSailthruCreateParams,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/users/v1/users/sync-to-sailthru`,
      method: 'POST',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags User
 * @name V1UsersSyncToSailthruStatusList
 * @summary  (Roles: developer)
 * @request GET:/users/v1/users/sync-to-sailthru/status
 * @secure
 */
export const v1UsersSyncToSailthruStatusList = (
  client: AxiosInstance,
  params: RequestParams = {},
) =>
  client
    .request<SyncUsersToSailThruStatusResponse>({
      url: `/users/v1/users/sync-to-sailthru/status`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags User
 * @name V1UsersUpdateAvatarUpdate
 * @summary  (Roles: registered user)
 * @request PUT:/users/v1/users/update-avatar
 * @secure
 */
export const v1UsersUpdateAvatarUpdate = (
  client: AxiosInstance,
  data: V1UsersUpdateAvatarUpdatePayload,
  params: RequestParams = {},
) =>
  client
    .request<UserAvatarUpdateResponse>({
      url: `/users/v1/users/update-avatar`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags User
 * @name V1UsersUpdateUsernameCreate
 * @summary  (Roles: registered user)
 * @request POST:/users/v1/users/update-username
 * @secure
 */
export const v1UsersUpdateUsernameCreate = (
  client: AxiosInstance,
  data: UserNameUpdateRequest,
  params: RequestParams = {},
) =>
  client
    .request<UpdateUserNameResponse>({
      url: `/users/v1/users/update-username`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags User
 * @name V1UsersUsernameAvailabilityList
 * @request GET:/users/v1/users/username-availability
 */
export const v1UsersUsernameAvailabilityList = (
  client: AxiosInstance,
  query: V1UsersUsernameAvailabilityListParams,
  params: RequestParams = {},
) =>
  client
    .request<CheckAvailabilityResponse>({
      url: `/users/v1/users/username-availability`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Video
 * @name V1VideosAdminList
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/users/v1/videos/admin
 * @secure
 */
export const v1VideosAdminList = (
  client: AxiosInstance,
  query: V1VideosAdminListParams,
  params: RequestParams = {},
) =>
  client
    .request<VideoResponse[]>({
      url: `/users/v1/videos/admin`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Video
 * @name V1VideosDelete
 * @summary Delete a video
 * @request DELETE:/users/v1/videos/{key}
 * @secure
 */
export const v1VideosDelete = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/users/v1/videos/${key}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Video
 * @name V1VideosRenovateUpdate
 * @summary Create or update a list of videos by a source urls
 * @request PUT:/users/v1/videos/renovate
 * @secure
 */
export const v1VideosRenovateUpdate = (
  client: AxiosInstance,
  data: V1VideosRenovateUpdatePayload,
  params: RequestParams = {},
) =>
  client
    .request<VideoResponseListResourceResponseMessage>({
      url: `/users/v1/videos/renovate`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Video
 * @name V1VideosUpdate
 * @summary Create or update a list of videos by a source urls
 * @request PUT:/users/v1/videos
 * @secure
 */
export const v1VideosUpdate = (
  client: AxiosInstance,
  data: V1VideosUpdatePayload,
  params: RequestParams = {},
) =>
  client
    .request<VideoResponseListResourceResponseMessage>({
      url: `/users/v1/videos`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);
