import { IUser } from '@on3/ui-lib/api/schema/custom-contracts';
import { useAuth } from '@on3/ui-lib/index';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect } from 'react';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Sailthru: any;
  }
}

interface ISailthruPageViewProps {
  user?: IUser;
}

export const SAILTHRU_CUSTOMER_ID_PROD = '95b5cce252eaf7a3ac67a2f7aa8c1a03';
export const SAILTHRU_CUSTOMER_ID_DEV = 'a0513d181b1a4ee28c940376fd3275ed';

const sailthruPageview = ({ user }: ISailthruPageViewProps) => {
  const showOverlay = `${!user?.has}`;
  const customerId =
    process.env.NEXT_PUBLIC_APP_ENV === 'production'
      ? SAILTHRU_CUSTOMER_ID_PROD
      : SAILTHRU_CUSTOMER_ID_DEV;

  window?.Sailthru?.init({
    customerId,
    overlayKeyValue: { showOverlay },
  });
};

export const Sailthru = () => {
  const { asPath: path } = useRouter();
  const { user } = useAuth();

  useEffect(() => {
    sailthruPageview({ user });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  return (
    <Script
      src="https://ak.sail-horizon.com/spm/spm.v1.min.js"
      strategy="beforeInteractive"
    />
  );
};
