import type { AxiosInstance, AxiosRequestConfig } from 'axios';

export interface ActiveGameStatusResponse {
  currentPeriod?: string | null;
  down?: string | null;
  possession?: string | null;
  timeRemaining?: string | null;
  yardline?: string | null;
  yardsRemaining?: string | null;
}

export interface AdminGameRes {
  /** @format int32 */
  awayOrgKey?: number | null;
  awayOrganization?: OrganizationResponse;
  /** @format int32 */
  awayScore?: number | null;
  /** @format int32 */
  awayScorePrediction?: number | null;
  city?: string | null;
  /** @format int32 */
  currentAwayTeamRank?: number | null;
  /** @format int32 */
  currentHomeTeamRank?: number | null;
  /** @format date */
  endDate?: string | null;
  /** @format time */
  endTimeCentral?: string | null;
  /** @format int64 */
  endTimeUtc?: number | null;
  gameBroadcast?: string | null;
  gameHash?: string | null;
  gameType?: GameType;
  /** @format int32 */
  homeOrgKey?: number | null;
  homeOrganization?: OrganizationResponse;
  /** @format int32 */
  homeScore?: number | null;
  /** @format int32 */
  homeScorePrediction?: number | null;
  imported?: GameOverride;
  /** @format int64 */
  key: number;
  /** @format int32 */
  lastUpdated?: number | null;
  location?: string | null;
  overrides?: GameOverride;
  seasonPhase?: SeasonPhaseResponse;
  /** @format int64 */
  seasonPhaseKey?: number | null;
  sport: SportResponseRecord;
  /** @format int32 */
  sportKey: number;
  /** @format date */
  startDate?: string | null;
  /** @format time */
  startTimeCentral?: string | null;
  startTimeTbd: boolean;
  /** @format int64 */
  startTimeUtc?: number | null;
  state?: string | null;
  status?: string | null;
  statusEnum: GameStatuses;
  ticketLink?: string | null;
  /** @format int32 */
  year: number;
}

export interface AdminGameResRelatedScheduleResponseRelatedPagedData {
  list?: AdminGameRes[];
  pagination?: Pagination;
  relatedModel?: RelatedScheduleResponse;
}

export interface Agency {
  /** @format int64 */
  key: number;
  /**
   * @minLength 0
   * @maxLength 50
   */
  name: string;
}

export interface AgentNilDeal {
  company?: string | null;
  /** @format int64 */
  key: number;
  name?: string;
  nilDeals?: NilDeal[];
}

export interface AgentPerson {
  agency?: Agency;
  /** @format int64 */
  agencyKey?: number | null;
  /** @format int64 */
  key: number;
  name?: string;
}

export interface AlternatePersonVerification {
  /** @format int32 */
  expiredCodeAttempts: number;
  /** @format int64 */
  key: number;
  person?: Person;
  /** @format int64 */
  personKey: number;
  /** @format int64 */
  unixTimeVerificationCodeExpiration: number;
  /** @format int64 */
  unixTimeVerified?: number | null;
  /** @format int64 */
  userKey: number;
  /** @minLength 1 */
  verificationCode: string;
  wpUser?: WpUser;
}

export enum AppTypes {
  Unknown = 'Unknown',
  Fan = 'Fan',
  Elite = 'Elite',
  Web = 'Web',
}

export interface AppleShowMeta {
  /** @format date-time */
  lastUpdated?: string | null;
  url?: string | null;
}

export interface ArticleNotificationLog {
  /** @maxLength 255 */
  body?: string | null;
  /** @format date-time */
  createdAt: string;
  error?: string | null;
  eventType: NotificationStatuses;
  /** @format int64 */
  key: number;
  post?: Post;
  /** @format int64 */
  postKey: number;
  recipients?: ArticleNotificationRecipients;
  /** @maxLength 255 */
  title?: string | null;
  topics?: string[] | null;
  /**
   * The key for the user that sent the notification. If null, then it was sent through WordPress.
   * @format int64
   */
  userKey?: number | null;
  wpUser?: WpUser;
}

export interface ArticleNotificationRecipients {
  all: boolean;
  orgKeys?: number[] | null;
  siteKeys?: number[] | null;
}

export interface Asset {
  /**
   * @minLength 0
   * @maxLength 255
   */
  altText?: string | null;
  /** Specifies the kind of asset and Asset object is. */
  assetClass: AssetClasses;
  assetMapping?: AssetMapping;
  /**
   * @minLength 0
   * @maxLength 255
   */
  authorText?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  caption?: string | null;
  category?: Category;
  /** @format int32 */
  date?: number | null;
  description?: string | null;
  /** @format int32 */
  duration: number;
  /**
   * @minLength 0
   * @maxLength 255
   */
  fileSystem?: string | null;
  /** Specifies options on Assets */
  flags: AssetFlags;
  /** @format int32 */
  height?: number | null;
  /** @format int64 */
  key: number;
  /**
   * @minLength 0
   * @maxLength 255
   */
  mimeType?: string | null;
  organizationAssets?: OrganizationAsset[];
  /**
   * @minLength 0
   * @maxLength 255
   */
  originalFilename?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  path?: string | null;
  personAssets?: PersonAsset[];
  /**
   * @minLength 0
   * @maxLength 512
   */
  thumbnail?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  title?: string | null;
  /**
   * @minLength 0
   * @maxLength 6
   */
  type?: string | null;
  /** @format int64 */
  userKey?: number | null;
  /** @format int32 */
  width?: number | null;
}

/** Specifies the kind of asset and Asset object is. */
export enum AssetClasses {
  Unknown = 'Unknown',
  Image = 'Image',
  Video = 'Video',
}

/** Specifies options on Assets */
export enum AssetFlags {
  None = 'None',
  Taggable = 'Taggable',
  UrlLink = 'UrlLink',
}

export interface AssetMapping {
  asset?: Asset;
  /** @format int64 */
  assetKey: number;
  attachment?: Attachment;
  /** @format int32 */
  flags: number;
  /** @format int64 */
  key: number;
  /** @format int64 */
  postId: number;
}

export interface AssetResponse {
  altText?: string | null;
  /** Specifies the kind of asset and Asset object is. */
  assetType: AssetClasses;
  caption?: string | null;
  category?: Category;
  description?: string | null;
  domain?: string | null;
  domainOverride?: string | null;
  /** @format int32 */
  duration: number;
  fileSystem?: string | null;
  /** @format int32 */
  height?: number | null;
  /** @format int64 */
  key: number;
  mimeType?: string | null;
  path?: string | null;
  source?: string | null;
  sourceOverride?: string | null;
  thumbnail?: string | null;
  title?: string | null;
  type?: string | null;
  /** @format int32 */
  width?: number | null;
}

export interface Attachment {
  assetMapping?: AssetMapping;
  author?: WpUser;
  /** @format int64 */
  authorKey: number;
  /** @minLength 1 */
  body: string;
  caption?: string;
  /**
   * @minLength 0
   * @maxLength 400
   */
  fullPath?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  guid?: string;
  /** @format int64 */
  key: number;
  metas?: PostMeta[];
  /**
   * @minLength 0
   * @maxLength 100
   */
  mimeType?: string;
  /** @format date-time */
  modifiedDate: string;
  /** @format date-time */
  modifiedDateGMT: string;
  notificationLogs?: ArticleNotificationLog[];
  offloadItem?: MediaLibraryItem;
  /** @format date-time */
  postDate: string;
  /** @format date-time */
  postDateGMT: string;
  postTerms?: PostTerm[];
  /**
   * @minLength 0
   * @maxLength 200
   */
  slug?: string | null;
  /**
   * One of the following:
   * <list type="bullet"><item><term>auto-draft</term></item><item><term>draft</term></item><item><term>future</term></item><item><term>inherit</term></item><item><term>pending</term></item><item><term>post_status</term></item><item><term>private</term></item><item><term>publish</term></item><item><term>trash</term></item></list>
   *
   * <b>Note:</b> Statuses can be added/removed based on plugins and plugin versions.
   * @minLength 0
   * @maxLength 20
   */
  status?: string | null;
  termRelationships?: WpTermRelationship[];
  /**
   * @minLength 0
   * @maxLength 255
   */
  title?: string | null;
  /**
   * One of the following:
   * <list type="bullet"><item><term>acf-field</term></item><item><term>acf-field-group</term></item><item><term>adstxt</term></item><item><term>app-adstxt</term></item><item><term>apto_sort</term></item><item><term>attachment</term></item><item><term>memberpresscoupon</term></item><item><term>memberpressgroup</term></item><item><term>memberpressproduct</term></item><item><term>mp-reminder</term></item><item><term>mt_pp</term></item><item><term>nav_menu_item</term></item><item><term>oembed_cache</term></item><item><term>page</term></item><item><term>post</term></item><item><term>revision</term></item><item><term>wp_block</term></item><item><term>wp_global_styles</term></item><item><term>wp_navigation</term></item></list>
   *
   * <b>Note:</b> Types can be added/removed based on plugins and plugin versions.
   * @minLength 0
   * @maxLength 20
   */
  type?: string | null;
}

export interface AttributeResponse {
  abbr?: string | null;
  /** @format int64 */
  key: number;
  name?: string | null;
}

export interface BaseCollective {
  /** @format int32 */
  baseValue: number;
  /** @format int64 */
  key: number;
  organizations?: BaseCollectiveOrganization[];
  sport?: Sport;
  /** @format int32 */
  sportKey: number;
  /** @format int32 */
  year: number;
}

export interface BaseCollectiveOrganization {
  baseCollective?: BaseCollective;
  /** @format int64 */
  baseCollectiveKey: number;
  /** @format int64 */
  key: number;
  /** @format double */
  minimumWage?: number | null;
  /** @format int32 */
  modifier: number;
  /** @format double */
  modifierValue: number;
  organization?: Organization;
  /** @format int32 */
  organizationKey: number;
  /**
   * @format double
   * @min 0
   * @max 100
   */
  recruitingPercent: number;
  stale: boolean;
  tiers?: CollectiveTier[];
}

export enum Category {
  Undetermined = 'Undetermined',
  Highlights = 'Highlights',
  Training = 'Training',
  Experts = 'Experts',
  Conversations = 'Conversations',
  Camp = 'Camp',
}

export enum CategoryTypes {
  Unknown = 'Unknown',
  Home = 'Home',
  Sport = 'Sport',
  Recruiting = 'Recruiting',
  Newsletter = 'Newsletter',
  HighSchool = 'HighSchool',
}

export interface City {
  country?: Country;
  /** @format int32 */
  countryKey?: number | null;
  /** @format int32 */
  key: number;
  /** @format double */
  latitude?: number | null;
  /** @format double */
  longitude?: number | null;
  /**
   * @minLength 0
   * @maxLength 45
   */
  name: string;
  /**
   * @minLength 0
   * @maxLength 8
   */
  postalCode: string;
  /**
   * @minLength 0
   * @maxLength 50
   */
  regionKey?: string | null;
  state?: State;
  /** @format int32 */
  stateKey?: number | null;
}

export interface CityResponseRecord {
  country?: CountryResponseRecord;
  /** @format int32 */
  key: number;
  name?: string;
  state?: StateResponseRecord;
}

export enum ClassYear {
  Unknown = 'Unknown',
  Freshman = 'Freshman',
  RedShirtFreshman = 'RedShirt_Freshman',
  Sophomore = 'Sophomore',
  RedShirtSophomore = 'RedShirt_Sophomore',
  Junior = 'Junior',
  RedShirtJunior = 'RedShirt_Junior',
  Senior = 'Senior',
  RedShirtSenior = 'RedShirt_Senior',
}

export enum ClassificationType {
  Unknown = 'Unknown',
  League = 'League',
  State = 'State',
  Conference = 'Conference',
  Division = 'Division',
  Association = 'Association',
  Region = 'Region',
  District = 'District',
  Class = 'Class',
  Metro = 'Metro',
  Section = 'Section',
}

export interface CoachVisited {
  /** @format int64 */
  coachingVisitKey: number;
  /** @format int64 */
  key: number;
  person?: Person;
  /** @format int64 */
  personKey: number;
  recruitmentInterestCoachVisit?: RecruitmentInterestCoachVisit;
}

export interface CollectiveGroup {
  /** @format double */
  annualGoalAmount?: number | null;
  /** @format double */
  confirmedRaisedAmount?: number | null;
  defaultAsset?: Asset;
  /** @format int64 */
  defaultAssetKey?: number | null;
  description?: string | null;
  founders?: CollectiveGroupFounder[];
  instagramHandle?: string | null;
  /** @format int64 */
  key: number;
  /** @format int32 */
  launchDate?: number | null;
  linkedInHandle?: string | null;
  mergedGroups?: CollectiveGroup[];
  mergedIntoGroup?: CollectiveGroup;
  /** @format int64 */
  mergedIntoGroupKey?: number | null;
  missionStatement?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  name: string;
  nilDeals?: NilDeal[];
  organization?: Organization;
  /** @format int32 */
  organizationKey: number;
  organizationType?: string | null;
  socialAsset?: Asset;
  /** @format int64 */
  socialAssetKey?: number | null;
  sports?: CollectiveGroupSport[];
  tikTokHandle?: string | null;
  twitterHandle?: string | null;
  websiteName?: string | null;
  websiteUrl?: string | null;
  youtubeHandle?: string | null;
}

export interface CollectiveGroupFounder {
  collectiveGroup?: CollectiveGroup;
  /** @format int64 */
  collectiveGroupKey: number;
  /**
   * @minLength 0
   * @maxLength 255
   */
  instagramHandle?: string | null;
  /** @format int64 */
  key: number;
  linkedInHandle?: string | null;
  /**
   * @minLength 0
   * @maxLength 100
   */
  name?: string | null;
  /** @format int32 */
  order?: number | null;
  person?: Person;
  /** @format int64 */
  personKey?: number | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  tikTokHandle?: string | null;
  /**
   * @minLength 0
   * @maxLength 45
   */
  title?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  twitterHandle?: string | null;
}

export interface CollectiveGroupSport {
  collectiveGroup?: CollectiveGroup;
  /** @format int64 */
  collectiveGroupKey: number;
  primary: boolean;
  sport?: Sport;
  /** @format int32 */
  sportKey: number;
}

export interface CollectiveTier {
  baseCollectiveOrganization?: BaseCollectiveOrganization;
  /** @format int64 */
  baseCollectiveOrganizationKey: number;
  drafts?: CollectiveTierValuationDraft[];
  /** @format int64 */
  key: number;
  /**
   * @minLength 0
   * @maxLength 50
   */
  name?: string;
  /** @format int32 */
  order: number;
  /**
   * @format double
   * @min 0
   * @max 100
   */
  percent: number;
  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  personLimit: number;
  stale: boolean;
  type: CollectiveTierTypes;
}

export enum CollectiveTierTypes {
  Roster = 'Roster',
  Recruiting = 'Recruiting',
}

export interface CollectiveTierValuation {
  /**
   * @format double
   * @min 0
   * @max 9999999999.99
   */
  actualValue?: number | null;
  collectiveTier?: CollectiveTier;
  /** @format int64 */
  collectiveTierKey: number;
  /** @format int32 */
  createdTimestamp: number;
  /** @format int64 */
  key: number;
  /** @format int32 */
  order: number;
  personSport?: PersonSport;
  /** @format int64 */
  personSportKey: number;
  personSportOrganization?: PersonSportOrganization;
  /** @format int64 */
  personSportOrganizationKey?: number | null;
  /**
   * @format double
   * @min 0
   * @max 9999999999.99
   */
  projectedValue: number;
  recruitmentInterest?: RecruitmentInterest;
  /** @format int64 */
  recruitmentInterestKey?: number | null;
  /** @format int32 */
  updatedTimestamp?: number | null;
}

export interface CollectiveTierValuationDraft {
  /**
   * @format double
   * @min 0
   * @max 9999999999.99
   */
  actualValue?: number | null;
  collectiveTier?: CollectiveTier;
  /** @format int64 */
  collectiveTierKey: number;
  /** @format int32 */
  createdTimestamp: number;
  /** @format int64 */
  key: number;
  /** @format int32 */
  order: number;
  personSport?: PersonSport;
  /** @format int64 */
  personSportKey: number;
  personSportOrganization?: PersonSportOrganization;
  /** @format int64 */
  personSportOrganizationKey?: number | null;
  /**
   * @format double
   * @min 0
   * @max 9999999999.99
   */
  projectedValue: number;
  recruitmentInterest?: RecruitmentInterest;
  /** @format int64 */
  recruitmentInterestKey?: number | null;
  stale: boolean;
  /** @format int32 */
  updatedTimestamp?: number | null;
}

export interface Conference {
  /**
   * @minLength 0
   * @maxLength 35
   */
  abbreviation?: string | null;
  asset?: Asset;
  /** @format int64 */
  assetKey?: number | null;
  isGroupOfFive: boolean;
  isPowerFive: boolean;
  /** @format int32 */
  key: number;
  leagueConferences?: LeagueConference[];
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
}

export interface ConferenceResponse {
  abbreviation?: string | null;
  asset?: KeyUrlBase;
  /** @format int64 */
  assetKey?: number | null;
  assetRes?: AssetResponse;
  isGroupOfFive: boolean;
  isPowerFive: boolean;
  /** @format int32 */
  key: number;
  name?: string;
}

export interface ContactInfo {
  email?: string | null;
  name?: string | null;
  phoneNumber?: string | null;
}

export interface ContactInfoPersonContactInfoMeta {
  value?: ContactInfo;
  verification?: Verification;
  visibility: Visibility;
}

export interface Country {
  /**
   * @minLength 0
   * @maxLength 4
   */
  abbreviation: string;
  /** @format int32 */
  key: number;
  /**
   * @minLength 0
   * @maxLength 50
   */
  name: string;
}

export interface CountryResponseRecord {
  abbreviation?: string;
  /** @format int32 */
  key: number;
  name?: string;
}

export interface CurrentPreviousAndNextGamesRes {
  current?: ScoreboardResponse;
  next?: ScoreboardResponse;
  previous?: ScoreboardResponse;
}

export enum DeviceClient {
  FanApp = 'FanApp',
  EliteApp = 'EliteApp',
}

export interface DeviceToken {
  /** @format int32 */
  buildNumber?: number | null;
  client?: DeviceClient;
  deviceType: DeviceType;
  /** @format int64 */
  key: number;
  /**
   * The last time the token was reported as active.
   * @format date-time
   */
  lastSeenAt?: string | null;
  /** @maxLength 255 */
  token?: string;
  wpUser?: WpUser;
  /** @format int64 */
  wpUserKey: number;
}

export enum DeviceType {
  Android = 'Android',
  Browser = 'Browser',
  IOS = 'iOS',
}

export interface Draft {
  /** @format date-time */
  dateOccurred?: string | null;
  /** @format int64 */
  key: number;
  league?: League;
  /** @format int64 */
  leagueKey: number;
  /**
   * @format int32
   * @min 0
   * @max 100
   */
  powerFiveEligible?: number | null;
  /** @format int32 */
  year: number;
}

export interface DraftPick {
  compensatory: boolean;
  draft?: Draft;
  /** @format int64 */
  draftKey: number;
  forfeited: boolean;
  /** @format int64 */
  key: number;
  notes?: string | null;
  organization?: Organization;
  /** @format int32 */
  organizationKey: number;
  /** @format int32 */
  overallPick?: number | null;
  recruitment?: Recruitment;
  /** @format int64 */
  recruitmentKey?: number | null;
  /** @format int32 */
  round: number;
  /** @format int32 */
  roundPick?: number | null;
  supplementary: boolean;
  throughOrganizationOne?: Organization;
  /** @format int32 */
  throughOrganizationOneKey?: number | null;
  throughOrganizationThree?: Organization;
  /** @format int32 */
  throughOrganizationThreeKey?: number | null;
  throughOrganizationTwo?: Organization;
  /** @format int32 */
  throughOrganizationTwoKey?: number | null;
  traded: boolean;
  tradingOrganization?: Organization;
  /** @format int32 */
  tradingOrganizationKey?: number | null;
}

export interface DraftSimpleResponse {
  collegeOrganization?: OrganizationResponse;
  compensatory: boolean;
  forfeited: boolean;
  industryComparison?: PlayerRatingAllResponse[] | null;
  /** @format int64 */
  key: number;
  organization?: OrganizationResponse;
  /** @format int32 */
  overallPick?: number | null;
  /** @format int32 */
  pick?: number | null;
  rosterRating?: RatingResponse;
  /** @format int32 */
  round: number;
  /** @format int32 */
  sportKey: number;
  supplementary: boolean;
  traded: boolean;
  /** @format int32 */
  year: number;
}

export interface EliteFeedItem {
  /** @format date-time */
  date: string;
  feedType: FeedItemTypes;
  /** @format int64 */
  key: number;
  meta?: string | null;
  personFeedItems?: PersonFeedItem[];
  sendNotification: boolean;
  status: FeedItemStatuses;
  /** @format int64 */
  typeKey: number;
  userFeedItems?: UserFeedItem[];
}

export interface Episode {
  asset?: Asset;
  /** @format int64 */
  assetKey?: number | null;
  description?: string | null;
  /** @format int32 */
  duration: number;
  /**
   * @minLength 0
   * @maxLength 512
   */
  filePath: string;
  /** @format int64 */
  key: number;
  meta?: EpisodeMeta;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
  /** @format date-time */
  publishDate: string;
  show?: Show;
  /** @format int64 */
  showKey: number;
  type: EpisodeTypes;
  /**
   * @minLength 0
   * @maxLength 255
   */
  uid: string;
}

export interface EpisodeMeta {
  imageSrc?: string | null;
  /** @format date-time */
  lastUpdated?: string | null;
  sourceJson?: string | null;
}

export enum EpisodeTypes {
  Unknown = 'Unknown',
  Podcast = 'Podcast',
  Video = 'Video',
}

export interface Event {
  city?: City;
  /** @format int32 */
  cityKey?: number | null;
  classification: EventClassification;
  /**
   * @minLength 0
   * @maxLength 255
   */
  description?: string | null;
  /** @format date-time */
  endDate?: string | null;
  /** @format int64 */
  key: number;
  measurements?: Measurement[];
  /**
   * @minLength 0
   * @maxLength 45
   */
  name: string;
  personSportEventResults?: PersonSportEventResult[];
  sport?: Sport;
  /** @format int32 */
  sportKey?: number | null;
  /** @format date-time */
  startDate?: string | null;
}

export enum EventClassification {
  None = 'None',
  TrackAndField = 'TrackAndField',
  Combine = 'Combine',
}

export interface ExposureBreakdownDto {
  heismanFinalist?: boolean | null;
  heismanWinner?: boolean | null;
  /** @format int32 */
  heismanYear?: number | null;
  /** @format int32 */
  impactRank?: number | null;
  /** @format double */
  impactRating?: number | null;
  /** @format int32 */
  impactYear?: number | null;
  /** @format int32 */
  orgKey?: number | null;
  orgType?: OrganizationType;
  /** @format int32 */
  positionKey?: number | null;
  /** @format int32 */
  sportKey?: number | null;
}

export enum ExternalStore {
  Apple = 'Apple',
  Google = 'Google',
  Amazon = 'Amazon',
}

export enum FeedItemStatuses {
  Unknown = 'Unknown',
  Stale = 'Stale',
  GeneratingMeta = 'GeneratingMeta',
  GeneratingPersonFeeds = 'GeneratingPersonFeeds',
  GeneratingOrganizationFeeds = 'GeneratingOrganizationFeeds',
  GeneratingUserFeeds = 'GeneratingUserFeeds',
  FlushingCache = 'FlushingCache',
  QueueNotifications = 'QueueNotifications',
  Updated = 'Updated',
  Error = 'Error',
}

export enum FeedItemTypes {
  Unknown = 'Unknown',
  Article = 'Article',
  NilValuation = 'NilValuation',
  NilDeal = 'NilDeal',
  IndustryRanking = 'IndustryRanking',
  GraphicEdit = 'GraphicEdit',
  ServiceRanking = 'ServiceRanking',
  Bolt = 'Bolt',
}

export interface Game {
  /** @format int32 */
  awayOrgKey?: number | null;
  awayOrganization?: Organization;
  /** @format int32 */
  awayScore?: number | null;
  /** @format int32 */
  awayScorePrediction?: number | null;
  /** @format double */
  awayWinProbability?: number | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  city?: string | null;
  /** @format int32 */
  currentAwayTeamRank?: number | null;
  /** @format int32 */
  currentHomeTeamRank?: number | null;
  /** @format int64 */
  endTimeUtc?: number | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  gameBroadcast?: string | null;
  /**
   * @minLength 0
   * @maxLength 64
   */
  gameHash?: string | null;
  gameOdds?: GameOdds;
  gameStat?: GameStat;
  gameType?: GameType;
  /** @format int32 */
  highestRanking?: number | null;
  /** @format int32 */
  homeOrgKey?: number | null;
  homeOrganization?: Organization;
  /** @format int32 */
  homeScore?: number | null;
  /** @format int32 */
  homeScorePrediction?: number | null;
  /** @format double */
  homeWinProbability?: number | null;
  /** @format int64 */
  key: number;
  /** @format int32 */
  lastUpdated?: number | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  location?: string | null;
  overrides?: GameOverrideDetails;
  seasonPhase?: SeasonPhase;
  /** @format int64 */
  seasonPhaseKey?: number | null;
  sport?: Sport;
  /** @format int32 */
  sportKey: number;
  startTimeTbd: boolean;
  /** @format int64 */
  startTimeUtc?: number | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  state?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  status?: string | null;
  statusEnum: GameStatuses;
  /**
   * @minLength 0
   * @maxLength 500
   */
  ticketLink?: string | null;
  /**
   * This is used if a provider has a Team that our database doesn't have or know about
   * This will still allow the UI to show this team but not click to the organization
   * @minLength 0
   * @maxLength 255
   */
  unknownTeamDisplay?: string | null;
  userOverride: boolean;
  /** @format int32 */
  year: number;
}

export interface GameOdds {
  moneyline?: MoneylineInfo;
  overUnder?: OverUnderInfo;
  pointSpread?: PointSpreadInfo;
}

export interface GameOddsResponse {
  /** @format double */
  awayHandicap?: number | null;
  /** @format int32 */
  awayMoneylineOdds?: number | null;
  /** @format double */
  homeHandicap?: number | null;
  /** @format int32 */
  homeMoneylineOdds?: number | null;
  /** @format double */
  overUnderTotal?: number | null;
}

export interface GameOverride {
  /** @format int32 */
  awayOrgKey?: number | null;
  /** @format int32 */
  awayScore?: number | null;
  /** @format int32 */
  awayScorePrediction?: number | null;
  /** @format double */
  awayWinProbability?: number | null;
  city?: string | null;
  /** @format int32 */
  currentAwayTeamRank?: number | null;
  /** @format int32 */
  currentHomeTeamRank?: number | null;
  /** @format int64 */
  endTimeUtc?: number | null;
  gameBroadcast?: string | null;
  gameStat?: GameStat;
  gameType?: GameType;
  /** @format int32 */
  highestRanking?: number | null;
  /** @format int32 */
  homeOrgKey?: number | null;
  /** @format int32 */
  homeScore?: number | null;
  /** @format int32 */
  homeScorePrediction?: number | null;
  /** @format double */
  homeWinProbability?: number | null;
  /** @format int32 */
  lastUpdated?: number | null;
  location?: string | null;
  /** @format int64 */
  seasonPhaseKey?: number | null;
  /** @format int32 */
  sportKey: number;
  startTimeTbd: boolean;
  /** @format int64 */
  startTimeUtc?: number | null;
  state?: string | null;
  status?: string | null;
  statusEnum: GameStatuses;
  ticketLink?: string | null;
  /** @format int32 */
  year: number;
}

export interface GameOverrideDetails {
  imported?: GameOverride;
  overrides?: GameOverride;
}

export interface GameStat {
  awayRecord?: string | null;
  /** @format int32 */
  awayScorePrediction?: number | null;
  /** @format double */
  awayWinProbability?: number | null;
  game?: Game;
  /** @format date-span */
  gameClock?: string | null;
  /** @format int64 */
  gameKey: number;
  /** @format int32 */
  gamePeriod?: number | null;
  hasPrediction?: boolean | null;
  homeRecord?: string | null;
  /** @format int32 */
  homeScorePrediction?: number | null;
  /** @format double */
  homeWinProbability?: number | null;
  isDirty: boolean;
  /** @format int64 */
  key: number;
  wasPredictionCorrect?: boolean | null;
}

export enum GameStatuses {
  Unknown = 'Unknown',
  Scheduled = 'Scheduled',
  InProgress = 'InProgress',
  Delayed = 'Delayed',
  Postponed = 'Postponed',
  Completed = 'Completed',
}

export enum GameType {
  Unknown = 'Unknown',
  RegularSeason = 'RegularSeason',
  Playoff = 'Playoff',
  Exhibition = 'Exhibition',
  Jamboree = 'Jamboree',
}

export interface Headline {
  d17FormattedBody?: string | null;
  d17YoastHead?: string | null;
  author?: WpUser;
  /** @format int64 */
  authorKey: number;
  /** @minLength 1 */
  body: string;
  categories?: Term[];
  excerpt?: string;
  /**
   * @minLength 0
   * @maxLength 400
   */
  fullPath?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  guid?: string;
  isPlus: boolean;
  /** @format int64 */
  key: number;
  metas?: PostMeta[];
  /** @format date-time */
  modifiedDate: string;
  /** @format date-time */
  modifiedDateGMT: string;
  notificationLogs?: ArticleNotificationLog[];
  /** @format date-time */
  postDate: string;
  /** @format date-time */
  postDateGMT: string;
  postTerms?: PostTerm[];
  primaryCategory?: WpTerm;
  /** @format int64 */
  primaryCategoryKey?: number | null;
  primaryCategoryMeta?: PostMeta;
  /**
   * @minLength 0
   * @maxLength 200
   */
  slug?: string | null;
  /**
   * One of the following:
   * <list type="bullet"><item><term>auto-draft</term></item><item><term>draft</term></item><item><term>future</term></item><item><term>inherit</term></item><item><term>pending</term></item><item><term>post_status</term></item><item><term>private</term></item><item><term>publish</term></item><item><term>trash</term></item></list>
   *
   * <b>Note:</b> Statuses can be added/removed based on plugins and plugin versions.
   * @minLength 0
   * @maxLength 20
   */
  status?: string | null;
  tags?: Term[];
  termRelationships?: WpTermRelationship[];
  terms?: Term[];
  thumbnail?: Attachment;
  /** @format int64 */
  thumbnailKey?: number | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  title?: string | null;
  /**
   * One of the following:
   * <list type="bullet"><item><term>acf-field</term></item><item><term>acf-field-group</term></item><item><term>adstxt</term></item><item><term>app-adstxt</term></item><item><term>apto_sort</term></item><item><term>attachment</term></item><item><term>memberpresscoupon</term></item><item><term>memberpressgroup</term></item><item><term>memberpressproduct</term></item><item><term>mp-reminder</term></item><item><term>mt_pp</term></item><item><term>nav_menu_item</term></item><item><term>oembed_cache</term></item><item><term>page</term></item><item><term>post</term></item><item><term>revision</term></item><item><term>wp_block</term></item><item><term>wp_global_styles</term></item><item><term>wp_navigation</term></item></list>
   *
   * <b>Note:</b> Types can be added/removed based on plugins and plugin versions.
   * @minLength 0
   * @maxLength 20
   */
  type?: string | null;
}

export interface InfluenceBreakdownDto {
  /** @format int64 */
  instagramFollowers?: number | null;
  /** @format double */
  instagramPostValue: number;
  /** @format int64 */
  tikTokFollowers?: number | null;
  /** @format double */
  tikTokPostValue: number;
  /** @format int64 */
  twitterFollowers?: number | null;
  /** @format double */
  twitterPostValue: number;
}

export interface Int64NullableValueVisibility {
  /** @format int64 */
  value?: number | null;
  visibility: Visibility;
}

export interface Int64PersonContactInfoMeta {
  /** @format int64 */
  value: number;
  verification?: Verification;
  visibility: Visibility;
}

export enum InterestLevel {
  None = 'None',
  ScoutLook = 'ScoutLook',
  Interested = 'Interested',
  HighChoice = 'HighChoice',
  TopTarget = 'TopTarget',
}

export interface JwPlayerShowMeta {
  /** @format date-time */
  lastUpdated?: string | null;
  playlistId?: string | null;
}

export interface KeyUrlBase {
  fullName?: string;
  /** @format int64 */
  key: number;
  slug?: string | null;
  url?: string | null;
}

export interface League {
  /**
   * @minLength 0
   * @maxLength 10
   */
  abbreviation?: string | null;
  /** @format int64 */
  key: number;
  leagueConferences?: LeagueConference[];
  /**
   * @minLength 0
   * @maxLength 75
   */
  name: string;
  organizationType: OrganizationType;
  sport?: Sport;
  /** @format int32 */
  sportKey: number;
}

export interface LeagueConference {
  conference?: Conference;
  /** @format int32 */
  conferenceKey: number;
  /** @format int64 */
  key: number;
  league?: League;
  leagueConferenceOrganizations?: LeagueConferenceOrganization[];
  /** @format int64 */
  leagueKey: number;
  sport?: Sport;
  /** @format int32 */
  sportKey: number;
}

export interface LeagueConferenceOrganization {
  /** @format int64 */
  key: number;
  leagueConference?: LeagueConference;
  /** @format int64 */
  leagueConferenceKey: number;
  organization?: Organization;
  /** @format int32 */
  organizationKey: number;
  /** @format int32 */
  year: number;
}

export interface LeagueSeason {
  /** @format int64 */
  key: number;
  league?: League;
  /** @format int64 */
  leagueKey: number;
  /**
   * @minLength 0
   * @maxLength 50
   */
  name: string;
  phases?: SeasonPhase[];
  /** @format int32 */
  year: number;
}

export enum MappingSource {
  Massey = 'Massey',
  MaxPreps = 'MaxPreps',
  CalPreps = 'CalPreps',
  Nfhs = 'Nfhs',
  Nces = 'Nces',
  TfsSports = 'TfsSports',
  Si = 'Si',
  Espn = 'Espn',
}

export interface Measurement {
  currentHeightPerson?: Person;
  currentWeightPerson?: Person;
  /** @format int32 */
  dateAdded: number;
  /** @format int32 */
  dateModified: number;
  /** @format int32 */
  dateOccurred?: number | null;
  elite: boolean;
  event?: Event;
  /** @format int64 */
  eventKey?: number | null;
  isCurrent: boolean;
  /** @format int64 */
  key: number;
  /** @format int64 */
  measurementTypeKey: number;
  person?: Person;
  /** @format int64 */
  personKey: number;
  /** @format int64 */
  personSportOrgKey?: number | null;
  personSportOrganization?: PersonSportOrganization;
  /**
   * Place to store what type of record that a person achieved with a measurement.
   * As this measurement can achieve multiple records at the same time, a flag makes the most sense here
   *   (i.e. - Personal Record, State Record, World Record)
   */
  record: MeasurementRecord;
  type?: MeasurementType;
  /** @format double */
  value?: number | null;
  verified: boolean;
  /** @format int64 */
  verifiedByUserKey?: number | null;
}

/**
 * Place to store what type of record that a person achieved with a measurement.
 * As this measurement can achieve multiple records at the same time, a flag makes the most sense here
 *   (i.e. - Personal Record, State Record, World Record)
 */
export enum MeasurementRecord {
  None = 'None',
  PersonalRecord = 'PersonalRecord',
  StateRecord = 'StateRecord',
  WorldRecord = 'WorldRecord',
}

export interface MeasurementType {
  isTrackAndField: boolean;
  /** @format int64 */
  key: number;
  measurementTypePositions?: MeasurementTypePosition[];
  measurements?: Measurement[];
  sort: SortMeasurementType;
  /**
   * @minLength 0
   * @maxLength 25
   */
  type: string;
  /**
   * @minLength 0
   * @maxLength 20
   */
  units?: string | null;
}

export interface MeasurementTypePosition {
  /** @format int64 */
  key: number;
  measurementType?: MeasurementType;
  /** @format int64 */
  measurementTypeKey: number;
  position?: Position;
  /** @format int32 */
  positionKey: number;
}

export interface MediaLibraryItem {
  /**
   * @minLength 0
   * @maxLength 255
   */
  bucket: string;
  extraInfo?: string | null;
  /** @format int64 */
  id: number;
  isPrivate: boolean;
  isVerified: boolean;
  /**
   * @minLength 0
   * @maxLength 1024
   */
  originalPath: string;
  /**
   * @minLength 0
   * @maxLength 1024
   */
  originalSourcePath: string;
  /** @format int32 */
  originator: number;
  /**
   * @minLength 0
   * @maxLength 1024
   */
  path: string;
  /**
   * @minLength 0
   * @maxLength 18
   */
  provider: string;
  /**
   * @minLength 0
   * @maxLength 255
   */
  region: string;
  source?: Attachment;
  /** @format int64 */
  sourceId: number;
  /**
   * @minLength 0
   * @maxLength 1024
   */
  sourcePath: string;
  /**
   * @minLength 0
   * @maxLength 18
   */
  sourceType: string;
}

export interface MegaphoneShowMeta {
  feedId?: string | null;
  /** @format date-time */
  lastUpdated?: string | null;
}

export interface MembershipCoupon {
  author?: WpUser;
  /** @format int64 */
  authorKey: number;
  /** @minLength 1 */
  body: string;
  /**
   * @minLength 0
   * @maxLength 400
   */
  fullPath?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  guid?: string;
  /** @format int64 */
  key: number;
  metas?: PostMeta[];
  mode?: string | null;
  /** @format date-time */
  modifiedDate: string;
  /** @format date-time */
  modifiedDateGMT: string;
  notificationLogs?: ArticleNotificationLog[];
  /** @format date-time */
  postDate: string;
  /** @format date-time */
  postDateGMT: string;
  postTerms?: PostTerm[];
  /**
   * @minLength 0
   * @maxLength 200
   */
  slug?: string | null;
  /**
   * One of the following:
   * <list type="bullet"><item><term>auto-draft</term></item><item><term>draft</term></item><item><term>future</term></item><item><term>inherit</term></item><item><term>pending</term></item><item><term>post_status</term></item><item><term>private</term></item><item><term>publish</term></item><item><term>trash</term></item></list>
   *
   * <b>Note:</b> Statuses can be added/removed based on plugins and plugin versions.
   * @minLength 0
   * @maxLength 20
   */
  status?: string | null;
  termRelationships?: WpTermRelationship[];
  /**
   * @minLength 0
   * @maxLength 255
   */
  title?: string | null;
  /**
   * One of the following:
   * <list type="bullet"><item><term>acf-field</term></item><item><term>acf-field-group</term></item><item><term>adstxt</term></item><item><term>app-adstxt</term></item><item><term>apto_sort</term></item><item><term>attachment</term></item><item><term>memberpresscoupon</term></item><item><term>memberpressgroup</term></item><item><term>memberpressproduct</term></item><item><term>mp-reminder</term></item><item><term>mt_pp</term></item><item><term>nav_menu_item</term></item><item><term>oembed_cache</term></item><item><term>page</term></item><item><term>post</term></item><item><term>revision</term></item><item><term>wp_block</term></item><item><term>wp_global_styles</term></item><item><term>wp_navigation</term></item></list>
   *
   * <b>Note:</b> Types can be added/removed based on plugins and plugin versions.
   * @minLength 0
   * @maxLength 20
   */
  type?: string | null;
}

export interface MembershipPlan {
  author?: WpUser;
  /** @format int64 */
  authorKey: number;
  /** @minLength 1 */
  body: string;
  forumGroups?: PlanForumGroup[];
  /**
   * @minLength 0
   * @maxLength 400
   */
  fullPath?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  guid?: string;
  /** @format int64 */
  key: number;
  metas?: PostMeta[];
  /** @format date-time */
  modifiedDate: string;
  /** @format date-time */
  modifiedDateGMT: string;
  notificationLogs?: ArticleNotificationLog[];
  planType?: string | null;
  /** @format date-time */
  postDate: string;
  /** @format date-time */
  postDateGMT: string;
  postTerms?: PostTerm[];
  sitePlans?: SitePlan[];
  /**
   * @minLength 0
   * @maxLength 200
   */
  slug?: string | null;
  /**
   * One of the following:
   * <list type="bullet"><item><term>auto-draft</term></item><item><term>draft</term></item><item><term>future</term></item><item><term>inherit</term></item><item><term>pending</term></item><item><term>post_status</term></item><item><term>private</term></item><item><term>publish</term></item><item><term>trash</term></item></list>
   *
   * <b>Note:</b> Statuses can be added/removed based on plugins and plugin versions.
   * @minLength 0
   * @maxLength 20
   */
  status?: string | null;
  termRelationships?: WpTermRelationship[];
  /**
   * @minLength 0
   * @maxLength 255
   */
  title?: string | null;
  /**
   * One of the following:
   * <list type="bullet"><item><term>acf-field</term></item><item><term>acf-field-group</term></item><item><term>adstxt</term></item><item><term>app-adstxt</term></item><item><term>apto_sort</term></item><item><term>attachment</term></item><item><term>memberpresscoupon</term></item><item><term>memberpressgroup</term></item><item><term>memberpressproduct</term></item><item><term>mp-reminder</term></item><item><term>mt_pp</term></item><item><term>nav_menu_item</term></item><item><term>oembed_cache</term></item><item><term>page</term></item><item><term>post</term></item><item><term>revision</term></item><item><term>wp_block</term></item><item><term>wp_global_styles</term></item><item><term>wp_navigation</term></item></list>
   *
   * <b>Note:</b> Types can be added/removed based on plugins and plugin versions.
   * @minLength 0
   * @maxLength 20
   */
  type?: string | null;
}

export interface MeprSubscription {
  /**
   * @minLength 0
   * @maxLength 10
   */
  ccLast4?: string | null;
  /**
   * @minLength 0
   * @maxLength 10
   */
  ccExpMonth?: string | null;
  /**
   * @minLength 0
   * @maxLength 10
   */
  ccExpYear?: string | null;
  /** @format int64 */
  couponKey?: number | null;
  /** @format date-time */
  createdAt: string;
  gateway?: string | null;
  /** @format int64 */
  key: number;
  limitCycles?: boolean | null;
  limitCyclesAction?: string | null;
  /** @format int32 */
  limitCyclesExpiresAfter?: number | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  limitCyclesExpiresType?: string | null;
  /** @format int32 */
  limitCyclesNumber?: number | null;
  /** @format int32 */
  period?: number | null;
  /**
   * @minLength 0
   * @maxLength 20
   */
  periodType?: string | null;
  /** @format double */
  price: number;
  product?: MembershipPlan;
  /** @format int64 */
  productKey: number;
  proratedTrial?: boolean | null;
  response?: string | null;
  /**
   * @minLength 0
   * @maxLength 20
   */
  status?: string | null;
  subscriptionKey?: string | null;
  /** @format double */
  taxAmount?: number | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  taxClass?: string | null;
  /** @format int32 */
  taxCompound?: number | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  taxDescription?: string | null;
  /** @format double */
  taxRate?: number | null;
  /** @format int32 */
  taxShipping?: number | null;
  /**
   * @minLength 0
   * @maxLength 64
   */
  token?: string | null;
  /** @format double */
  total?: number | null;
  transactions?: MeprTransaction[];
  trial?: boolean | null;
  /** @format double */
  trialAmount?: number | null;
  /** @format int32 */
  trialDays?: number | null;
  /** @format double */
  trialTaxAmount?: number | null;
  /** @format double */
  trialTotal?: number | null;
  user?: WpUser;
  /** @format int64 */
  userKey: number;
}

export interface MeprTransaction {
  /** @format double */
  amount: number;
  /** @format int64 */
  corporateAccountId?: number | null;
  /** @format int64 */
  couponKey?: number | null;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  expiresAt?: string | null;
  gateway?: string | null;
  /** @format int64 */
  id: number;
  /** @format int64 */
  parentTransactionId?: number | null;
  product?: MembershipPlan;
  /** @format int64 */
  productKey: number;
  prorated?: boolean | null;
  response?: string | null;
  status?: string | null;
  subscription?: MeprSubscription;
  /** @format int64 */
  subscriptionKey?: number | null;
  /** @format double */
  taxAmount?: number | null;
  taxClass?: string | null;
  /** @format int32 */
  taxCompound?: number | null;
  taxDescription?: string | null;
  /** @format double */
  taxRate?: number | null;
  /** @format int32 */
  taxShipping?: number | null;
  /** @format double */
  total?: number | null;
  transNum?: string | null;
  transactionType?: string | null;
  user?: WpUser;
  /** @format int64 */
  userKey: number;
}

export interface Metric {
  /** @format int64 */
  key: number;
  metricType: MetricType;
  /** @minLength 1 */
  name: string;
}

export enum MetricType {
  Revenue = 'Revenue',
  YouTube = 'YouTube',
  Podcasts = 'Podcasts',
  ArticlesAndBoards = 'ArticlesAndBoards',
  Subscription = 'Subscription',
  Traffic = 'Traffic',
  Channel = 'Channel',
  Article = 'Article',
  Note = 'Note',
}

/** External store (App Store, Google Play, etc) site subscription information */
export interface MobilePlan {
  enabled: boolean;
  /** @format int32 */
  key: number;
  /** The ID of the subscription in the external store */
  productId?: string;
  site?: Site;
  /** @format int32 */
  siteKey: number;
  store: ExternalStore;
  /** The ID of the correlating term in Piano */
  termId?: string;
  type: PlanType;
}

export interface MoneylineInfo {
  /** @format int32 */
  americanAwayOdds?: number | null;
  /** @format int32 */
  americanHomeOdds?: number | null;
  /** @format int64 */
  lastUpdatedUtc?: number | null;
  opening?: boolean | null;
  status?: string | null;
}

export interface NilDeal {
  agentNilDeal?: AgentNilDeal;
  /** @format int64 */
  agentNilDealKey?: number | null;
  /** @format double */
  amount?: number | null;
  collectiveGroup?: CollectiveGroup;
  /** @format int64 */
  collectiveGroupKey?: number | null;
  /** @format date-time */
  date?: string | null;
  headline?: Headline;
  /** @format int64 */
  headlineKey?: number | null;
  /** @format int64 */
  key: number;
  partnerCompany?: NilPartnerCompany;
  /** @format int64 */
  partnerCompanyKey?: number | null;
  sourceUrl?: string | null;
  /**
   * @minLength 0
   * @maxLength 20
   */
  type?: string;
  verified: boolean;
}

export interface NilDealPosition {
  /** @format int64 */
  key: number;
  organizationalNilDeal?: OrganizationalNilDeal;
  /** @format int64 */
  organizationalNilDealKey: number;
  position?: Position;
  /** @format int32 */
  positionKey?: number | null;
  sport?: Sport;
  /** @format int32 */
  sportKey?: number | null;
}

export interface NilPartnerCompany {
  defaultAsset?: Asset;
  /** @format int64 */
  defaultAssetKey?: number | null;
  details?: string | null;
  /** @format int64 */
  key: number;
  /** @minLength 1 */
  name: string;
  personNilDeals?: NilDeal[];
  url?: string | null;
}

export enum NilStatuses {
  Normal = 'Normal',
  Pending = 'Pending',
  Suspended = 'Suspended',
  Private = 'Private',
  Hidden = 'Hidden',
  Show = 'Show',
  Final = 'Final',
}

export enum NotifRelationTypes {
  Unknown = 'Unknown',
  ActivityFeed = 'ActivityFeed',
  CommunityFeed = 'CommunityFeed',
}

export enum NotificationStatuses {
  NotSent = 'NotSent',
  NeedsScheduled = 'NeedsScheduled',
  Scheduled = 'Scheduled',
  Sending = 'Sending',
  Sent = 'Sent',
  Canceling = 'Canceling',
  Canceled = 'Canceled',
  Error = 'Error',
}

export enum OracleStatus {
  Undetermined = 'Undetermined',
  Flipped = 'Flipped',
  Locked = 'Locked',
  Processed = 'Processed',
  Correct = 'Correct',
}

export interface OrgTypeClassHierarchyRes {
  classificationType: ClassificationType;
  displayName: string;
  isRankable: boolean;
  /** @format int32 */
  key: number;
  name: string;
  organizationType: OrganizationType;
  /** @format int32 */
  parentOrgTypeClassHierarchyKey?: number | null;
  path?: string | null;
  /** @format int64 */
  relatedKey?: number | null;
  /** @format int32 */
  rootOrgTypeClassHierarchyKey?: number | null;
  status: OrgTypeClassHierarchyStatus;
}

export enum OrgTypeClassHierarchyStatus {
  Unknown = 'Unknown',
  Active = 'Active',
  Removed = 'Removed',
}

export interface Organization {
  /**
   * @minLength 0
   * @maxLength 35
   */
  abbreviation?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  address?: string | null;
  addressCity?: City;
  /** @format int32 */
  addressCityKey?: number | null;
  alternateAsset?: Asset;
  /** @format int64 */
  alternateAssetKey?: number | null;
  baseCollectives?: BaseCollectiveOrganization[];
  bio?: string | null;
  currentLeague?: League;
  /** @format int64 */
  currentLeagueKey?: number | null;
  defaultAsset?: Asset;
  /** @format int64 */
  defaultAssetKey?: number | null;
  draftEligibleSummaries?: OrganizationDraftEligibleSummary[];
  draftPicks?: DraftPick[];
  exposureOverrides?: OrganizationSportExposureOverride[];
  fullName?: string;
  /** @format int64 */
  inflcrKey?: number | null;
  isForeign?: boolean | null;
  isPrivate?: boolean | null;
  /** @format int32 */
  key: number;
  /**
   * @minLength 0
   * @maxLength 50
   */
  knownAs?: string | null;
  /** @format int32 */
  latitude?: number | null;
  leagueConferenceOrganizations?: LeagueConferenceOrganization[];
  lightAsset?: Asset;
  /** @format int64 */
  lightAssetKey?: number | null;
  /** @format int32 */
  longitude?: number | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  mascot?: string | null;
  /** @format int32 */
  masseyKey?: number | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
  orgType: OrganizationType;
  organizationAssets?: OrganizationAsset[];
  organizationRankings?: OrganizationRanking[];
  organizationSports?: OrganizationSport[];
  personSportOrganizations?: PersonSportOrganization[];
  /**
   * @minLength 0
   * @maxLength 10
   */
  primaryColor?: string | null;
  recruitmentInterests?: RecruitmentInterest[];
  recruitments?: Recruitment[];
  /**
   * @minLength 0
   * @maxLength 10
   */
  secondaryColor?: string | null;
  /**
   * @minLength 0
   * @maxLength 75
   */
  slug?: string | null;
  socials?: OrganizationSocial;
  /**
   * @minLength 0
   * @maxLength 35
   */
  telephone?: string | null;
  throughOneDraftPicks?: DraftPick[];
  throughThreeDraftPicks?: DraftPick[];
  throughTwoDraftPicks?: DraftPick[];
  tradedDraftPicks?: DraftPick[];
  website?: string | null;
  /** @format int32 */
  zipCode?: number | null;
}

export interface OrganizationAsset {
  asset?: Asset;
  /** @format int64 */
  assetKey: number;
  /** @format int64 */
  key: number;
  organization?: Organization;
  /** @format int32 */
  organizationKey: number;
}

export interface OrganizationDraftEligibleSummary {
  /** @format int32 */
  blueChipsEligible: number;
  eligiblePersonSportKeys?: number[] | null;
  /** @format int32 */
  juniorsEligible: number;
  /** @format int64 */
  key: number;
  organization?: Organization;
  /** @format int32 */
  organizationKey: number;
  /** @format int32 */
  seniorsEligible: number;
  sport?: Sport;
  /** @format int32 */
  sportKey: number;
  /** @format int32 */
  year: number;
}

export interface OrganizationRanking {
  /** @format double */
  appliedAverageConsensusRating?: number | null;
  /** @format double */
  appliedAverageRating?: number | null;
  /** @format int32 */
  appliedCommits?: number | null;
  /** @format double */
  appliedTotalConsensusRating?: number | null;
  /** @format double */
  appliedTotalRating?: number | null;
  /** @format int32 */
  commits?: number | null;
  /** @format int32 */
  conferenceConsensusRank?: number | null;
  /** @format int32 */
  conferenceRank?: number | null;
  /** @format int32 */
  consensusFiveStars?: number | null;
  /** @format int32 */
  consensusFourStars?: number | null;
  /** @format int32 */
  consensusThreeStars?: number | null;
  /** @format int32 */
  dateModified: number;
  /** @format double */
  deductions?: number | null;
  deductionsDescription?: string | null;
  /** @format int32 */
  fiveStars?: number | null;
  /** @format int32 */
  fourStars?: number | null;
  /** @format int64 */
  key: number;
  organization?: Organization;
  /** @format int32 */
  organizationKey: number;
  /** @format int32 */
  overallConsensusRank?: number | null;
  /** @format int32 */
  overallRank?: number | null;
  ranking?: Ranking;
  /** @format int32 */
  rankingKey: number;
  /** @format int32 */
  threeStars?: number | null;
}

export interface OrganizationRankingPhaseRequest {
  /** @format int32 */
  associatedPressPoints?: number | null;
  /** @format int32 */
  associatedPressRanking?: number | null;
  associatedPressTrend?: RankingTrend;
  /** @format int32 */
  associatedPressTrendNumber?: number | null;
  /** @format int32 */
  coachesPollPoints?: number | null;
  /** @format int32 */
  coachesPollRanking?: number | null;
  coachesPollTrend?: RankingTrend;
  /** @format int32 */
  coachesPollTrendNumber?: number | null;
  /** @format int64 */
  gameKey?: number | null;
  /** @format int32 */
  orgKey: number;
  /** @format int32 */
  playoffRanking?: number | null;
  playoffTrend?: RankingTrend;
  /** @format int32 */
  playoffTrendNumber?: number | null;
  /** @format int64 */
  seasonPhaseKey: number;
}

export interface OrganizationRankingPhaseResponse {
  /** @format int32 */
  associatedPressPoints?: number | null;
  /** @format int32 */
  associatedPressRanking?: number | null;
  associatedPressTrend?: RankingTrend;
  /** @format int32 */
  associatedPressTrendNumber?: number | null;
  /** @format int32 */
  coachesPollPoints?: number | null;
  /** @format int32 */
  coachesPollRanking?: number | null;
  coachesPollTrend?: RankingTrend;
  /** @format int32 */
  coachesPollTrendNumber?: number | null;
  organization?: OrganizationResponse;
  /** @format int32 */
  organizationKey: number;
  /** @format int32 */
  playoffRanking?: number | null;
  playoffTrend?: RankingTrend;
  /** @format int32 */
  playoffTrendNumber?: number | null;
  /** @format int64 */
  seasonPhaseKey?: number | null;
}

export interface OrganizationResponse {
  abbreviation?: string | null;
  assetUrl?: string | null;
  defaultAsset?: AssetResponse;
  division?: string | null;
  fullName?: string;
  /** @format int32 */
  key: number;
  knownAs?: string | null;
  mascot?: string | null;
  name?: string;
  orgType?: string;
  orgTypeEnum: OrganizationType;
  primaryColor?: string | null;
  siteKeys?: number[] | null;
  slug?: string | null;
  urlSlug?: string | null;
}

export interface OrganizationSocial {
  athleticsInstagram?: string | null;
  athleticsTwitter?: string | null;
  basketballInstagram?: string | null;
  basketballTwitter?: string | null;
  footballInstagram?: string | null;
  footballTwitter?: string | null;
  /** @format int32 */
  key: number;
  /** @format int32 */
  orgKey: number;
  organization?: Organization;
}

/**
 * The purpose of OrganizationSport is to provide an easy mechanism for filtering
 * the organizations for a sport and vice versa
 */
export interface OrganizationSport {
  /** @format int32 */
  key: number;
  organization?: Organization;
  /** @format int32 */
  organizationKey: number;
  sport?: Sport;
  /** @format int32 */
  sportKey: number;
}

export interface OrganizationSportExposureOverride {
  /** @format int64 */
  key: number;
  organization?: Organization;
  /** @format int32 */
  organizationKey: number;
  /**
   * @format double
   * @min 0
   * @max 100
   */
  override: number;
  sport?: Sport;
  /** @format int32 */
  sportKey: number;
}

export enum OrganizationType {
  Unknown = 'Unknown',
  HighSchool = 'HighSchool',
  JuniorCollege = 'JuniorCollege',
  College = 'College',
  Professional = 'Professional',
  PrepSchool = 'PrepSchool',
  Developmental = 'Developmental',
}

export interface OrganizationalNilDeal {
  agentNilDeal?: AgentNilDeal;
  /** @format int64 */
  agentNilDealKey?: number | null;
  /** @format double */
  amount?: number | null;
  collectiveGroup?: CollectiveGroup;
  /** @format int64 */
  collectiveGroupKey?: number | null;
  /** @format date-time */
  date?: string | null;
  headline?: Headline;
  /** @format int64 */
  headlineKey?: number | null;
  /** @format int64 */
  key: number;
  nilDealPositions?: NilDealPosition[];
  organization?: Organization;
  /** @format int32 */
  organizationKey: number;
  partnerCompany?: NilPartnerCompany;
  /** @format int64 */
  partnerCompanyKey?: number | null;
  sourceUrl?: string | null;
  /**
   * @minLength 0
   * @maxLength 20
   */
  type?: string;
  verified: boolean;
}

export interface OverUnderInfo {
  /** @format int64 */
  lastUpdatedUtc?: number | null;
  opening?: boolean | null;
  /** @format int32 */
  overOdds?: number | null;
  status?: string | null;
  /** @format double */
  totals?: number | null;
  /** @format int32 */
  underOdds?: number | null;
}

export interface Page {
  author?: WpUser;
  /** @format int64 */
  authorKey: number;
  /** @minLength 1 */
  body: string;
  /**
   * @minLength 0
   * @maxLength 400
   */
  fullPath?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  guid?: string;
  /** @format int64 */
  key: number;
  metas?: PostMeta[];
  /** @format date-time */
  modifiedDate: string;
  /** @format date-time */
  modifiedDateGMT: string;
  notificationLogs?: ArticleNotificationLog[];
  /** @format date-time */
  postDate: string;
  /** @format date-time */
  postDateGMT: string;
  postTerms?: PostTerm[];
  /**
   * @minLength 0
   * @maxLength 200
   */
  slug?: string | null;
  /**
   * One of the following:
   * <list type="bullet"><item><term>auto-draft</term></item><item><term>draft</term></item><item><term>future</term></item><item><term>inherit</term></item><item><term>pending</term></item><item><term>post_status</term></item><item><term>private</term></item><item><term>publish</term></item><item><term>trash</term></item></list>
   *
   * <b>Note:</b> Statuses can be added/removed based on plugins and plugin versions.
   * @minLength 0
   * @maxLength 20
   */
  status?: string | null;
  termRelationships?: WpTermRelationship[];
  /**
   * @minLength 0
   * @maxLength 255
   */
  title?: string | null;
  /**
   * One of the following:
   * <list type="bullet"><item><term>acf-field</term></item><item><term>acf-field-group</term></item><item><term>adstxt</term></item><item><term>app-adstxt</term></item><item><term>apto_sort</term></item><item><term>attachment</term></item><item><term>memberpresscoupon</term></item><item><term>memberpressgroup</term></item><item><term>memberpressproduct</term></item><item><term>mp-reminder</term></item><item><term>mt_pp</term></item><item><term>nav_menu_item</term></item><item><term>oembed_cache</term></item><item><term>page</term></item><item><term>post</term></item><item><term>revision</term></item><item><term>wp_block</term></item><item><term>wp_global_styles</term></item><item><term>wp_navigation</term></item></list>
   *
   * <b>Note:</b> Types can be added/removed based on plugins and plugin versions.
   * @minLength 0
   * @maxLength 20
   */
  type?: string | null;
}

export interface Pagination {
  /** @format int32 */
  count: number;
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  itemsPerPage: number;
  /** @format int32 */
  limit: number;
  /** @format int32 */
  offset: number;
  /** @format int32 */
  pageCount: number;
}

export interface PerformanceBreakdownDto {
  /** @format int32 */
  impactRank?: number | null;
  /** @format double */
  impactRating?: number | null;
  /** @format double */
  impactValue?: number | null;
  /** @format int32 */
  impactYear?: number | null;
  orgType?: OrganizationType;
  /** @format int32 */
  orgYear?: number | null;
  psoWalkOn: boolean;
  /** @format double */
  rankingValue?: number | null;
  recruitOffer: boolean;
  /** @format int32 */
  recruitRank?: number | null;
  /** @format double */
  recruitRating?: number | null;
  /** @format double */
  recruitValue?: number | null;
  /** @format int32 */
  recruitYear?: number | null;
  /** @format int64 */
  recruitmentRosterManagementValuation?: number | null;
  /** @format int64 */
  rosterManagementValuation?: number | null;
  /** @format double */
  transferValue?: number | null;
  /** @format int32 */
  transferYear?: number | null;
}

export interface Person {
  agentInformation?: ContactInfo;
  almaMater?: Organization;
  /** @format int32 */
  almaMaterClassYear?: number | null;
  /** @format int32 */
  almaMaterKey?: number | null;
  alternateVerifications?: AlternatePersonVerification[];
  bio?: string | null;
  bioCollegeRecruit?: string | null;
  bioProProspect?: string | null;
  /** @format int32 */
  classYear: number;
  collegeOrganization?: Organization;
  /** @format int32 */
  collegeOrganizationKey?: number | null;
  contactInfo?: PersonContactInfo;
  currentHeightMeasurement?: Measurement;
  /** @format int64 */
  currentHeightMeasurementKey?: number | null;
  currentWeightMeasurement?: Measurement;
  /** @format int64 */
  currentWeightMeasurementKey?: number | null;
  /** @format date-time */
  dateOfBirth?: string | null;
  defaultAsset?: Asset;
  /** @format int64 */
  defaultAssetKey?: number | null;
  defaultPersonSport?: PersonSport;
  /** @format int64 */
  defaultPersonSportKey?: number | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  degree?: string | null;
  education?: PersonEducation;
  /**
   * @minLength 0
   * @maxLength 255
   */
  espnProfile?: string | null;
  excludeFromNil: boolean;
  /**
   * @minLength 0
   * @maxLength 45
   */
  firstName: string;
  fullName?: string;
  highSchoolAlmaMater?: Organization;
  /** @format int32 */
  highSchoolAlmaMaterOrgKey?: number | null;
  highSchoolOrganization?: Organization;
  /** @format int32 */
  highSchoolOrganizationKey?: number | null;
  hometown?: City;
  /** @format int32 */
  hometownCityKey?: number | null;
  /** @format uuid */
  inflcrKey?: string | null;
  /** @format int64 */
  inflcrLastSynced?: number | null;
  /**
   * @minLength 0
   * @maxLength 30
   */
  instagramId?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  instagramProfile?: string | null;
  /** @format int32 */
  invalidVerificationAttempts: number;
  /** @format int64 */
  key: number;
  /**
   * @minLength 0
   * @maxLength 45
   */
  knownAsName?: string | null;
  /**
   * @minLength 0
   * @maxLength 45
   */
  lastName: string;
  measurements?: Measurement[];
  moneyWants?: PersonNilMoneyWanted[];
  /**
   * @format double
   * @min -9999999999.99
   * @max 9999999999.99
   */
  nilAdjustedValuation: number;
  nilAdjustedValuationReason?: string | null;
  nilStatus: NilStatuses;
  nilValuationDrafts?: PersonNilValuationDraft[];
  nilValuations?: PersonNilValuation[];
  parentInformation?: ContactInfo;
  personEngagements?: PersonEngagement[];
  personSports?: PersonSport[];
  prospectVerified: boolean;
  /**
   * @minLength 0
   * @maxLength 255
   */
  rivalsProfile?: string | null;
  secondaryParentInformation?: ContactInfo;
  settings?: PersonSettings;
  socialMetas?: PersonSocialMeta[];
  socialMetrics?: PersonSocialMetric[];
  socialTokens?: PersonSocialToken[];
  status?: PersonStatus;
  /**
   * @minLength 0
   * @maxLength 255
   */
  tikTokHandle?: string | null;
  /**
   * @minLength 0
   * @maxLength 30
   */
  tikTokId?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  twitterHandle?: string | null;
  /**
   * @minLength 0
   * @maxLength 30
   */
  twitterId?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  twoFourSevenProfile?: string | null;
  /** @format int64 */
  userKey?: number | null;
  wpUser?: WpUser;
  xfUser?: XfUser;
}

export interface PersonAsset {
  asset?: Asset;
  /** @format int64 */
  assetKey: number;
  isFeatured: boolean;
  isHeadshot: boolean;
  /** @format int64 */
  key: number;
  person?: Person;
  /** @format int64 */
  personKey: number;
  personSport?: PersonSport;
  /** @format int64 */
  personSportKey?: number | null;
}

export interface PersonConnectionFullResponse {
  connectedCollegeOrganization?: OrganizationResponse;
  connectedDraft?: DraftSimpleResponse;
  connectedPlayer?: SmallPlayerResponse;
  connectedRating?: RatingResponse;
  connectedRosterRating?: RatingResponse;
  connection?: PersonConnectionResponse;
}

export interface PersonConnectionRequestV2 {
  /** @format int64 */
  connectedPersonKey: number;
  /** @minLength 1 */
  description: string;
}

export interface PersonConnectionResponse {
  /** @format int64 */
  connectedPersonKey: number;
  connectedPersonSport?: PersonSportResponseRecord;
  description?: string;
  /** @format int64 */
  key: number;
  /** @format int64 */
  personKey: number;
  /** @format int32 */
  sportKey: number;
}

export interface PersonContactInfo {
  agentInfo?: ContactInfoPersonContactInfoMeta;
  birthDate?: Int64PersonContactInfoMeta;
  email?: StringPersonContactInfoMeta;
  instagramHandle?: StringPersonContactInfoMeta;
  parentInfo?: ContactInfoPersonContactInfoMeta;
  phoneNumber?: StringPersonContactInfoMeta;
  secondaryParentInfo?: ContactInfoPersonContactInfoMeta;
  tikTokHandle?: StringPersonContactInfoMeta;
  twitterHandle?: StringPersonContactInfoMeta;
  /** @format int32 */
  version: number;
}

export interface PersonEducation {
  /** @format int32 */
  act?: number | null;
  /** @format double */
  gpa?: number | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  highSchoolTranscriptUrl?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  intendedMajor?: string | null;
  /** @format int64 */
  key: number;
  person?: Person;
  /** @format int64 */
  personKey: number;
  /** @format int32 */
  sat?: number | null;
}

export interface PersonEngagement {
  /** @format date-time */
  endDate: string;
  /** @format int64 */
  key: number;
  person?: Person;
  /** @format int64 */
  personKey: number;
  socialType: SocialType;
  /** @format date-time */
  startDate: string;
  /** @format int64 */
  totalComments: number;
  /** @format int64 */
  totalLikes: number;
  /** @format int64 */
  totalPlays: number;
  /** @format int64 */
  totalPosts: number;
  /** @format int64 */
  totalQuoted: number;
  /** @format int64 */
  totalShareCount: number;
}

export interface PersonFeedItem {
  /** @format date-time */
  date: string;
  eliteFeedItem?: EliteFeedItem;
  /** @format int64 */
  eliteFeedItemKey: number;
  /** @format int64 */
  key: number;
  /** @format int32 */
  page: number;
  /** @format int32 */
  pageOrder: number;
  person?: Person;
  /** @format int64 */
  personKey: number;
}

export interface PersonNilDeal {
  agentNilDeal?: AgentNilDeal;
  /** @format int64 */
  agentNilDealKey?: number | null;
  /** @format double */
  amount?: number | null;
  collectiveGroup?: CollectiveGroup;
  /** @format int64 */
  collectiveGroupKey?: number | null;
  /** @format date-time */
  date?: string | null;
  headline?: Headline;
  /** @format int64 */
  headlineKey?: number | null;
  /** @format int64 */
  key: number;
  partnerCompany?: NilPartnerCompany;
  /** @format int64 */
  partnerCompanyKey?: number | null;
  person?: Person;
  /** @format int64 */
  personKey: number;
  personSportOrganization?: PersonSportOrganization;
  /** @format int64 */
  personSportOrganizationKey: number;
  sourceUrl?: string | null;
  /**
   * @minLength 0
   * @maxLength 20
   */
  type?: string;
  verified: boolean;
}

export interface PersonNilMoneyWanted {
  /** @format double */
  annualMoneyWanted: number;
  /** @format date-time */
  date: string;
  /** @format int64 */
  key: number;
  person?: Person;
  /** @format int64 */
  personKey: number;
}

export interface PersonNilValuation {
  boostSummary?: any;
  /** @format date-time */
  date: string;
  exposureBreakdown?: ExposureBreakdownDto;
  /**
   * @format double
   * @min 0
   * @max 100
   */
  exposureScore?: number | null;
  /** @format int64 */
  exposureValue: number;
  groupOrganizationType?: OrganizationType;
  groupPositionType?: PositionType;
  /** @format int64 */
  groupRank?: number | null;
  groupSport?: Sport;
  /** @format int32 */
  groupSportKey?: number | null;
  /** @format int32 */
  groupYear?: number | null;
  influenceBreakdown?: InfluenceBreakdownDto;
  /**
   * @format double
   * @min 0
   * @max 100
   */
  influenceScore?: number | null;
  /** @format int64 */
  influenceValue: number;
  /** @format int64 */
  key: number;
  nilStatus: NilStatuses;
  performanceBreakdown?: PerformanceBreakdownDto;
  /**
   * @format double
   * @min 0
   * @max 100
   */
  performanceScore?: number | null;
  /** @format int64 */
  performanceValue: number;
  person?: Person;
  /** @format int64 */
  personKey: number;
  personSportOrganization?: PersonSportOrganization;
  /** @format int64 */
  personSportOrganizationKey?: number | null;
  previousPersonNilValuation?: PersonNilValuation;
  /** @format int64 */
  previousPersonNilValuationKey?: number | null;
  /** @format int64 */
  rank?: number | null;
  recruitment?: Recruitment;
  /** @format int64 */
  recruitmentKey?: number | null;
  /**
   * @format double
   * @min 0
   * @max 9999.999999
   */
  score?: number | null;
  stale: boolean;
  /** @format int64 */
  totalValue: number;
}

export interface PersonNilValuationDraft {
  boostSummary?: any;
  /** @format date-time */
  date: string;
  exposureBreakdown?: ExposureBreakdownDto;
  /**
   * @format double
   * @min 0
   * @max 100
   */
  exposureScore?: number | null;
  /** @format int64 */
  exposureValue: number;
  groupOrganizationType?: OrganizationType;
  groupPositionType?: PositionType;
  /** @format int64 */
  groupRank?: number | null;
  groupSport?: Sport;
  /** @format int32 */
  groupSportKey?: number | null;
  /** @format int32 */
  groupYear?: number | null;
  influenceBreakdown?: InfluenceBreakdownDto;
  /**
   * @format double
   * @min 0
   * @max 100
   */
  influenceScore?: number | null;
  /** @format int64 */
  influenceValue: number;
  /** @format int64 */
  key: number;
  nilStatus: NilStatuses;
  performanceBreakdown?: PerformanceBreakdownDto;
  /**
   * @format double
   * @min 0
   * @max 100
   */
  performanceScore?: number | null;
  /** @format int64 */
  performanceValue: number;
  person?: Person;
  /** @format int64 */
  personKey: number;
  personSportOrganization?: PersonSportOrganization;
  /** @format int64 */
  personSportOrganizationKey?: number | null;
  /** @format int64 */
  rank?: number | null;
  recruitment?: Recruitment;
  /** @format int64 */
  recruitmentKey?: number | null;
  /**
   * @format double
   * @min 0
   * @max 9999.999999
   */
  score?: number | null;
  stale: boolean;
  /** @format int64 */
  totalValue: number;
}

export interface PersonSettings {
  birthDate?: Int64NullableValueVisibility;
  cellNumber?: StringValueVisibility;
  email?: StringValueVisibility;
}

export interface PersonSocialMeta {
  /** @format int64 */
  key: number;
  /** @format int32 */
  lastError?: number | null;
  lastErrorMessage?: string | null;
  lastErrorType?: ScrapeErrorType;
  /** @format int32 */
  lastScrape?: number | null;
  /** @format int32 */
  nextScrape?: number | null;
  person?: Person;
  /** @format int64 */
  personKey: number;
  privateAccount?: boolean | null;
  socialType: SocialType;
  status: SocialMetaStatus;
}

export interface PersonSocialMetric {
  /** @format int64 */
  followers: number;
  /** @format int64 */
  following: number;
  handshake: boolean;
  /** @format int64 */
  key: number;
  /** @format int64 */
  mediaCount: number;
  person?: Person;
  /** @format int64 */
  personKey: number;
  socialType: SocialType;
  verified: boolean;
}

export interface PersonSocialToken {
  /** @format double */
  createdDate: number;
  /** @format double */
  expiresIn: number;
  /** @format int64 */
  key: number;
  person?: Person;
  /** @format int64 */
  personKey: number;
  profileType?: ProfileType;
  socialType: SocialType;
  token?: string | null;
  user?: WpUser;
  /** @format int64 */
  userKey: number;
}

export interface PersonSport {
  currentCoachPersonSportOrganization?: PersonSportOrganization;
  /** @format int64 */
  currentCoachPersonSportOrganizationKey?: number | null;
  currentPersonSportRanking?: PersonSportRanking;
  /** @format int64 */
  currentPersonSportRankingKey?: number | null;
  currentPlayerPersonSportOrganization?: PersonSportOrganization;
  /** @format int64 */
  currentPlayerPersonSportOrganizationKey?: number | null;
  currentRecruitment?: Recruitment;
  /** @format int64 */
  currentRecruitmentKey?: number | null;
  currentRosterPersonSportRanking?: PersonSportRanking;
  /** @format int64 */
  currentRosterPersonSportRankingKey?: number | null;
  currentTransferPersonSportRanking?: PersonSportRanking;
  /** @format int64 */
  currentTransferPersonSportRankingKey?: number | null;
  defaultPerson?: Person;
  defaultSport: boolean;
  draftRankings?: PersonSportRankingDraft[];
  /** @format int64 */
  key: number;
  pageFlags: PersonSportPageFlags;
  person?: Person;
  /** @format int64 */
  personKey: number;
  personSportEventResults?: PersonSportEventResult[];
  personSportOrganizations?: PersonSportOrganization[];
  publishedRankings?: PersonSportRanking[];
  rankingHistory?: PersonSportRankingHistory[];
  recruitmentInterests?: RecruitmentInterest[];
  recruitments?: Recruitment[];
  sport?: Sport;
  /** @format int32 */
  sportKey: number;
  /** @format int32 */
  tierStatus?: number | null;
}

export interface PersonSportEventResult {
  /** @format date-time */
  date?: string | null;
  event?: Event;
  /** @format int64 */
  eventKey: number;
  /** @format int64 */
  key: number;
  personSport?: PersonSport;
  /** @format int64 */
  personSportKey: number;
  /** @format double */
  result?: number | null;
  sportCategory?: SportCategory;
  /** @format int32 */
  sportCategoryKey: number;
}

export interface PersonSportOrganization {
  agency?: Agency;
  /** @format int64 */
  agencyKey?: number | null;
  agent?: AgentPerson;
  /** @format int64 */
  agentKey?: number | null;
  asset?: Asset;
  /** @format int64 */
  assetKey?: number | null;
  blueShirt: boolean;
  classYear?: ClassYear;
  collectiveTierValuation?: CollectiveTierValuation;
  deceased: boolean;
  /** @format int32 */
  depth?: number | null;
  draftParticipant: boolean;
  earlyEnrollee: boolean;
  earlySignee: boolean;
  endOfTeam: boolean;
  fired: boolean;
  freeTransferYear: boolean;
  freeYear: boolean;
  grayShirt: boolean;
  /** @format int32 */
  jerseyNumber?: number | null;
  /** @format int64 */
  key: number;
  measurements?: Measurement[];
  medical: boolean;
  nilDeals?: PersonNilDeal[];
  nilValuationDrafts?: PersonNilValuationDraft[];
  nilValuations?: PersonNilValuation[];
  noAgent: boolean;
  optedOut: boolean;
  organization?: Organization;
  /** @format int32 */
  organizationKey: number;
  personSport?: PersonSport;
  /** @format int64 */
  personSportKey: number;
  personSportRankings?: PersonSportRanking[];
  primaryPosition?: Position;
  /** @format int32 */
  primaryPositionKey?: number | null;
  promoted: boolean;
  recruitment?: Recruitment;
  redShirt: boolean;
  resigned: boolean;
  /** @format double */
  salary?: number | null;
  salaryUrl?: string | null;
  secondaryAgency?: Agency;
  /** @format int64 */
  secondaryAgencyKey?: number | null;
  secondaryAgent?: AgentPerson;
  /** @format int64 */
  secondaryAgentKey?: number | null;
  secondaryPosition?: Position;
  /** @format int32 */
  secondaryPositionKey?: number | null;
  stats?: PersonSportStat[];
  /** @format int32 */
  tierStatus?: number | null;
  transfer: boolean;
  /** @format date-time */
  transferDate?: string | null;
  /** @default true */
  walkOn: boolean;
  withdrawnTransfer: boolean;
  /** @format date-time */
  withdrawnTransferDate?: string | null;
  /** @format int32 */
  year: number;
  /** @format int32 */
  yearOrder: number;
}

export enum PersonSportPageFlags {
  Person = 'Person',
  Player = 'Player',
  Recruiting = 'Recruiting',
  Scouting = 'Scouting',
  Draft = 'Draft',
  Athlete = 'Athlete',
  Coach = 'Coach',
}

export interface PersonSportRanking {
  /** @format double */
  averageRank?: number | null;
  /** @format int32 */
  consensusOverallRank?: number | null;
  /** @format int32 */
  consensusPositionRank?: number | null;
  /** @format double */
  consensusRating?: number | null;
  /** @format int32 */
  consensusStars: number;
  /** @format int32 */
  consensusStateRank?: number | null;
  /** @format int32 */
  consensusStrength?: number | null;
  /** @format int32 */
  dateModified: number;
  /** @format int32 */
  espnLastUpdated?: number | null;
  /** @format int32 */
  espnOverallRank?: number | null;
  /** @format int32 */
  espnPositionRank?: number | null;
  /** @format double */
  espnRating?: number | null;
  /** @format int32 */
  espnStars?: number | null;
  /** @format int32 */
  espnStateRank?: number | null;
  fiveStarPlus: boolean;
  /** @format int64 */
  key: number;
  latestHistory?: PersonSportRankingHistory;
  /** @format int64 */
  latestHistoryKey?: number | null;
  nearlyFiveStarPlus: boolean;
  /** @format double */
  normalizedEspnOverallRank?: number | null;
  /** @format double */
  normalizedEspnRating?: number | null;
  /** @format double */
  normalizedOverallRank?: number | null;
  /** @format double */
  normalizedRivalsOverallRank?: number | null;
  /** @format double */
  normalizedRivalsRating?: number | null;
  /** @format double */
  normalizedTfsSportsOverallRank?: number | null;
  /** @format double */
  normalizedTfsSportsRating?: number | null;
  /** @format int32 */
  order?: number | null;
  /** @format int32 */
  overallRank?: number | null;
  personSport?: PersonSport;
  /** @format int64 */
  personSportKey: number;
  personSportOrganization?: PersonSportOrganization;
  /** @format int64 */
  personSportOrganizationKey?: number | null;
  position?: Position;
  /** @format int32 */
  positionKey?: number | null;
  /** @format int32 */
  positionRank?: number | null;
  ranking?: Ranking;
  /** @format int32 */
  rankingKey: number;
  /** @format double */
  rating?: number | null;
  recruitment?: Recruitment;
  /** @format int64 */
  recruitmentKey?: number | null;
  /** @format int32 */
  rivalsLastUpdated?: number | null;
  /** @format int32 */
  rivalsOverallRank?: number | null;
  /** @format int32 */
  rivalsPositionRank?: number | null;
  /** @format double */
  rivalsRating?: number | null;
  /** @format int32 */
  rivalsStars?: number | null;
  /** @format int32 */
  rivalsStateRank?: number | null;
  /** @format int32 */
  stars?: number | null;
  state?: State;
  /** @format int32 */
  stateKey?: number | null;
  /** @format int32 */
  stateRank?: number | null;
  /** @format int32 */
  tfsSportsLastUpdated?: number | null;
  /** @format int32 */
  tfsSportsOverallRank?: number | null;
  /** @format int32 */
  tfsSportsPositionRank?: number | null;
  /** @format double */
  tfsSportsRating?: number | null;
  /** @format int32 */
  tfsSportsStateRank?: number | null;
}

export interface PersonSportRankingDraft {
  /** @format double */
  averageRank?: number | null;
  /** @format int32 */
  consensusOverallRank?: number | null;
  /** @format int32 */
  consensusPositionRank?: number | null;
  /** @format double */
  consensusRating?: number | null;
  /** @format int32 */
  consensusStars: number;
  /** @format int32 */
  consensusStateRank?: number | null;
  /** @format int32 */
  consensusStrength?: number | null;
  /** @format int32 */
  dateModified: number;
  /** @format int32 */
  espnLastUpdated?: number | null;
  /** @format int32 */
  espnOverallRank?: number | null;
  /** @format int32 */
  espnPositionRank?: number | null;
  /** @format double */
  espnRating?: number | null;
  /** @format int32 */
  espnStars?: number | null;
  /** @format int32 */
  espnStateRank?: number | null;
  fiveStarPlus: boolean;
  /** @format int64 */
  key: number;
  nearlyFiveStarPlus: boolean;
  /** @format double */
  normalizedEspnOverallRank?: number | null;
  /** @format double */
  normalizedEspnRating?: number | null;
  /** @format double */
  normalizedOverallRank?: number | null;
  /** @format double */
  normalizedRivalsOverallRank?: number | null;
  /** @format double */
  normalizedRivalsRating?: number | null;
  /** @format double */
  normalizedTfsSportsOverallRank?: number | null;
  /** @format double */
  normalizedTfsSportsRating?: number | null;
  /** @format int32 */
  order?: number | null;
  /** @format int32 */
  overallRank?: number | null;
  personSport?: PersonSport;
  /** @format int64 */
  personSportKey: number;
  personSportOrganization?: PersonSportOrganization;
  /** @format int64 */
  personSportOrganizationKey?: number | null;
  position?: Position;
  /** @format int32 */
  positionKey?: number | null;
  /** @format int32 */
  positionRank?: number | null;
  ranking?: Ranking;
  /** @format int32 */
  rankingKey: number;
  /** @format double */
  rating?: number | null;
  recruitment?: Recruitment;
  /** @format int64 */
  recruitmentKey?: number | null;
  /** @format int32 */
  rivalsLastUpdated?: number | null;
  /** @format int32 */
  rivalsOverallRank?: number | null;
  /** @format int32 */
  rivalsPositionRank?: number | null;
  /** @format double */
  rivalsRating?: number | null;
  /** @format int32 */
  rivalsStars?: number | null;
  /** @format int32 */
  rivalsStateRank?: number | null;
  /** @format int32 */
  stars?: number | null;
  state?: State;
  /** @format int32 */
  stateKey?: number | null;
  /** @format int32 */
  stateRank?: number | null;
  /** @format int32 */
  tfsSportsLastUpdated?: number | null;
  /** @format int32 */
  tfsSportsOverallRank?: number | null;
  /** @format int32 */
  tfsSportsPositionRank?: number | null;
  /** @format double */
  tfsSportsRating?: number | null;
  /** @format int32 */
  tfsSportsStateRank?: number | null;
}

export interface PersonSportRankingHistory {
  /** @format double */
  averageRank?: number | null;
  /** @format int32 */
  consensusOverallRank?: number | null;
  /** @format int32 */
  consensusPositionRank?: number | null;
  /** @format double */
  consensusRating?: number | null;
  /** @format int32 */
  consensusStars: number;
  /** @format int32 */
  consensusStateRank?: number | null;
  /** @format int32 */
  consensusStrength?: number | null;
  /** @format int32 */
  dateModified: number;
  /** @format int32 */
  espnLastUpdated?: number | null;
  /** @format int32 */
  espnOverallRank?: number | null;
  /** @format int32 */
  espnPositionRank?: number | null;
  /** @format double */
  espnRating?: number | null;
  /** @format int32 */
  espnStars?: number | null;
  /** @format int32 */
  espnStateRank?: number | null;
  fiveStarPlus: boolean;
  /** @format int64 */
  key: number;
  nearlyFiveStarPlus: boolean;
  /** @format double */
  normalizedEspnOverallRank?: number | null;
  /** @format double */
  normalizedEspnRating?: number | null;
  /** @format double */
  normalizedOverallRank?: number | null;
  /** @format double */
  normalizedRivalsOverallRank?: number | null;
  /** @format double */
  normalizedRivalsRating?: number | null;
  /** @format double */
  normalizedTfsSportsOverallRank?: number | null;
  /** @format double */
  normalizedTfsSportsRating?: number | null;
  /** @format int32 */
  order?: number | null;
  /** @format int32 */
  overallRank?: number | null;
  personSport?: PersonSport;
  /** @format int64 */
  personSportKey: number;
  personSportOrganization?: PersonSportOrganization;
  /** @format int64 */
  personSportOrganizationKey?: number | null;
  position?: Position;
  /** @format int32 */
  positionKey?: number | null;
  /** @format int32 */
  positionRank?: number | null;
  ranking?: Ranking;
  /** @format int32 */
  rankingKey: number;
  /** @format double */
  rating?: number | null;
  recruitment?: Recruitment;
  /** @format int64 */
  recruitmentKey?: number | null;
  /** @format int32 */
  rivalsLastUpdated?: number | null;
  /** @format int32 */
  rivalsOverallRank?: number | null;
  /** @format int32 */
  rivalsPositionRank?: number | null;
  /** @format double */
  rivalsRating?: number | null;
  /** @format int32 */
  rivalsStars?: number | null;
  /** @format int32 */
  rivalsStateRank?: number | null;
  /** @format int32 */
  stars?: number | null;
  state?: State;
  /** @format int32 */
  stateKey?: number | null;
  /** @format int32 */
  stateRank?: number | null;
  /** @format int32 */
  tfsSportsLastUpdated?: number | null;
  /** @format int32 */
  tfsSportsOverallRank?: number | null;
  /** @format int32 */
  tfsSportsPositionRank?: number | null;
  /** @format double */
  tfsSportsRating?: number | null;
  /** @format int32 */
  tfsSportsStateRank?: number | null;
  /** @format int32 */
  version: number;
}

export interface PersonSportResponseRecord {
  /** @format int64 */
  currentPersonSportRankingKey?: number | null;
  /** @format int64 */
  currentRosterPersonSportRankingKey?: number | null;
  defaultSport: boolean;
  /** @format int64 */
  key: number;
  /** @format int64 */
  personKey: number;
  sport?: SportResponseRecord;
  /** @format int32 */
  sportKey: number;
}

export interface PersonSportStat {
  /** @format int32 */
  dateAdded: number;
  /** @format int64 */
  key: number;
  personSportOrganization?: PersonSportOrganization;
  /** @format int64 */
  personSportOrganizationKey: number;
  stat?: Stat;
  /** @format int32 */
  statKey: number;
  /** @format double */
  value?: number | null;
  /** @format int32 */
  year: number;
}

export interface PersonStatus {
  /** @format int64 */
  key: number;
  person?: Person;
  /** @format int64 */
  personKey: number;
  reviewStatus: PersonStatusReviewStatus;
  tier: PersonStatusTier;
  visibility: PersonStatusVisibility;
}

export enum PersonStatusReviewStatus {
  Unreviewed = 'Unreviewed',
  Reviewed = 'Reviewed',
}

export enum PersonStatusTier {
  Base = 'Base',
  Emerging = 'Emerging',
  Star = 'Star',
  Unknown = 'Unknown',
  Elite = 'Elite',
}

export enum PersonStatusVisibility {
  None = 'None',
  PublicWithArticleTaggingDisabled = 'PublicWithArticleTaggingDisabled',
  PublicWithArticleTaggingEnabled = 'PublicWithArticleTaggingEnabled',
}

export interface PlanForumGroup {
  allSites: boolean;
  /** @format int64 */
  key: number;
  plan?: MembershipPlan;
  /** @format int64 */
  planKey: number;
  userGroup?: XfUserGroup;
  /** @format int32 */
  userGroupKey: number;
}

export enum PlanType {
  Any = 'Any',
  Monthly = 'Monthly',
  Annual = 'Annual',
  Upgrade = 'Upgrade',
  Save = 'Save',
}

export interface PlayerRatingAllResponse {
  change?: Record<string, PlayerRatingChange>;
  /** @format int32 */
  classYear?: number | null;
  fiveStarPlus: boolean;
  link?: string | null;
  nearlyFiveStarPlus: boolean;
  /** @format int32 */
  overallRank?: number | null;
  positionAbbr?: string | null;
  /** @format int32 */
  positionRank?: number | null;
  /** @format int32 */
  rankingKey?: number | null;
  rankingType: RankingType;
  /** @format int32 */
  rankingYear: number;
  /** @format double */
  rating?: number | null;
  sport?: string | null;
  /** @format int32 */
  stars?: number | null;
  stateAbbr?: string | null;
  /** @format int32 */
  stateRank?: number | null;
  type?: string;
}

export enum PlayerRatingChange {
  Decrease = 'Decrease',
  None = 'None',
  Increase = 'Increase',
}

export interface PointSpreadInfo {
  /** @format int32 */
  americanAwayOdds?: number | null;
  /** @format int32 */
  americanHomeOdds?: number | null;
  /** @format double */
  awayHandicap?: number | null;
  /** @format double */
  homeHandicap?: number | null;
}

export enum PopulationDiscriminator {
  Country = 'Country',
  State = 'State',
  City = 'City',
}

export interface Position {
  /**
   * @minLength 0
   * @maxLength 8
   */
  abbreviation?: string | null;
  /** @format int32 */
  key: number;
  /**
   * @minLength 0
   * @maxLength 100
   */
  name: string;
  nilDealPositions?: NilDealPosition[] | null;
  positionType: PositionType;
  sport?: Sport;
  /** @format int32 */
  sportKey?: number | null;
}

export enum PositionType {
  Player = 'Player',
  Coach = 'Coach',
  Operations = 'Operations',
}

export interface Post {
  author?: WpUser;
  /** @format int64 */
  authorKey: number;
  /** @minLength 1 */
  body: string;
  /**
   * @minLength 0
   * @maxLength 400
   */
  fullPath?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  guid?: string;
  /** @format int64 */
  key: number;
  metas?: PostMeta[];
  /** @format date-time */
  modifiedDate: string;
  /** @format date-time */
  modifiedDateGMT: string;
  notificationLogs?: ArticleNotificationLog[];
  /** @format date-time */
  postDate: string;
  /** @format date-time */
  postDateGMT: string;
  postTerms?: PostTerm[];
  /**
   * @minLength 0
   * @maxLength 200
   */
  slug?: string | null;
  /**
   * One of the following:
   * <list type="bullet"><item><term>auto-draft</term></item><item><term>draft</term></item><item><term>future</term></item><item><term>inherit</term></item><item><term>pending</term></item><item><term>post_status</term></item><item><term>private</term></item><item><term>publish</term></item><item><term>trash</term></item></list>
   *
   * <b>Note:</b> Statuses can be added/removed based on plugins and plugin versions.
   * @minLength 0
   * @maxLength 20
   */
  status?: string | null;
  termRelationships?: WpTermRelationship[];
  /**
   * @minLength 0
   * @maxLength 255
   */
  title?: string | null;
  /**
   * One of the following:
   * <list type="bullet"><item><term>acf-field</term></item><item><term>acf-field-group</term></item><item><term>adstxt</term></item><item><term>app-adstxt</term></item><item><term>apto_sort</term></item><item><term>attachment</term></item><item><term>memberpresscoupon</term></item><item><term>memberpressgroup</term></item><item><term>memberpressproduct</term></item><item><term>mp-reminder</term></item><item><term>mt_pp</term></item><item><term>nav_menu_item</term></item><item><term>oembed_cache</term></item><item><term>page</term></item><item><term>post</term></item><item><term>revision</term></item><item><term>wp_block</term></item><item><term>wp_global_styles</term></item><item><term>wp_navigation</term></item></list>
   *
   * <b>Note:</b> Types can be added/removed based on plugins and plugin versions.
   * @minLength 0
   * @maxLength 20
   */
  type?: string | null;
}

export interface PostMeta {
  /** @format int64 */
  key: number;
  /** @maxLength 255 */
  metaKey?: string | null;
  metaValue?: string | null;
  post?: Post;
  /** @format int64 */
  postKey: number;
}

export interface PostTerm {
  attachment?: Attachment;
  headline?: Headline;
  membershipCoupon?: MembershipCoupon;
  membershipPlan?: MembershipPlan;
  /** @format int32 */
  order: number;
  page?: Page;
  /** @format int64 */
  postKey: number;
  /** @format int64 */
  taxonomyKey: number;
  term?: Term;
  termRelationship?: WpTermRelationship;
}

export enum ProfileType {
  Personal = 'Personal',
  Professional = 'Professional',
  Business = 'Business',
}

export interface Promotion {
  /**
   * @minLength 0
   * @maxLength 500
   */
  text1?: string;
  /**
   * @minLength 0
   * @maxLength 500
   */
  text2?: string;
  /**
   * @minLength 0
   * @maxLength 500
   */
  text3?: string;
  active: boolean;
  /** @format double */
  actualPrice: number;
  /**
   * @minLength 0
   * @maxLength 50
   */
  buttonText?: string;
  /** @format int32 */
  buyCount?: number | null;
  default: boolean;
  /**
   * @minLength 0
   * @maxLength 255
   */
  description?: string;
  /**
   * @minLength 0
   * @maxLength 255
   */
  disclaimer?: string;
  /** @format double */
  fixedPrice?: number | null;
  /** @format int32 */
  freeDays?: number | null;
  /** @format date-time */
  freeUntilDate?: string | null;
  /** @format int32 */
  getCount?: number | null;
  /** @format int32 */
  key: number;
  /**
   * @minLength 0
   * @maxLength 50
   */
  name: string;
  newSitePlans?: SitePlan[];
  /** @format int32 */
  percentOff?: number | null;
  planType: PlanType;
  promotionType: PromotionType;
  requireCoolDown: boolean;
  saveSitePlans?: SitePlan[];
  stripeCoupon?: string | null;
  upgradeSitePlans?: SitePlan[];
}

export enum PromotionType {
  FreeTrial = 'FreeTrial',
  FreeUntil = 'FreeUntil',
  BuyXGetY = 'BuyXGetY',
  PercentOff = 'PercentOff',
  FixedPrice = 'FixedPrice',
}

export interface RankInformationResponse {
  /** @format int32 */
  associatedPressRanking?: number | null;
  /** @format int32 */
  coachesPollRanking?: number | null;
  conferenceAbbreviation?: string | null;
  conferenceRecord?: string | null;
  /** @format int32 */
  nationalRank?: number | null;
  record?: string | null;
  /** @format int32 */
  stateRank?: number | null;
}

export interface Ranking {
  /** @format double */
  on3Percent?: number | null;
  autoPublish: boolean;
  /** @format int32 */
  averageCommits: number;
  createVersion: boolean;
  /** @format int32 */
  dateCreated: number;
  /** @format int32 */
  dateScheduled?: number | null;
  /** @format int32 */
  dateUpdated?: number | null;
  /**
   * @format int32
   * @min 0
   * @max 10
   */
  draftRankStrength: number;
  /** @format double */
  espnPercent?: number | null;
  isLocked: boolean;
  /** @format int32 */
  key: number;
  organizationRankings?: OrganizationRanking[];
  organizationType?: OrganizationType;
  /**
   * @format int32
   * @min 0
   * @max 10
   */
  rankStrength: number;
  /** @format double */
  rivalsPercent?: number | null;
  sport?: Sport;
  /** @format int32 */
  sportKey: number;
  staleness: RankingStaleness;
  status: RankingStatus;
  teamTransferPortalRankingDrafts?: TeamTransferPortalRankingDraft[];
  teamTransferPortalRankings?: TeamTransferPortalRanking[];
  /** @format double */
  tfsSportsPercent?: number | null;
  type: RankingType;
  /** @format int32 */
  year: number;
}

export enum RankingStaleness {
  None = 'None',
  On300 = 'On300',
  Consensus = 'Consensus',
}

export enum RankingStatus {
  Draft = 'Draft',
  Processing = 'Processing',
  Ready = 'Ready',
  Scheduled = 'Scheduled',
  Publishing = 'Publishing',
  Published = 'Published',
}

export enum RankingTrend {
  NoChange = 'NoChange',
  Up = 'Up',
  Down = 'Down',
}

export enum RankingType {
  Player = 'Player',
  AllTimePlayer = 'AllTimePlayer',
  TransferPortal = 'TransferPortal',
  AllTimeTransferPortal = 'AllTimeTransferPortal',
  Draft = 'Draft',
  AllTimeDraft = 'AllTimeDraft',
  Organization = 'Organization',
  AllTimeOrganization = 'AllTimeOrganization',
  Roster = 'Roster',
  AllTimeRoster = 'AllTimeRoster',
  TeamTransferPortal = 'TeamTransferPortal',
  AllTimeTeamTransferPortal = 'AllTimeTeamTransferPortal',
  TeamComposite = 'TeamComposite',
  AllTimeTeamComposite = 'AllTimeTeamComposite',
}

export interface RatingResponse {
  /** @format int32 */
  consensusNationalRank?: number | null;
  /** @format int32 */
  consensusPositionRank?: number | null;
  /** @format double */
  consensusRating?: number | null;
  /** @format int32 */
  consensusStars?: number | null;
  /** @format int32 */
  consensusStateRank?: number | null;
  fiveStarPlus: boolean;
  /** @format int64 */
  key: number;
  /** @format int32 */
  nationalRank?: number | null;
  nearlyFiveStarPlus: boolean;
  positionAbbr?: string | null;
  /** @format int32 */
  positionRank?: number | null;
  /** @format double */
  rating?: number | null;
  sport?: AttributeResponse;
  /** @format int32 */
  stars?: number | null;
  stateAbbr?: string | null;
  /** @format int32 */
  stateRank?: number | null;
  /** @format int32 */
  year?: number | null;
}

export interface Recruitment {
  /** @format date-time */
  announcementDate?: string | null;
  committedHeadline?: Headline;
  /** @format int64 */
  committedHeadlineKey?: number | null;
  draft: boolean;
  draftPick?: DraftPick;
  earlyEnrollee: boolean;
  earlySignee: boolean;
  enteredHeadline?: Headline;
  /** @format int64 */
  enteredHeadlineKey?: number | null;
  exitedHeadline?: Headline;
  /** @format int64 */
  exitedHeadlineKey?: number | null;
  /** @format int32 */
  interestCount: number;
  /** @format int64 */
  key: number;
  maxInterestLevel?: InterestLevel;
  oracleLocked: boolean;
  organization?: Organization;
  /** @format int32 */
  organizationKey?: number | null;
  personSport?: PersonSport;
  /** @format int64 */
  personSportKey: number;
  personSportOrganization?: PersonSportOrganization;
  /** @format int64 */
  personSportOrganizationKey?: number | null;
  personSportRankingDrafts?: PersonSportRankingDraft[];
  personSportRankings?: PersonSportRanking[];
  primaryInterest?: RecruitmentInterest;
  /** @format int64 */
  primaryInterestKey?: number | null;
  recruitmentInterests?: RecruitmentInterest[];
  recruitmentOracles?: RecruitmentOracle[];
  recruitmentPredictions?: RpmOrganizationSummary[];
  rpmOne?: RpmOrganizationSummary;
  /** @format int64 */
  rpmOneKey?: number | null;
  rpmTwo?: RpmOrganizationSummary;
  /** @format int64 */
  rpmTwoKey?: number | null;
  shortTermSignee: boolean;
  showRpmPercentage?: boolean | null;
  status: RecruitmentStatus;
  transfer: boolean;
  /** @format int32 */
  year: number;
}

export interface RecruitmentBreakdownSummary {
  /** @format int32 */
  fiveStars: number;
  /** @format int32 */
  fourStars: number;
  /** @format int64 */
  key: number;
  sport?: Sport;
  /** @format int32 */
  sportKey: number;
  /** @format int32 */
  threeStars: number;
  /** @format int32 */
  year: number;
  /** @format int32 */
  zeroStars: number;
}

export interface RecruitmentInterest {
  coachingVisits?: RecruitmentInterestCoachVisit[];
  collectiveTierValuation?: CollectiveTierValuation;
  events?: RecruitmentInterestEvent[];
  /** @format int64 */
  key: number;
  latestCommitEvent?: RecruitmentInterestEvent;
  /** @format int64 */
  latestCommitEventKey?: number | null;
  latestEvent?: RecruitmentInterestEvent;
  /**
   * Latest On3.DataAccess.Models.Rdb.RecruitmentInterestEvent that has a On3.DataAccess.Enums.RecruitmentStatus other than None
   * @format int64
   */
  latestEventKey?: number | null;
  offer: boolean;
  organization?: Organization;
  organizationInterest: InterestLevel;
  /** @format int32 */
  organizationInterestLevel?: number | null;
  /** @format int32 */
  organizationKey: number;
  personSport?: PersonSport;
  /** @format int64 */
  personSportKey: number;
  position?: Position;
  /** @format int32 */
  positionKey: number;
  primaryPerson?: Person;
  /** @format int64 */
  primaryPersonKey?: number | null;
  recruitment?: Recruitment;
  /** @format int64 */
  recruitmentKey: number;
  secondaryPerson?: Person;
  /** @format int64 */
  secondaryPersonKey?: number | null;
  status: RecruitmentStatus;
  target: boolean;
  transferredIn: boolean;
  transferredOut: boolean;
  visits?: RecruitmentInterestVisit[];
  /** @format int32 */
  year: number;
}

export interface RecruitmentInterestCoachVisit {
  coaches?: CoachVisited[];
  /** @format int64 */
  dateOccurred: number;
  /** @format int64 */
  key: number;
  recruitmentInterest?: RecruitmentInterest;
  /** @format int64 */
  recruitmentInterestKey: number;
}

export interface RecruitmentInterestEvent {
  /** @format int32 */
  dateAdded: number;
  /** @format date-time */
  dateOccurred?: string | null;
  /** @format int64 */
  key: number;
  recruitmentInterest?: RecruitmentInterest;
  /** @format int64 */
  recruitmentInterestKey: number;
  type: RecruitmentStatus;
}

export interface RecruitmentInterestVisit {
  /** @format date-time */
  dateAdded: string;
  /** @format date-time */
  dateOccurred: string;
  /** @format int64 */
  key: number;
  official: boolean;
  recruitmentInterest?: RecruitmentInterest;
  /** @format int64 */
  recruitmentInterestKey: number;
}

export interface RecruitmentOracle {
  /**
   * @minLength 0
   * @maxLength 500
   */
  articleLink?: string | null;
  /**
   * @format double
   * @min 0
   * @max 100
   */
  confidence?: number | null;
  correct?: boolean | null;
  /** @format int32 */
  dateAdded: number;
  /**
   * @format double
   * @min -999999.99
   * @max 999999.99
   */
  daysCorrect?: number | null;
  flippedFromOrganization?: Organization;
  /** @format int32 */
  flippedFromOrganizationKey?: number | null;
  hidden: boolean;
  /** @format int64 */
  key: number;
  organization?: Organization;
  /** @format int32 */
  organizationKey: number;
  premium: boolean;
  previousRecruitmentOracleHistory?: RecruitmentOracleHistory;
  /** @format int64 */
  previousRecruitmentOracleHistoryKey?: number | null;
  recruitment?: Recruitment;
  /** @format int64 */
  recruitmentKey: number;
  /**
   * @format double
   * @min 0
   * @max 999999
   */
  score?: number | null;
  status: OracleStatus;
  user?: WpUser;
  /** @format int64 */
  userKey: number;
}

export interface RecruitmentOracleHistory {
  /**
   * @minLength 0
   * @maxLength 500
   */
  articleLink?: string | null;
  /**
   * @format double
   * @min 0
   * @max 100
   */
  confidence?: number | null;
  correct?: boolean | null;
  /** @format int32 */
  dateAdded: number;
  /**
   * @format double
   * @min -999999.99
   * @max 999999.99
   */
  daysCorrect?: number | null;
  /** @format int64 */
  flippedFromOrganizationKey?: number | null;
  hidden: boolean;
  /** @format int64 */
  key: number;
  organization?: Organization;
  /** @format int32 */
  organizationKey: number;
  premium: boolean;
  recruitment?: Recruitment;
  /** @format int64 */
  recruitmentKey: number;
  /**
   * @format double
   * @min 0
   * @max 999999
   */
  score?: number | null;
  status: OracleStatus;
  /** @format int64 */
  userKey: number;
}

export enum RecruitmentStatus {
  None = 'None',
  Decommitted = 'Decommitted',
  Committed = 'Committed',
  Signed = 'Signed',
  Enrolled = 'Enrolled',
}

export interface RelatedScheduleResponse {
  /** @format int32 */
  orgKey: number;
  organization?: OrganizationResponse;
  sport?: SportResponseRecord;
  /** @format int32 */
  sportKey: number;
  /** @format int32 */
  year: number;
}

export interface RelatedTeamRankingResponse {
  childClassifications?: OrgTypeClassHierarchyRes[] | null;
  /** @format int32 */
  conferenceKey?: number | null;
  currentClassification?: OrgTypeClassHierarchyRes;
  filter?: string | null;
  /** @format int64 */
  lastUpdated?: number | null;
  /** @format int32 */
  orgTypeClassHierarchyKey?: number | null;
  organizationType: OrganizationType;
  siblingClassifications?: OrgTypeClassHierarchyRes[] | null;
  /** @format int32 */
  sportKey?: number | null;
  /** @format int32 */
  stateKey?: number | null;
  /** @format int32 */
  totalResults: number;
  /** @format int32 */
  year?: number | null;
}

export interface RenGameReq {
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  awayOrgKey?: number | null;
  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  awayScore?: number | null;
  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  awayScorePrediction?: number | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  city?: string | null;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  currentAwayTeamRank?: number | null;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  currentHomeTeamRank?: number | null;
  /** @format date */
  endDate?: string | null;
  /** @format time */
  endTimeCentral?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  gameBroadcast?: string | null;
  gameType: GameType;
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  homeOrgKey?: number | null;
  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  homeScore?: number | null;
  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  homeScorePrediction?: number | null;
  /**
   * @format int64
   * @min 1
   */
  key?: number | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  location?: string | null;
  /**
   * @format int64
   * @min 1
   */
  seasonPhaseKey?: number | null;
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  sportKey: number;
  /** @format date */
  startDate?: string | null;
  /** @format time */
  startTimeCentral?: string | null;
  startTimeTbd: boolean;
  /**
   * @minLength 0
   * @maxLength 255
   */
  state?: string | null;
  status?: string | null;
  statusEnum: GameStatuses;
  /**
   * @minLength 0
   * @maxLength 500
   */
  ticketLink?: string | null;
  /**
   * @format int32
   * @min 1800
   * @max 3000
   */
  year: number;
}

export interface ResponseMessage {
  message?: string;
  /** @format int32 */
  statusCode: number;
}

export interface RpmOrganizationSummary {
  boost: boolean;
  /** @format date-time */
  dateCalculated?: string | null;
  /** @format double */
  distance?: number | null;
  /** @format int64 */
  key: number;
  organization?: Organization;
  /** @format int32 */
  organizationKey: number;
  /** @format double */
  percent?: number | null;
  previousRpmOrganizationSummaryHistory?: RpmOrganizationSummaryHistory;
  /** @format int64 */
  previousRpmOrganizationSummaryHistoryKey?: number | null;
  /** @format int32 */
  rank?: number | null;
  recruitment?: Recruitment;
  /** @format int64 */
  recruitmentKey: number;
  /** @format double */
  version?: number | null;
}

export interface RpmOrganizationSummaryHistory {
  boost: boolean;
  /** @format date-time */
  dateCalculated?: string | null;
  /** @format int64 */
  key: number;
  organization?: Organization;
  /** @format int32 */
  organizationKey: number;
  /** @format double */
  percent?: number | null;
  previousRpmOrganizationSummaryHistory?: RpmOrganizationSummaryHistory;
  /** @format int64 */
  previousRpmOrganizationSummaryHistoryKey?: number | null;
  /** @format int32 */
  rank?: number | null;
  recruitment?: Recruitment;
  /** @format int64 */
  recruitmentKey: number;
}

export interface ScoreboardResponse {
  activeGameStatus?: ActiveGameStatusResponse;
  awayRankingInformation?: RankInformationResponse;
  /** @format int32 */
  awayTeamOrgKey?: number | null;
  awayTeamOrganization?: OrganizationResponse;
  /** @format int32 */
  awayTeamScore?: number | null;
  /** @format int32 */
  awayTeamScorePrediction?: number | null;
  /** @format double */
  awayTeamWinPercentage?: number | null;
  /** @format double */
  awayTeamWinProbability?: number | null;
  broadcast?: string | null;
  city?: string | null;
  currentOrgIsHome?: boolean | null;
  currentOrgIsWinner?: boolean | null;
  /** @format int32 */
  currentOrgKey?: number | null;
  currentOrganization?: OrganizationResponse;
  currentOrganizationRankInformation?: RankInformationResponse;
  forecast?: WeatherInformationResponse;
  homeRankingInformation?: RankInformationResponse;
  /** @format int32 */
  homeTeamOrgKey?: number | null;
  homeTeamOrganization?: OrganizationResponse;
  /** @format int32 */
  homeTeamScore?: number | null;
  /** @format int32 */
  homeTeamScorePrediction?: number | null;
  /** @format double */
  homeTeamWinPercentage?: number | null;
  /** @format double */
  homeTeamWinProbability?: number | null;
  isFinal: boolean;
  /** @format int64 */
  key: number;
  location?: string | null;
  odds?: GameOddsResponse;
  opponentOrganization?: OrganizationResponse;
  opponentOrganizationRankInformation?: RankInformationResponse;
  overUnderResult?: string | null;
  seasonPhase?: SeasonPhaseResponse;
  spreadPrediction?: string | null;
  spreadResult?: string | null;
  startDate?: string;
  /** @format int64 */
  startDateUtc?: number | null;
  startTime?: string;
  startTimeTbd: boolean;
  state?: string | null;
  status?: string | null;
  statusEnum: GameStatuses;
  ticketLink?: string | null;
  winner?: OrganizationResponse;
  /** @format int32 */
  winnerOrgKey?: number | null;
}

export interface ScoreboardResponseRelatedScheduleResponseRelatedPagedData {
  list?: ScoreboardResponse[];
  pagination?: Pagination;
  relatedModel?: RelatedScheduleResponse;
}

export enum ScrapeErrorType {
  Unknown = 'Unknown',
  RateLimited = 'RateLimited',
  MissingResource = 'MissingResource',
  PrivateResource = 'PrivateResource',
  InvalidResource = 'InvalidResource',
  SuspendedResource = 'SuspendedResource',
}

export interface SeasonPhase {
  /** @format date */
  endDate?: string | null;
  /** @format int32 */
  gameCount: number;
  /** @format int64 */
  key: number;
  leagueSeason?: LeagueSeason;
  /** @format int64 */
  leagueSeasonKey: number;
  /**
   * @minLength 0
   * @maxLength 50
   */
  name: string;
  /** @format int32 */
  number?: number | null;
  parentSeasonPhase?: SeasonPhase;
  /** @format int64 */
  parentSeasonPhaseKey?: number | null;
  phaseType: SeasonPhaseType;
  /** @format date */
  startDate?: string | null;
}

export interface SeasonPhaseResponse {
  /** @format date */
  endDate?: string | null;
  /** @format int64 */
  key: number;
  /** @format int64 */
  leagueSeasonKey: number;
  name?: string;
  /** @format int32 */
  number?: number | null;
  /** @format int64 */
  parentSeasonPhaseKey?: number | null;
  phaseType: SeasonPhaseType;
  /** @format date */
  startDate?: string | null;
}

export enum SeasonPhaseType {
  Unknown = 'Unknown',
  Round = 'Round',
  MatchRound = 'MatchRound',
}

export interface Show {
  appleMeta?: AppleShowMeta;
  asset?: Asset;
  /** @format int64 */
  assetKey?: number | null;
  description?: string | null;
  episodes?: Episode[];
  featured: boolean;
  hosts?: number[] | null;
  jwPlayerMeta?: JwPlayerShowMeta;
  /** @format int64 */
  key: number;
  megaphoneMeta?: MegaphoneShowMeta;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name?: string;
  national: boolean;
  /** @format int32 */
  order: number;
  simplecastMeta?: SimplecastShowMeta;
  site?: Site;
  /** @format int32 */
  siteKey: number;
  spotifyMeta?: SpotifyShowMeta;
  youTubeMeta?: YouTubeShowMeta;
}

export interface SimplecastShowMeta {
  /** @format date-time */
  lastUpdated?: string | null;
  podcastId?: string | null;
}

export interface Site {
  /**
   * @minLength 0
   * @maxLength 25
   */
  gA4PropertyId?: string | null;
  allowSubs: boolean;
  alternateAvatar?: Asset;
  /** @format int64 */
  alternateAvatarKey?: number | null;
  alternateLogo?: Asset;
  /** @format int64 */
  alternateLogoKey?: number | null;
  /** @format int64 */
  categoryKey?: number | null;
  /**
   * @minLength 0
   * @maxLength 100
   */
  connatixId?: string | null;
  /**
   * @minLength 0
   * @maxLength 100
   */
  connatixPlayerId?: string | null;
  defaultAsset?: Asset;
  /** @format int64 */
  defaultAssetKey?: number | null;
  defaultAvatar?: Asset;
  /** @format int64 */
  defaultAvatarKey?: number | null;
  defaultTrendingList?: TrendingList;
  /** @format int64 */
  defaultTrendingListKey?: number | null;
  detail?: SiteDetail;
  /**
   * @minLength 0
   * @maxLength 255
   */
  facebookProfile?: string | null;
  forum?: XfNode;
  /** @format int32 */
  forumKey?: number | null;
  /**
   * @minLength 0
   * @maxLength 25
   */
  gTagId?: string | null;
  /**
   * @minLength 0
   * @maxLength 25
   */
  googleAnalyticsProjectId?: string | null;
  inIndex: boolean;
  /**
   * @minLength 0
   * @maxLength 255
   */
  instagramProfile?: string | null;
  jwPlaylistId?: string | null;
  /** @format int32 */
  key: number;
  keyPersonKeys?: number[] | null;
  /**
   * @minLength 0
   * @maxLength 10
   */
  linkColor?: string | null;
  live: boolean;
  /** @format int32 */
  menuKey?: number | null;
  metrics?: SiteMetric[];
  mobilePlans?: MobilePlan[];
  /**
   * @minLength 0
   * @maxLength 100
   */
  name?: string | null;
  /** @format int32 */
  newsletterListId?: number | null;
  organization?: Organization;
  /** @format int32 */
  organizationKey?: number | null;
  plans?: SitePlan[];
  /**
   * @minLength 0
   * @maxLength 10
   */
  primaryColor?: string | null;
  primaryForum?: XfNode;
  /** @format int32 */
  primaryForumId?: number | null;
  resourceId?: string | null;
  /**
   * @minLength 0
   * @maxLength 10
   */
  secondaryColor?: string | null;
  shows?: Show[];
  siblingSite?: Site;
  /** @format int32 */
  siblingSiteKey?: number | null;
  siteCategories?: SiteCategory[];
  /**
   * @minLength 0
   * @maxLength 100
   */
  slug?: string | null;
  subscribeText?: string | null;
  subscriptionConfig?: SiteSubscriptionConfig;
  /**
   * @minLength 0
   * @maxLength 1000
   */
  tagLine?: string | null;
  template: SiteTemplate;
  templateId?: string | null;
  /**
   * @minLength 0
   * @maxLength 10
   */
  tertiaryColor?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  twitterHandle?: string | null;
  type: SiteType;
  /**
   * @minLength 0
   * @maxLength 100
   */
  url?: string | null;
  wpTerm?: WpTerm;
  /**
   * @minLength 0
   * @maxLength 100
   */
  youTubeChannelId?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  youtubeProfile?: string | null;
}

export interface SiteCategory {
  category?: Term;
  /** @format int64 */
  categoryKey: number;
  categoryType: CategoryTypes;
  /** @format int32 */
  key: number;
  site?: Site;
  /** @format int32 */
  siteKey: number;
  sport?: Sport;
  /** @format int32 */
  sportKey?: number | null;
  /**
   * @minLength 0
   * @maxLength 100
   */
  url: string;
}

export interface SiteDetail {
  forumsAssetOne?: Asset;
  /** @format int64 */
  forumsAssetOneKey?: number | null;
  forumsAssetTwo?: Asset;
  /** @format int64 */
  forumsAssetTwoKey?: number | null;
  /**
   * @minLength 0
   * @maxLength 2000
   */
  forumsDescription?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  forumsTitle?: string | null;
  genericAssetOne?: Asset;
  /** @format int64 */
  genericAssetOneKey?: number | null;
  /**
   * @minLength 0
   * @maxLength 2000
   */
  genericDescription?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  genericTitle?: string | null;
  /** @format int64 */
  key: number;
  site?: Site;
  siteBulletPoints?: string[] | null;
  /** @format int32 */
  siteKey: number;
}

export interface SiteMetric {
  daily: boolean;
  /** @format date-time */
  date?: string | null;
  /** @format double */
  editedValue?: number | null;
  /** @format double */
  goalValue?: number | null;
  /** @format int64 */
  key: number;
  metric?: Metric;
  /** @format int64 */
  metricKey: number;
  /** @format double */
  originalValue?: number | null;
  reason?: string | null;
  site?: Site;
  /** @format int32 */
  siteKey: number;
}

export interface SitePlan {
  featured: boolean;
  /** @format int32 */
  key: number;
  newPromotion?: Promotion;
  /** @format int32 */
  newPromotionKey?: number | null;
  plan?: MembershipPlan;
  /** @format int64 */
  planKey: number;
  savePromotion?: Promotion;
  /** @format int32 */
  savePromotionKey?: number | null;
  site?: Site;
  /** @format int32 */
  siteKey: number;
  upgradePromotion?: Promotion;
  /** @format int32 */
  upgradePromotionKey?: number | null;
}

export interface SiteSubscriptionConfig {
  /**
   * @minLength 0
   * @maxLength 100
   */
  annualArticleTemplateVariantId?: string | null;
  /**
   * @minLength 0
   * @maxLength 100
   */
  annualJoinTemplateVariantId?: string | null;
  /**
   * @minLength 0
   * @maxLength 100
   */
  annualOffer?: string | null;
  /**
   * @minLength 0
   * @maxLength 100
   */
  annualPromotionCode?: string | null;
  /**
   * @minLength 0
   * @maxLength 100
   */
  articleTemplateId?: string | null;
  /**
   * @minLength 0
   * @maxLength 100
   */
  joinTemplateId?: string | null;
  /** @format int64 */
  key: number;
  /**
   * @minLength 0
   * @maxLength 100
   */
  monthlyArticleTemplateVariantId?: string | null;
  /**
   * @minLength 0
   * @maxLength 100
   */
  monthlyJoinTemplateVariantId?: string | null;
  /**
   * @minLength 0
   * @maxLength 100
   */
  monthlyOffer?: string | null;
  /**
   * @minLength 0
   * @maxLength 100
   */
  monthlyPromotionCode?: string | null;
  site?: Site;
  /** @format int32 */
  siteKey: number;
}

export enum SiteTemplate {
  Feed = 'Feed',
  Traditional = 'Traditional',
}

export enum SiteType {
  National = 'National',
  Channel = 'Channel',
  Team = 'Team',
}

export interface SmallPlayerResponse {
  /** @format int32 */
  classYear?: number | null;
  defaultAsset?: AssetResponse;
  firstName?: string;
  fullName?: string;
  height?: string | null;
  /** @format int64 */
  key: number;
  lastName?: string;
  primaryPosition?: AttributeResponse;
  /** @format int64 */
  recruitmentKey?: number | null;
  slug?: string;
  /** @format double */
  weight?: number | null;
}

export enum SocialMetaStatus {
  NeedsUpdated = 'NeedsUpdated',
  Skip = 'Skip',
  ReadyToScrape = 'ReadyToScrape',
  Scraping = 'Scraping',
  Scraped = 'Scraped',
}

export enum SocialType {
  Twitter = 'Twitter',
  Instagram = 'Instagram',
  TikTok = 'TikTok',
  Facebook = 'Facebook',
  Youtube = 'Youtube',
}

export enum SortMeasurementType {
  Latest = 'Latest',
  Lowest = 'Lowest',
  Highest = 'Highest',
}

export interface Sport {
  /**
   * @minLength 0
   * @maxLength 8
   */
  abbreviation: string;
  active: boolean;
  events?: Event[];
  exposureOverrides?: OrganizationSportExposureOverride[];
  isIndustryRankable: boolean;
  isRankable: boolean;
  /** @format int32 */
  key: number;
  /**
   * @minLength 0
   * @maxLength 45
   */
  name: string;
  nilDealPositions?: NilDealPosition[] | null;
  organizationSports?: OrganizationSport[];
  personSports?: PersonSport[];
  positions?: Position[];
  recruitmentBreakdownSummaries?: RecruitmentBreakdownSummary[] | null;
}

export interface SportCategory {
  /** @format int32 */
  key: number;
  /**
   * @minLength 0
   * @maxLength 45
   */
  name: string;
  sport?: Sport;
  /** @format int32 */
  sportKey: number;
}

export interface SportResponseRecord {
  abbreviation?: string;
  isIndustryRankable: boolean;
  isRankable: boolean;
  /** @format int32 */
  key: number;
  name?: string;
}

export interface SpotifyShowMeta {
  /** @format date-time */
  lastUpdated?: string | null;
  url?: string | null;
}

export interface Stat {
  /**
   * @minLength 0
   * @maxLength 255
   */
  description?: string | null;
  /** @format int32 */
  key: number;
  /**
   * @minLength 0
   * @maxLength 45
   */
  name: string;
  position?: Position;
  /** @format int32 */
  positionKey?: number | null;
  sport?: Sport;
  /** @format int32 */
  sportKey?: number | null;
  type: StatType;
}

export enum StatType {
  Passing = 'Passing',
  Rushing = 'Rushing',
  Receiving = 'Receiving',
  Defensive = 'Defensive',
}

export interface State {
  /**
   * @minLength 0
   * @maxLength 4
   */
  abbreviation: string;
  country?: Country;
  /** @format int32 */
  countryKey?: number | null;
  /** @format int32 */
  key: number;
  /**
   * @minLength 0
   * @maxLength 45
   */
  name: string;
  populations?: StatePopulation[];
}

export interface StatePopulation {
  discriminator: PopulationDiscriminator;
  /** @format int64 */
  key: number;
  state?: State;
  /** @format int32 */
  stateKey: number;
  /** @format int32 */
  value: number;
  /** @format int32 */
  year: number;
}

export interface StateResponseRecord {
  abbreviation?: string;
  /** @format int32 */
  key: number;
  name?: string;
}

export interface StringListResourceResponseMessage {
  message?: string;
  resource?: string[];
  /** @format int32 */
  statusCode: number;
}

export interface StringPersonContactInfoMeta {
  value?: string | null;
  verification?: Verification;
  visibility: Visibility;
}

export interface StringValueVisibility {
  value?: string | null;
  visibility: Visibility;
}

export interface TeamRankingResponse {
  city?: CityResponseRecord;
  /** @format int32 */
  classificationRank?: number | null;
  conferenceResponse?: ConferenceResponse;
  currentOrganization?: OrganizationResponse;
  /** @format int32 */
  defensiveRank?: number | null;
  /** @format double */
  defensiveScore?: number | null;
  /** @format int32 */
  homeFieldAdvantageRank?: number | null;
  /** @format double */
  homeFieldAdvantageScore?: number | null;
  /** @format int32 */
  hrpKey?: number | null;
  /** @format int32 */
  losses: number;
  /** @format int32 */
  nationalRank?: number | null;
  /** @format int32 */
  offensiveRank?: number | null;
  /** @format double */
  offensiveScore?: number | null;
  /** @format int32 */
  orgKey: number;
  /** @format int32 */
  overallRank?: number | null;
  /** @format int32 */
  overallRankChange?: number | null;
  /** @format int32 */
  overallRankPreviousWeek?: number | null;
  /** @format double */
  overallScore?: number | null;
  /** @format int32 */
  powerRank?: number | null;
  /** @format double */
  powerScore?: number | null;
  record?: string | null;
  /** @format int32 */
  strengthOfScheduleRank?: number | null;
  /** @format double */
  strengthOfScheduleScore?: number | null;
  /** @format int32 */
  ties?: number | null;
  /** @format int32 */
  totalGames: number;
  /** @format double */
  winPercentage: number;
  /** @format int32 */
  wins: number;
}

export interface TeamRankingResponseRelatedTeamRankingResponseRelatedPagedData {
  list?: TeamRankingResponse[];
  pagination?: Pagination;
  relatedModel?: RelatedTeamRankingResponse;
}

export interface TeamTransferPortalRanking {
  /** @format double */
  adjustedNilValuation?: number | null;
  /** @format date */
  date: string;
  /** @format int32 */
  dateModified: number;
  /** @format int32 */
  fiveStarsIn?: number | null;
  /** @format int32 */
  fiveStarsNet?: number | null;
  /** @format int32 */
  fiveStarsOut?: number | null;
  /** @format int32 */
  fourStarsIn?: number | null;
  /** @format int32 */
  fourStarsNet?: number | null;
  /** @format int32 */
  fourStarsOut?: number | null;
  /** @format int64 */
  key: number;
  /** @format double */
  nilValuationChange?: number | null;
  organization?: Organization;
  /** @format int32 */
  organizationKey: number;
  /** @format double */
  originalNilValuation?: number | null;
  /** @format int32 */
  overallRank?: number | null;
  /** @format int32 */
  overallScore?: number | null;
  ranking?: Ranking;
  /** @format int32 */
  rankingKey: number;
  /** @format double */
  rawScore?: number | null;
  /** @format double */
  rawScoreIn?: number | null;
  /** @format double */
  rawScoreOut?: number | null;
  /** @format double */
  rosterAverageRating?: number | null;
  /** @format int32 */
  threeStarsIn?: number | null;
  /** @format int32 */
  threeStarsNet?: number | null;
  /** @format int32 */
  threeStarsOut?: number | null;
  /** @format int32 */
  totalIn?: number | null;
  /** @format double */
  totalInAverageRating?: number | null;
  /** @format int32 */
  totalOut?: number | null;
  /** @format double */
  totalOutAverageRating?: number | null;
}

export interface TeamTransferPortalRankingDraft {
  /** @format double */
  adjustedNilValuation?: number | null;
  /** @format date */
  date: string;
  /** @format int32 */
  dateModified: number;
  /** @format int32 */
  fiveStarsIn?: number | null;
  /** @format int32 */
  fiveStarsNet?: number | null;
  /** @format int32 */
  fiveStarsOut?: number | null;
  /** @format int32 */
  fourStarsIn?: number | null;
  /** @format int32 */
  fourStarsNet?: number | null;
  /** @format int32 */
  fourStarsOut?: number | null;
  /** @format int64 */
  key: number;
  /** @format double */
  nilValuationChange?: number | null;
  organization?: Organization;
  /** @format int32 */
  organizationKey: number;
  /** @format double */
  originalNilValuation?: number | null;
  /** @format int32 */
  overallRank?: number | null;
  /** @format int32 */
  overallScore?: number | null;
  ranking?: Ranking;
  /** @format int32 */
  rankingKey: number;
  /** @format double */
  rawScore?: number | null;
  /** @format double */
  rawScoreIn?: number | null;
  /** @format double */
  rawScoreOut?: number | null;
  /** @format double */
  rosterAverageRating?: number | null;
  /** @format int32 */
  threeStarsIn?: number | null;
  /** @format int32 */
  threeStarsNet?: number | null;
  /** @format int32 */
  threeStarsOut?: number | null;
  /** @format int32 */
  totalIn?: number | null;
  /** @format double */
  totalInAverageRating?: number | null;
  /** @format int32 */
  totalOut?: number | null;
  /** @format double */
  totalOutAverageRating?: number | null;
}

export interface Term {
  /** @format int64 */
  count: number;
  headlines?: Headline[];
  /** @format int64 */
  key: number;
  name?: string;
  /** @format int64 */
  parent: number;
  postTerms?: PostTerm[];
  siteCategories?: SiteCategory[];
  slug?: string;
  taxonomy?: string;
  /** @format int64 */
  termKey: number;
}

export interface TrendingList {
  /** @format int64 */
  key: number;
  /**
   * @minLength 0
   * @maxLength 100
   */
  name?: string;
  sites?: Site[];
  trendingListItems?: TrendingListItem[];
}

export interface TrendingListItem {
  /** @format int32 */
  displayOrder: number;
  /**
   * @minLength 0
   * @maxLength 100
   */
  headline?: string;
  /** @format int64 */
  key: number;
  /**
   * @minLength 0
   * @maxLength 100
   */
  subject?: string;
  trendingList?: TrendingList;
  /** @format int64 */
  trendingListKey: number;
  type?: TrendingListItemType;
  /**
   * @minLength 0
   * @maxLength 255
   */
  url?: string;
}

export enum TrendingListItemType {
  Unknown = 'Unknown',
  Breaking = 'Breaking',
  Hot = 'Hot',
  Live = 'Live',
  New = 'New',
  Trending = 'Trending',
}

export interface UofSettings {
  all: boolean;
  articleTag: boolean;
}

export interface UpfSettings {
  all: boolean;
  articleTag: boolean;
  changeInNilValuation: boolean;
  changeInRecruitingRanking: boolean;
}

export interface UploadByCsvResponse {
  /** @format int32 */
  added: number;
  /** @format int32 */
  deleted: number;
  /** @format int32 */
  processed: number;
  /** @format int32 */
  rejected: number;
  /** @format int32 */
  updated: number;
}

export interface UserFeedItem {
  /** @format date-time */
  date: string;
  eliteFeedItem?: EliteFeedItem;
  /** @format int64 */
  eliteFeedItemKey: number;
  /** @format int64 */
  key: number;
  /** @format int32 */
  page: number;
  /** @format int32 */
  pageOrder: number;
  personFollowLinks?: UserFeedItemPersonFollowLink[];
  user?: WpUser;
  /** @format int64 */
  userKey: number;
}

export interface UserFeedItemPersonFollowLink {
  /** @format int64 */
  key: number;
  userFeedItem?: UserFeedItem;
  /** @format int64 */
  userFeedItemKey: number;
  userPersonFollow?: UserPersonFollow;
  /** @format int64 */
  userPersonFollowKey: number;
}

export interface UserNotification {
  /** @format date-time */
  added: string;
  appType: AppTypes;
  details?: string | null;
  /** @format int64 */
  key: number;
  meta?: string | null;
  /** @format int32 */
  notificationType: number;
  read: boolean;
  /** @format int64 */
  relationKey?: number | null;
  relationType?: NotifRelationTypes;
  /** @minLength 1 */
  title: string;
  user?: WpUser;
  /** @format int64 */
  userKey: number;
}

export interface UserOrganizationFollow {
  /** @format date-time */
  added: string;
  changeLogs?: UserOrganizationFollowChangeLog[];
  hidden: boolean;
  /** @format int64 */
  key: number;
  /** @format date-time */
  lastUpdated?: string | null;
  organization?: Organization;
  /** @format int32 */
  organizationKey: number;
  settings?: UofSettings;
  user?: WpUser;
  /** @format int64 */
  userKey: number;
}

export interface UserOrganizationFollowChangeLog {
  /** @format date-time */
  added: string;
  currentSettings?: UofSettings;
  /** @format int64 */
  key: number;
  previousSettings?: UofSettings;
  userOrgFollow?: UserOrganizationFollow;
  /** @format int64 */
  userOrgFollowKey: number;
}

export interface UserPersonFollow {
  /** @format date-time */
  added: string;
  changeLogs?: UserPersonFollowChangeLog[];
  hidden: boolean;
  /** @format int64 */
  key: number;
  /** @format date-time */
  lastUpdated?: string | null;
  person?: Person;
  personFollowLinks?: UserFeedItemPersonFollowLink[];
  /** @format int64 */
  personKey: number;
  settings?: UpfSettings;
  user?: WpUser;
  /** @format int64 */
  userKey: number;
}

export interface UserPersonFollowChangeLog {
  /** @format date-time */
  added: string;
  currentSettings?: UpfSettings;
  /** @format int64 */
  key: number;
  previousSettings?: UpfSettings;
  userPerFollow?: UserPersonFollow;
  /** @format int64 */
  userPerFollowKey: number;
}

export interface UserProfile {
  bio?: string | null;
  /**
   * @minLength 0
   * @maxLength 200
   */
  instagram?: string | null;
  /**
   * @minLength 0
   * @maxLength 200
   */
  jobTitle?: string | null;
  /** @format int64 */
  key: number;
  profilePicture?: Asset;
  /** @format int64 */
  profilePictureKey?: number | null;
  /**
   * @minLength 0
   * @maxLength 200
   */
  twitter?: string | null;
  user?: WpUser;
  /** @format int64 */
  userKey: number;
  /**
   * @minLength 0
   * @maxLength 200
   */
  youtubeUrl?: string | null;
}

export interface V2OrganizationsPrevNextDetailParams {
  /** @format int32 */
  orgKey: number;
  /**
   * @format int32
   * @default 1
   */
  sportKey?: number;
  /** @format int32 */
  year?: number;
}

export interface V2OrganizationsScheduleAdminDetailParams {
  /** @format int32 */
  orgKey: number;
  /**
   * @format int32
   * @default 1
   */
  sportKey?: number;
  /** @format int32 */
  year?: number;
}

export interface V2OrganizationsScheduleDetailParams {
  /** @format int32 */
  orgKey: number;
  /**
   * @format int32
   * @default 1
   */
  sportKey?: number;
  /** @format int32 */
  year?: number;
}

export interface V2SchedulesImportMasseySchedulesCreateParams {
  /** @default true */
  diff?: boolean;
  orgType?: OrganizationType;
  /** @format int32 */
  sportKey?: number;
  /** @format int32 */
  year?: number;
}

export interface V2SchedulesImportMasseySchedulesCreatePayload {
  /** @format binary */
  csvFile?: File;
}

export interface V2SchedulesImportMasseyStatsCreateParams {
  /** @default true */
  diff?: boolean;
  orgType?: OrganizationType;
  source?: MappingSource;
  /** @format int32 */
  sportKey?: number;
  /** @format int32 */
  year?: number;
}

export interface V2SchedulesImportMasseyStatsCreatePayload {
  /** @format binary */
  csvFile?: File;
}

export interface V2SchedulesRefreshOrganizationScheduleStatsUpdateParams {
  orgType?: OrganizationType;
  /** @format int32 */
  sportKey?: number;
  /** @format int32 */
  year?: number;
}

export type V2SeasonRankingPhasesRenovateCreatePayload =
  OrganizationRankingPhaseRequest[];

export interface V2TeamRankingsRankingsListParams {
  /**
   * @format int32
   * @min 1
   * @max 100
   */
  limit?: number;
  orgType: OrganizationType;
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  orgTypeClassHierarachyKey?: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  page?: number;
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  sportKey: number;
  /**
   * @format int32
   * @min 1800
   * @max 2147483647
   */
  year: number;
}

export interface Verification {
  /** @format int64 */
  userKey: number;
  verificationSource: VerificationSource;
  /** @format date-time */
  verifiedOn: string;
}

export enum VerificationSource {
  Athlete = 'Athlete',
  DBAdmin = 'DBAdmin',
  Unknown = 'Unknown',
}

export enum Visibility {
  Private = 'Private',
  Friends = 'Friends',
  Athletes = 'Athletes',
  Public = 'Public',
  Unknown = 'Unknown',
}

export interface WeatherInformationResponse {
  conditions?: string | null;
  /** @format int32 */
  temperature?: number | null;
}

export interface WpTerm {
  headlines?: Headline[];
  /**
   * @minLength 0
   * @maxLength 200
   */
  name: string;
  /**
   * @minLength 0
   * @maxLength 200
   */
  slug?: string | null;
  /** @format int32 */
  termGroup: number;
  /** @format int64 */
  termId: number;
  termMetas?: WpTermMeta[];
  termTaxonomies?: WpTermTaxonomy[];
}

export interface WpTermMeta {
  /** @format int64 */
  metaId: number;
  /**
   * @minLength 0
   * @maxLength 255
   */
  metaKey: string;
  metaValue?: string | null;
  term?: WpTerm;
  /** @format int64 */
  termId: number;
}

export interface WpTermRelationship {
  object?: Post;
  /** @format int64 */
  objectId: number;
  /** @format int32 */
  order: number;
  termTaxonomy?: WpTermTaxonomy;
  /** @format int64 */
  termTaxonomyId: number;
}

export interface WpTermTaxonomy {
  children?: WpTermTaxonomy[];
  /** @format int64 */
  count: number;
  description?: string | null;
  parent?: WpTermTaxonomy;
  /** @format int64 */
  parentKey: number;
  relationships?: WpTermRelationship[];
  /**
   * @minLength 0
   * @maxLength 32
   */
  taxonomy: string;
  term?: WpTerm;
  /** @format int64 */
  termId: number;
  /** @format int64 */
  termTaxonomyId: number;
}

export interface WpUser {
  /**
   * @minLength 0
   * @maxLength 255
   */
  activationKey?: string;
  articleNotificationLogs?: ArticleNotificationLog[];
  deviceTokens?: DeviceToken[];
  disabled?: boolean | null;
  /**
   * @minLength 0
   * @maxLength 250
   */
  displayName?: string | null;
  /**
   * @minLength 0
   * @maxLength 100
   */
  email?: string | null;
  followedOrganizations?: UserOrganizationFollow[];
  followedPeople?: UserPersonFollow[];
  headlines?: Headline[] | null;
  /** @format int64 */
  key: number;
  /** @format int32 */
  lastLogin?: number | null;
  linkedPeople?: Person[];
  /**
   * @minLength 0
   * @maxLength 60
   */
  login?: string;
  metaData?: WpUserMeta[];
  /**
   * @minLength 0
   * @maxLength 50
   */
  niceName?: string;
  notifications?: UserNotification[];
  /**
   * @minLength 0
   * @maxLength 255
   */
  password?: string;
  /** @format uuid */
  pianoID?: string | null;
  /** @format date-time */
  registered: string;
  subscriptions?: MeprSubscription[];
  transactions?: MeprTransaction[];
  /**
   * @minLength 0
   * @maxLength 100
   */
  url?: string;
  userProfile?: UserProfile;
  wpUserPiano?: WpUserPiano;
  xfUser?: XfUser;
  xfUserAuthenticate?: XfUserAuthenticate;
  xfUserGroupRelations?: XfUserGroupRelation[];
}

export interface WpUserMeta {
  /** @format int64 */
  key: number;
  /**
   * @minLength 0
   * @maxLength 255
   */
  metaKey?: string | null;
  metaValue?: string | null;
  user?: WpUser;
  /** @format int64 */
  userKey: number;
}

export interface WpUserPiano {
  /** @format uuid */
  pianoId: string;
  wpUser?: WpUser;
  /** @format int64 */
  wpUserKey: number;
}

export interface XfNode {
  /** @format byte */
  breadcrumbData?: string | null;
  children?: XfNode[];
  /**
   * Depth = 0: no parent
   * @format int32
   */
  depth: number;
  description?: string;
  /**
   * If 0, hidden from node list. Still counts for lft/rgt.
   * @format int32
   */
  displayInList: number;
  /** @format int32 */
  displayOrder: number;
  /**
   * @format byte
   * @maxLength 50
   */
  effectiveNavigationId?: string;
  /**
   * Style override; pushed down tree
   * @format int32
   */
  effectiveStyleId: number;
  /**
   * Nested set info 'left' value
   * @format int32
   */
  lft: number;
  /**
   * Unique column used as string ID by some node types
   * @minLength 0
   * @maxLength 50
   */
  name?: string | null;
  /**
   * @format byte
   * @maxLength 50
   */
  navigationId?: string;
  /** @format int32 */
  nodeId: number;
  /** @maxLength 25 */
  nodeTypeId?: string;
  parent?: XfNode;
  /** @format int32 */
  parentNodeId: number;
  /**
   * Nested set info 'right' value
   * @format int32
   */
  rgt: number;
  /**
   * Style override for specific node
   * @format int32
   */
  styleId: number;
  threads?: XfThread[];
  /**
   * @minLength 0
   * @maxLength 50
   */
  title?: string;
}

/** Global permissions given to On3.DataAccess.Models.On3Forum.XfUserGroup or On3.DataAccess.Models.On3Forum.XfPermissionEntry.XfUser. */
export interface XfPermissionEntry {
  /** @format int32 */
  permissionEntryId: number;
  /**
   * @format byte
   * @maxLength 25
   */
  permissionGroupId?: string;
  /**
   * @format byte
   * @maxLength 25
   */
  permissionId?: string;
  permissionValue?: string;
  /** @format int32 */
  permissionValueInt: number;
  /** @format int32 */
  userGroupId: number;
  /** @format int64 */
  userId: number;
  xfUser?: XfUser;
}

/** Permissions given to On3.DataAccess.Models.On3Forum.XfUserGroup or On3.DataAccess.Models.On3Forum.XfPermissionEntryContent.XfUser for specific content. Most commonly On3.DataAccess.Models.On3Forum.XfNode. */
export interface XfPermissionEntryContent {
  /** @format int32 */
  contentId: number;
  /**
   * @format byte
   * @maxLength 25
   */
  contentType?: string;
  /** @format int32 */
  permissionEntryId: number;
  /**
   * @format byte
   * @maxLength 25
   */
  permissionGroupId?: string;
  /**
   * @format byte
   * @maxLength 25
   */
  permissionId?: string;
  permissionValue?: string;
  /** @format int32 */
  permissionValueInt: number;
  /** @format int32 */
  userGroupId: number;
  /** @format int64 */
  userId: number;
  xfUser?: XfUser;
}

export interface XfPost {
  /** @format int32 */
  attachCount: number;
  /** @format int32 */
  editCount: number;
  /** @format byte */
  embedMetadata?: string | null;
  /** @format int32 */
  ipId: number;
  /** @format int64 */
  itTempPostId?: number | null;
  /** @format int32 */
  lastEditDate: number;
  /** @format int32 */
  lastEditUserId: number;
  message?: string;
  messageState?: string;
  /** @format int32 */
  position: number;
  /** @format int32 */
  postDate: number;
  /** @format int32 */
  postKey: number;
  /** @format int32 */
  reactionScore: number;
  /** @format byte */
  reactionUsers?: string;
  /** @format byte */
  reactions?: string | null;
  /** @format int64 */
  scTempPostId?: number | null;
  thread?: XfThread;
  /** @format int32 */
  threadId: number;
  /** @format byte */
  typeData?: string;
  user?: XfUser;
  /** @format int64 */
  userKey: number;
  /**
   * @minLength 0
   * @maxLength 50
   */
  username?: string;
  /** @format int32 */
  voteCount: number;
  /** @format int32 */
  voteScore: number;
  /** @format int32 */
  warningId: number;
  /**
   * @minLength 0
   * @maxLength 255
   */
  warningMessage?: string;
}

export interface XfThread {
  /** @format byte */
  customFields?: string;
  /** @format int32 */
  discussionOpen: number;
  discussionState?: string;
  /**
   * @minLength 0
   * @maxLength 25
   */
  discussionType?: string;
  /** @format int32 */
  firstPostId: number;
  /** @format int32 */
  firstPostReactionScore: number;
  /** @format byte */
  firstPostReactions?: string | null;
  /** @format int64 */
  itTempThreadId?: number | null;
  /** @format int32 */
  lastPostDate: number;
  /** @format int32 */
  lastPostId: number;
  /** @format int32 */
  lastPostUserId: number;
  /**
   * @minLength 0
   * @maxLength 50
   */
  lastPostUsername?: string;
  node?: XfNode;
  /** @format int32 */
  nodeId: number;
  /** @format int32 */
  postDate: number;
  posts?: XfPost[];
  /** @format int32 */
  prefixId: number;
  /** @format int32 */
  replyCount: number;
  /** @format int64 */
  scTempThreadId?: number | null;
  /** @format int32 */
  sticky: number;
  /** @format byte */
  tags?: string;
  /** @format int32 */
  threadId: number;
  /** @format int32 */
  threadTitleEditCount: number;
  /** @format int32 */
  threadTitleLastEditDate: number;
  /** @format int32 */
  threadTitleLastEditUserId: number;
  /**
   * @minLength 0
   * @maxLength 150
   */
  title?: string;
  /** @format byte */
  typeData?: string;
  user?: XfUser;
  /** @format int64 */
  userKey: number;
  /**
   * @minLength 0
   * @maxLength 50
   */
  username?: string;
  /** @format int32 */
  viewCount: number;
  /** @format int32 */
  voteCount: number;
  /** @format int32 */
  voteScore: number;
}

export interface XfUser {
  /** @format int32 */
  activityVisible: number;
  /** @format int32 */
  alertsUnread: number;
  /** @format int32 */
  alertsUnviewed: number;
  /** @format int32 */
  andrewUserNoteCount: number;
  /** @format int32 */
  avatarDate: number;
  /** @format int32 */
  avatarHeight: number;
  /** @format int32 */
  avatarHighdpi: number;
  /** @format int32 */
  avatarWidth: number;
  contentPermissionEntries?: XfPermissionEntryContent[];
  /** @format int32 */
  conversationsUnread: number;
  /**
   * @minLength 0
   * @maxLength 50
   */
  customTitle?: string;
  /**
   * User group ID that provides user styling
   * @format int32
   */
  displayStyleGroupId: number;
  /**
   * @minLength 0
   * @maxLength 120
   */
  email?: string;
  /**
   * If specified, this is an email address corresponding to the user's 'Gravatar'
   * @minLength 0
   * @maxLength 120
   */
  gravatar?: string;
  /** @format int32 */
  isAdmin: number;
  /** @format int32 */
  isBanned: number;
  /** @format int32 */
  isModerator: number;
  /** @format int32 */
  isStaff: number;
  /** @format int32 */
  languageId: number;
  /** @format int32 */
  lastActivity: number;
  /** @format int32 */
  lastSummaryEmailDate?: number | null;
  /** @format int32 */
  messageCount: number;
  /** @format int32 */
  permissionCombinationKey: number;
  permissionEntries?: XfPermissionEntry[];
  posts?: XfPost[];
  /** @format int32 */
  privacyPolicyAccepted: number;
  /** @format int32 */
  questionSolutionCount: number;
  /** @format int32 */
  reactionScore: number;
  /** @format int32 */
  registerDate: number;
  /** @maxLength 255 */
  secondaryGroupKeys?: string;
  /**
   * @format byte
   * @maxLength 32
   */
  secretKey?: string;
  securityLock?: string;
  /**
   * 0 = use system default
   * @format int32
   */
  styleId: number;
  /** @format int32 */
  termsAccepted: number;
  threads?: XfThread[];
  /**
   * Example: 'Europe/London'
   * @minLength 0
   * @maxLength 50
   */
  timezone?: string;
  /** @format int32 */
  trophyPoints: number;
  /** @format int32 */
  userGroupKey: number;
  /** @format int64 */
  userKey: number;
  userState?: string;
  /**
   * @minLength 0
   * @maxLength 50
   */
  username?: string;
  /** @format int32 */
  usernameDate: number;
  /** @format int32 */
  usernameDateVisible: number;
  /**
   * Show browsing activity to others
   * @format int32
   */
  visible: number;
  /** @format int32 */
  voteScore: number;
  /** @format int32 */
  warningPoints: number;
  wpUser?: WpUser;
  xfUserAuthenticate?: XfUserAuthenticate;
}

export interface XfUserAuthenticate {
  data?: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  schemeClass?: string;
  /** @format int64 */
  userId: number;
  wpUser?: WpUser;
  xfUser?: XfUser;
}

export interface XfUserGroup {
  /**
   * @minLength 0
   * @maxLength 75
   */
  bannerCssClass?: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  bannerText?: string;
  /** @format int32 */
  displayStylePriority: number;
  /**
   * @minLength 0
   * @maxLength 50
   */
  title?: string;
  /** @format int32 */
  userGroupId: number;
  /**
   * @minLength 0
   * @maxLength 100
   */
  userTitle?: string;
  usernameCss?: string;
}

export interface XfUserGroupRelation {
  /** @format int32 */
  isPrimary: number;
  /** @format int32 */
  userGroupKey: number;
  /** @format int64 */
  userKey: number;
  wpUser?: WpUser;
}

export interface YouTubeShowMeta {
  /** @format date-time */
  lastUpdated?: string | null;
  url?: string | null;
}

export type QueryParamsType = Record<string | number, any>;

export type RequestParams = Omit<
  AxiosRequestConfig,
  'data' | 'method' | 'params' | 'url' | 'responseType'
>;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

/**
 * No description
 *
 * @tags ScheduleController
 * @name V2GamesAdminDetail
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1, game_scorer)
 * @request GET:/rdb/v2/games/{gameKey}/admin
 * @secure
 */
export const v2GamesAdminDetail = (
  client: AxiosInstance,
  gameKey: number,
  params: RequestParams = {},
) =>
  client
    .request<AdminGameRes>({
      url: `/rdb/v2/games/${gameKey}/admin`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags ScheduleController
 * @name V2GamesDelete
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request DELETE:/rdb/v2/games/{gameKey}
 * @secure
 */
export const v2GamesDelete = (
  client: AxiosInstance,
  gameKey: number,
  params: RequestParams = {},
) =>
  client
    .request<ResponseMessage>({
      url: `/rdb/v2/games/${gameKey}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags ScheduleController
 * @name V2GamesRenovateUpdate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1, game_scorer)
 * @request PUT:/rdb/v2/games/renovate
 * @secure
 */
export const v2GamesRenovateUpdate = (
  client: AxiosInstance,
  data: RenGameReq,
  params: RequestParams = {},
) =>
  client
    .request<AdminGameRes>({
      url: `/rdb/v2/games/renovate`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags ScheduleController
 * @name V2OrganizationsPrevNextDetail
 * @summary Get the current, previous, and next games for a team
 * @request GET:/rdb/v2/organizations/{orgKey}/prev-next
 */
export const v2OrganizationsPrevNextDetail = (
  client: AxiosInstance,
  { orgKey, ...query }: V2OrganizationsPrevNextDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<CurrentPreviousAndNextGamesRes>({
      url: `/rdb/v2/organizations/${orgKey}/prev-next`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags ScheduleController
 * @name V2OrganizationsScheduleAdminDetail
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1, game_scorer)
 * @request GET:/rdb/v2/organizations/{orgKey}/schedule-admin
 * @secure
 */
export const v2OrganizationsScheduleAdminDetail = (
  client: AxiosInstance,
  { orgKey, ...query }: V2OrganizationsScheduleAdminDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<AdminGameResRelatedScheduleResponseRelatedPagedData>({
      url: `/rdb/v2/organizations/${orgKey}/schedule-admin`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags ScheduleController
 * @name V2OrganizationsScheduleDetail
 * @summary Get schedule for a org and season.
 * @request GET:/rdb/v2/organizations/{orgKey}/schedule
 */
export const v2OrganizationsScheduleDetail = (
  client: AxiosInstance,
  { orgKey, ...query }: V2OrganizationsScheduleDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<ScoreboardResponseRelatedScheduleResponseRelatedPagedData>({
      url: `/rdb/v2/organizations/${orgKey}/schedule`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonConnection
 * @name V2PeoplePersonConnectionsCreate
 * @summary Creates an Connection using Person Keys and SportKey
 * @request POST:/rdb/v2/people/{personKey}/person-connections
 * @secure
 */
export const v2PeoplePersonConnectionsCreate = (
  client: AxiosInstance,
  personKey: number,
  data: PersonConnectionRequestV2,
  params: RequestParams = {},
) =>
  client
    .request<PersonConnectionFullResponse>({
      url: `/rdb/v2/people/${personKey}/person-connections`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonConnection
 * @name V2PeoplePersonConnectionsDetail
 * @summary Gets Person Connection by Person Key
 * @request GET:/rdb/v2/people/{personKey}/person-connections
 * @secure
 */
export const v2PeoplePersonConnectionsDetail = (
  client: AxiosInstance,
  personKey: number,
  params: RequestParams = {},
) =>
  client
    .request<PersonConnectionFullResponse>({
      url: `/rdb/v2/people/${personKey}/person-connections`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonConnection
 * @name V2PeoplePersonConnectionsUpdate
 * @summary Updates a Connection using Person Keys And SportKey
 * @request PUT:/rdb/v2/people/{personKey}/person-connections/{connectionKey}
 * @secure
 */
export const v2PeoplePersonConnectionsUpdate = (
  client: AxiosInstance,
  personKey: number,
  connectionKey: number,
  data: PersonConnectionRequestV2,
  params: RequestParams = {},
) =>
  client
    .request<PersonConnectionFullResponse>({
      url: `/rdb/v2/people/${personKey}/person-connections/${connectionKey}`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags ScheduleController
 * @name V2SchedulesImportMasseySchedulesCreate
 * @summary  (Roles: developer, cron)
 * @request POST:/rdb/v2/schedules/import-massey-schedules
 * @secure
 */
export const v2SchedulesImportMasseySchedulesCreate = (
  client: AxiosInstance,
  query: V2SchedulesImportMasseySchedulesCreateParams,
  data: V2SchedulesImportMasseySchedulesCreatePayload,
  params: RequestParams = {},
) =>
  client
    .request<UploadByCsvResponse>({
      url: `/rdb/v2/schedules/import-massey-schedules`,
      method: 'POST',
      params: query,
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags ScheduleController
 * @name V2SchedulesImportMasseyStatsCreate
 * @summary  (Roles: developer, cron)
 * @request POST:/rdb/v2/schedules/import-massey-stats
 * @secure
 */
export const v2SchedulesImportMasseyStatsCreate = (
  client: AxiosInstance,
  query: V2SchedulesImportMasseyStatsCreateParams,
  data: V2SchedulesImportMasseyStatsCreatePayload,
  params: RequestParams = {},
) =>
  client
    .request<UploadByCsvResponse>({
      url: `/rdb/v2/schedules/import-massey-stats`,
      method: 'POST',
      params: query,
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags ScheduleController
 * @name V2SchedulesRefreshOrganizationScheduleStatsUpdate
 * @summary Refresh season stats table OrganizationScheduleStats for a given org/sport/year
 * @request PUT:/rdb/v2/schedules/refresh-organization-schedule-stats
 * @secure
 */
export const v2SchedulesRefreshOrganizationScheduleStatsUpdate = (
  client: AxiosInstance,
  query: V2SchedulesRefreshOrganizationScheduleStatsUpdateParams,
  params: RequestParams = {},
) =>
  client
    .request<CurrentPreviousAndNextGamesRes>({
      url: `/rdb/v2/schedules/refresh-organization-schedule-stats`,
      method: 'PUT',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags SeasonPhaseControllerV
 * @name V2SeasonRankingPhasesRenovateCreate
 * @summary Updates an organization ranking (by Season Phase - week 1 / week 2...)
 * @request POST:/rdb/v2/season-ranking-phases/renovate
 * @secure
 */
export const v2SeasonRankingPhasesRenovateCreate = (
  client: AxiosInstance,
  data: V2SeasonRankingPhasesRenovateCreatePayload,
  params: RequestParams = {},
) =>
  client
    .request<OrganizationRankingPhaseResponse>({
      url: `/rdb/v2/season-ranking-phases/renovate`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags TeamRankingController
 * @name V2TeamRankingsRankingsList
 * @summary Get all Massey Rankings for an Organization/Sport/Year at a national level (default)
or provide an Organization Type Class Hierarchy key to filter by classification.
 * @request GET:/rdb/v2/team-rankings/rankings
 */
export const v2TeamRankingsRankingsList = (
  client: AxiosInstance,
  query: V2TeamRankingsRankingsListParams,
  params: RequestParams = {},
) =>
  client
    .request<TeamRankingResponseRelatedTeamRankingResponseRelatedPagedData>({
      url: `/rdb/v2/team-rankings/rankings`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);
