export const truncateText = (str: string, length?: number, ending?: string) => {
  if (length == null) {
    length = 100;
  }

  if (ending == null) {
    ending = '...';
  }

  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  }

  return str;
};
