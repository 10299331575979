import { useRouter } from 'next/router';
import Script from 'next/script';
import { useCallback, useEffect } from 'react';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _comscore: any;
  }
}

const COMSCORE_ACCOUNT_ID = '36671852';
let hasFired = false;

const comscorePageview = () => {
  const _comscore = window?._comscore || [];

  _comscore.push({ c1: '2', c2: COMSCORE_ACCOUNT_ID });
};

export const Comscore = () => {
  const { asPath: path } = useRouter();

  useEffect(() => {
    comscorePageview();
  }, [path]);

  useEffect(() => {
    if (!hasFired) {
      comscorePageview();
    }

    hasFired = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const comscoreScript = useCallback(() => {
    return {
      __html: `(function() {
          var s = document.createElement("script"), el = document.getElementsByTagName("script")[0]; s.async = true;
          s.src = "https://sb.scorecardresearch.com/cs/${COMSCORE_ACCOUNT_ID}/beacon.js";
          el.parentNode.insertBefore(s, el);
        })();`,
    };
  }, []);

  return (
    <Script dangerouslySetInnerHTML={comscoreScript()} strategy="lazyOnload" />
  );
};
