import { Typography, TypographyProps } from '@mui/material';
import { truncateText } from '@on3/ui-lib/src/hooks/truncateText';
import type { ReactNode } from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ITextProps extends TypographyProps {
  component?: React.ElementType;
  truncate?: number;
  truncateEnding?: string;
  children?: ReactNode;
}

export const Text = ({
  children,
  color,
  truncate,
  truncateEnding,
  ...restTextProps
}: ITextProps) => {
  return (
    <Typography color={color ?? 'textPrimary'} {...restTextProps}>
      {truncate
        ? truncateText(`${children}`, truncate, truncateEnding)
        : children}
    </Typography>
  );
};
