import type { AxiosInstance, AxiosRequestConfig } from 'axios';

export interface CreateServiceAccountTokenRequest {
  /** @format date-time */
  expiresAt?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  name: string;
  /** @minItems 1 */
  roles: string[];
}

export interface DiagramGenerationRequest {
  /** @minLength 1 */
  type: string;
}

export interface ServiceAccountJwtResponse {
  /** The JWT to use */
  token?: string | null;
}

export interface V1DevToolsExistingCacheKeysListParams {
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   * @default 1
   */
  page?: number;
}

export interface V1DevToolsGetForumsCountCreateParams {
  /** @format date-time */
  from?: string;
  nodeIds?: number[];
  /** @format date-time */
  to?: string;
  type?: string[];
  userIds?: number[];
}

export type V1DevToolsGetNotifHandlerOrderCreatePayload = string;

export type V1DevToolsPurgeCacheForTagsCreatePayload = string[];

export type V1DevToolsPurgeCacheKeysCreatePayload = string[];

export type QueryParamsType = Record<string | number, any>;

export type RequestParams = Omit<
  AxiosRequestConfig,
  'data' | 'method' | 'params' | 'url' | 'responseType'
>;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

/**
 * No description
 *
 * @tags DevTools
 * @name V1DevToolsExistingCacheKeysList
 * @summary  (Roles: developer)
 * @request GET:/support/v1/dev-tools/existing-cache-keys
 * @secure
 */
export const v1DevToolsExistingCacheKeysList = (
  client: AxiosInstance,
  query: V1DevToolsExistingCacheKeysListParams,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/support/v1/dev-tools/existing-cache-keys`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags DevTools
 * @name V1DevToolsGenerateDiagramCreate
 * @summary  (Roles: developer)
 * @request POST:/support/v1/dev-tools/generate-diagram
 * @secure
 */
export const v1DevToolsGenerateDiagramCreate = (
  client: AxiosInstance,
  data: DiagramGenerationRequest,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/support/v1/dev-tools/generate-diagram`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags DevTools
 * @name V1DevToolsGenerateServiceAccountTokenCreate
 * @summary  (Roles: developer)
 * @request POST:/support/v1/dev-tools/generate-service-account-token
 * @secure
 */
export const v1DevToolsGenerateServiceAccountTokenCreate = (
  client: AxiosInstance,
  data: CreateServiceAccountTokenRequest,
  params: RequestParams = {},
) =>
  client
    .request<ServiceAccountJwtResponse>({
      url: `/support/v1/dev-tools/generate-service-account-token`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags DevTools
 * @name V1DevToolsGetForumsCountCreate
 * @summary  (Roles: developer)
 * @request POST:/support/v1/dev-tools/get-forums-count
 * @secure
 */
export const v1DevToolsGetForumsCountCreate = (
  client: AxiosInstance,
  query: V1DevToolsGetForumsCountCreateParams,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/support/v1/dev-tools/get-forums-count`,
      method: 'POST',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags DevTools
 * @name V1DevToolsGetNotifHandlerOrderCreate
 * @summary  (Roles: developer)
 * @request POST:/support/v1/dev-tools/get-notif-handler-order
 * @secure
 */
export const v1DevToolsGetNotifHandlerOrderCreate = (
  client: AxiosInstance,
  data: V1DevToolsGetNotifHandlerOrderCreatePayload,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/support/v1/dev-tools/get-notif-handler-order`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags DevTools
 * @name V1DevToolsPurgeCacheForTagsCreate
 * @summary  (Roles: developer)
 * @request POST:/support/v1/dev-tools/purge-cache-for-tags
 * @secure
 */
export const v1DevToolsPurgeCacheForTagsCreate = (
  client: AxiosInstance,
  data: V1DevToolsPurgeCacheForTagsCreatePayload,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/support/v1/dev-tools/purge-cache-for-tags`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags DevTools
 * @name V1DevToolsPurgeCacheKeysCreate
 * @summary  (Roles: developer)
 * @request POST:/support/v1/dev-tools/purge-cache-keys
 * @secure
 */
export const v1DevToolsPurgeCacheKeysCreate = (
  client: AxiosInstance,
  data: V1DevToolsPurgeCacheKeysCreatePayload,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/support/v1/dev-tools/purge-cache-keys`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);
